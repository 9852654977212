import React from 'react';
import { SvgImage } from '../../../../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../../../../components/Icon/KnownIcons';
import { useLessonStore } from '../../../../../../../store/lesson/LessonStore';
import { ChessclubUser } from '../../../../../../../interfaces/user';

export function CanMoveIndicator(props: { canMove: boolean, user: ChessclubUser }) {

  const { toggleCanMoveById, monitoringStudentId } = useLessonStore();

  if (!props.canMove)
    return null;
  return <SvgImage
    size={24}
    r={4}
    icon={KnownIcons.pawn_with_arrows}
    active
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      !monitoringStudentId && toggleCanMoveById(props.user.id);
    }}
  />;
}