import React, { PropsWithChildren } from 'react';
import { Link } from '../LowLevelComponents/Link';
import styled from 'styled-components';

export const StyledLinkWrapper = styled(Link)`
  padding: 5px 5px;
  display: flex;
  align-items: center;
  height: 32px;
  color: ${({ theme }) => theme.currentTheme.iconMain};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.currentTheme.hover};
  }
`;

export function StyledLink(props: PropsWithChildren<{ to: string }>) {
  return <StyledLinkWrapper to={props.to}>
    {props.children}
  </StyledLinkWrapper>;
}