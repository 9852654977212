import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { SvgImage } from '../Icon/SvgImage';
import { RawSvgString } from '*.svg';
import { PopupMenuSeparator } from './PopupMenuSeparator';
import { TextEllipsis } from '../LowLevelComponents/EllipsedText';

const StyledTitleTextItem = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  font-family: Roboto,serif;
  border-radius: 8px;
  color: ${p => p.theme.currentTheme.iconMain};
  vertical-align: middle
  pointer-events: none;
  padding-top: 5px;
`;

type PopupMenuTitleParams = {
  icon?: RawSvgString | ReactNode;
  text: string;
  style?: CSSProperties;
  id: string;
};

export function PopupMenuTitle(props: PopupMenuTitleParams) {
  return <StyledTitleTextItem id={props.id}>
    <div style={{display: "flex", alignItems: "center", paddingRight: 10, paddingLeft: 10}}>
      {typeof props.icon === "string" ? <SvgImage
        icon={props.icon as any}
      /> : props.icon}
      <TextEllipsis text={props.text}/>
    </div>
    <PopupMenuSeparator/>
  </StyledTitleTextItem>
}