import { create } from 'zustand';
import { AdminKnowledgesStoreActions, AdminKnowledgesStoreState } from './AdminKnowledgesStoreApi';
import { IMaterial } from '@chessclub/grpc_wrapper/src/api/types/IMaterial';
import { ServerApi } from '../../transport/ServerApi';
import { replaceById } from '../../helpers/replaceById';
import { ITopic, UserId } from '@chessclub/grpc_wrapper';
import { useAuthStore } from '../auth/AuthStore';
import { localStorageObject } from '../../helpers/localStorageObject';
import { selectedKnowledgeTopicDb } from './AdminKnowledgesStoreDb';

type AdminKnowledgesStore = AdminKnowledgesStoreState & AdminKnowledgesStoreActions;

const savedKnowledgePage = localStorageObject<number>('knowledges-page-active', 0);
const savedPageSize = localStorageObject<number>('knowledges-page-size', 14);


const initialState: AdminKnowledgesStoreState = {
  page: 0,
  pageCount: 0,
  pageSize: savedPageSize.restore(),
  knowledges: [],
  pageKnowledges: [],
  selectedTopic: null,
  knowledgeTopics: [],
};

export const useAdminKnowledgesStore = create<AdminKnowledgesStore>((
  set,
  get,
) => {

  const privateState: { userId: UserId } = {
    userId: null,
  };

  useAuthStore.subscribe(async (state, prevState) => {
    if (state.user?.id !== prevState.user?.id) {
      await init(state.user?.id);
    }
  });

  async function getSelectedTopic() {
    const { knowledgeTopics } = get();

    let selectedTopic = await selectedKnowledgeTopicDb.getSelectedKnowledgeTopic(privateState.userId) || knowledgeTopics[0];
    if (selectedTopic && ![...knowledgeTopics].find(t => t.id === selectedTopic.id)) {
      selectedTopic = knowledgeTopics[0];
    }

    return selectedTopic;
  }

  function getPageCount(knowledges: IMaterial[], pageSize: number) {
    return Math.floor((knowledges?.length-1 || 0) / pageSize);
  }

  async function init(userId: UserId) {
    if (!userId)
      return;
    privateState.userId = userId;

    const { pageSize } = get();

    const knowledgeTopics = await ServerApi.knowledgeService.getKnowledgeTopicAll();

    const selectedTopic = await getSelectedTopic();
    const knowledges = await requestKnowledges(selectedTopic);
    const pageCount = getPageCount(knowledges, pageSize);

    set({ selectedTopic, knowledgeTopics, pageCount });
  }

  async function requestKnowledges( selectedTopic: ITopic ): Promise<IMaterial[]> {
    if (!selectedTopic) {
      return;
    }

    return await ServerApi.knowledgeService.getNextKnowledgeByTopicId(
      selectedTopic.id, null, 10000);
  }

  return {
    ...initialState,

    async setPage(page) {
      const { knowledges, pageSize } = get();

      const start = !page ? 0 : page * pageSize;
      const pageKnowledges = knowledges.slice(start, start + pageSize);
      const pageCount = getPageCount(knowledges, pageSize);

      savedKnowledgePage.save(page);

      set({ page, pageKnowledges, pageCount });
    },

    async selectTopic(selectedTopic: ITopic) {
      const { pageSize } = get();

      await selectedKnowledgeTopicDb.saveSelectedKnowledgeTopic(privateState.userId, selectedTopic);

      const knowledges = await requestKnowledges(selectedTopic);
      const pageKnowledges = knowledges.slice(0, pageSize);
      const pageCount = getPageCount(knowledges, pageSize);
      selectedTopic.itemsCount = knowledges.length;

      set({ selectedTopic, knowledges, pageKnowledges, pageCount, page: 0 });
    },

    async putTopic(topic: ITopic) {
      const { knowledgeTopics } = get();

      let isEdit = topic.id;
      if (isEdit) {
        await ServerApi.knowledgeService.updateKnowledgeTopic(topic);
      } else {
        topic.id = await ServerApi.knowledgeService.createKnowledgeTopic(topic);
      }

      set({
        selectedTopic: topic,
        knowledgeTopics: isEdit ? replaceById(knowledgeTopics, topic) : [...knowledgeTopics, topic],
        knowledges: [],
      });
    },

    async putKnowledge(knowledge: IMaterial) {
      const { selectedTopic, knowledges, knowledgeTopics, page, pageSize } = get();
      const isEdit = knowledge.id;
      if (isEdit) {
        await ServerApi.knowledgeService.updateKnowledgeFields(knowledge);
      } else {
        knowledge.topicId = selectedTopic.id;
        knowledge.id = await ServerApi.knowledgeService.createKnowledge(knowledge);
      }

      const start = !page ? 0 : page * pageSize;
      const knowledgesAdd = isEdit ? replaceById(knowledges, knowledge) : [...knowledges, knowledge];
      selectedTopic.itemsCount = knowledgesAdd.length;
      const pageCount = getPageCount(knowledgesAdd, pageSize);

      set({
        knowledgeTopics: replaceById(knowledgeTopics, selectedTopic),
        knowledges: knowledgesAdd,
        pageKnowledges: knowledgesAdd.slice(start, start + pageSize),
        pageCount: pageCount,
      });

      if (!isEdit) {
        get().setPage(pageCount);
      }
    },

    async deleteTopic(topic: ITopic) {
      const { selectedTopic, knowledgeTopics } = get();
      await ServerApi.knowledgeService.deleteKnowledgeTopicById(topic.id);
      const newKnowledgeTopics = knowledgeTopics.filter(t => t.id !== topic.id);
      set({
        selectedTopic: selectedTopic.id === topic.id ? newKnowledgeTopics[0] : selectedTopic,
        knowledgeTopics: newKnowledgeTopics,
      });
    },

    async deleteKnowledge(knowledge: IMaterial) {
      const { selectedTopic, knowledgeTopics, knowledges, page, pageSize, pageCount } = get();

      const start = !page ? 0 : page * pageSize;
      const knowledgesDel = knowledges.filter(m => m.id !== knowledge.id);
      selectedTopic.itemsCount = knowledgesDel.length;

      const pageCountAfter = getPageCount(knowledgesDel, pageSize);

      set({
        knowledgeTopics: replaceById(knowledgeTopics, selectedTopic),
        knowledges: knowledgesDel,
        pageKnowledges: knowledgesDel.slice(start, start + pageSize),
        pageCount: pageCountAfter,
      });

      await ServerApi.knowledgeService.deleteKnowledgeById(knowledge.id);

      if (page === pageCount && pageCountAfter < pageCount) {
        get().setPage(pageCountAfter);
      }
    },

  };
});