import { L10n } from './L10n';

export const Lang_ru: L10n = {
  'landing': {
    'mainTitle': 'Обучение<br/>на новом уровне',
    'mainSubTitle': '<br/>платформа для дистанционного<br/>обучения игре в шахматы',
    'aboutUs': 'О платформе',
    'aboutUsText': '<b>ChessClub Training это платформа для тех, для кого шахматы больше, чем игра!</b><br/><br/>Мы делаем процесс обучения максимально<br/>комфортным, эффективным и увлекательным,<br/>как для преподавателей, так и для учеников',
    'ourCapabilitiesTitle': 'Наши возможности',
    'ourCapabilitiesElements': [
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'Проведение как индивидуальных, так и групповых занятий'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'Внедрение новых игровых форм в обучение детей шахматам (геймификация)'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'Все необходимые материалы для обучения собраны в одном месте'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'Расширенный инструментарий для преподавания'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', 'Удаленное обучение с эффектом присутствия'],
    ],
    'ourPlatformTitle': 'Наша платформа для Вас, если Вы:',
    'ourPlatformElements': [
      ['https://chessclub.spb.ru/landing/img/knight_black.svg', 'https://chessclub.spb.ru/landing/img/knight_white.svg', 'Занимаетесь фрилансом в области обучения игре в шахматы'],
      ['https://chessclub.spb.ru/landing/img/rook_black.svg', 'https://chessclub.spb.ru/landing/img/rook_white.svg', 'Преподаватель в шахматном клубе или профессиональный тренер по шахматам'],
      ['https://chessclub.spb.ru/landing/img/king_black.svg', 'https://chessclub.spb.ru/landing/img/king_white.svg', 'Проводите мастер-классы, лекции или интенсивы по шахматам'],
      ['https://chessclub.spb.ru/landing/img/queen_black.svg', 'https://chessclub.spb.ru/landing/img/queen_white.svg', 'Хотите, чтобы Ваш ребенок логически мыслил и научился играть в шахматы'],
      ['https://chessclub.spb.ru/landing/img/pawn_black.svg', 'https://chessclub.spb.ru/landing/img/pawn_white.svg', 'Хотите подобрать себе тренера и научиться игре в шахматы'],
    ],
    'ourAdvantagesTitle': 'Наши преимущества',
    'ourAdvantagesElements': [
      ['https://chessclub.spb.ru/landing/img/pawns-6.svg', 'Возможность проведения групповых онлайн-занятий в режиме конференции'],
      ['https://chessclub.spb.ru/landing/img/board.svg', 'Уникальная шахматная доска с возможностью кастомизации под разные задачи'],
      ['https://chessclub.spb.ru/landing/img/arrows.svg', 'Виртуальная указка с синхронизацией перемещений курсора и фигур'],
      ['https://chessclub.spb.ru/landing/img/pawns-3.svg', 'Инструментарий для ведения занятий с разными возрастными категориями'],
      ['https://chessclub.spb.ru/landing/img/ai.svg', 'Применение технологий искусственного интеллекта'],
      ['https://chessclub.spb.ru/landing/img/pc.svg', 'Адаптивные интерфейсы под ПК, ноутбук, смартфон, планшет, мобильную версия'],
      ['https://chessclub.spb.ru/landing/img/two-knights.svg', 'Возможность проведения онлайн-турниров<br/>(в разработке)'],
      ['https://chessclub.spb.ru/landing/img/server.svg', 'Синергия с сервисами TimeWeb (надежный хостинг на серверах)'],
    ],
    'ourExpertsTitle': 'Наши эксперты',
    'ourExpertsElements': [
      ['https://chessclub.spb.ru/landing/img/vladimir-bykov.png', '<b>Владимир Быков</b><br/><br/>Исполнительный директор федерации шахмат Санкт-Петербурга. Капитан команды «Медный Всадник». Спортивный судья всероссийской категории по шахматам.'],
      ['https://chessclub.spb.ru/landing/img/ekaterina-sudoplatova.png', '<b>Екатерина Судоплатова</b><br/><br/>Гроссмейстер среди женщин. Международный мастер. Двукратная чемпионка России и чемпионка мира среди девушек, чемпионка Европы, член олимпийской сборной России, чемпионка России среди женщин, многократная чемпионка Санкт-Петербурга. Тренер, педагог дополнительного образования.'],
      ['https://chessclub.spb.ru/landing/img/alina-balayan.png', '<b>Алина Балаян</b><br/><br/>Международный мастер среди женщин. Мастер спорта России по шахматам. Чемпионка России по рапиду среди женщин, чемпионка России в составе команды, многократная чемпионка Санкт-Петербурга. Тренер, педагог дополнительного образования.'],
      ['https://chessclub.spb.ru/landing/img/valeriy-popov.png', '<b>Валерий Попов</b><br/><br/>Гроссмейстер. Международный мастер. Чемпион Европы. Тренер высшей категории. Старший тренер отделения шахмат СПБ ГБУ СШОР по шахматам.'],
      ['https://chessclub.spb.ru/landing/img/dina-belenkaya.png', '<b>Дина Беленькая</b><br/><br/>Гроссмейстер среди женщин. Многократный призёр Чемпионата России в составе команды, многократная чемпионка г.Санкт-Петербурга. Преподаватель по шахматам.'],
      ['https://chessclub.spb.ru/landing/img/anastasia-bondaruk.png', '<b>Анастасия Боднарук</b><br/><br/>Гроссмейстер среди женщин. Международный мастер. Чемпионка мира по рапиду, многократная чемпионка России, многократная чемпионка города Санкт-Петербурга, России, член сборной России.'],
    ],
    'imagesSlider': [
      ['Онлайн занятие', 'https://chessclub.spb.ru/landing/img/online-black.png', 'https://chessclub.spb.ru/landing/img/online-white.png'],
      ['Оффлайн занятие', 'https://chessclub.spb.ru/landing/img/online-black.png', 'https://chessclub.spb.ru/landing/img/offline-white.png'],
      ['Редактирование задания', 'https://chessclub.spb.ru/landing/img/online-black.png', 'https://chessclub.spb.ru/landing/img/edit-white.png'],
    ]
  },
  'footer': {
    'help': 'Справка',
    'contacts': 'Контакты',
    'supportUs': 'Поддержать проект',
    'termsOfService': 'Условия использования',
  },
  'rooms': {
    "rooms": 'Комнаты',
    "new_room": "Новая комната",
    "edit_room": "Редактирование комнаты",
    "name": "Название",
    "description": "Описание",
    "link": "Ссылка",
    "external_view": "Внешний вид комнаты ученика",
    "password_optional": "Пароль (не обязательно)",
    "trainees": "Обучаемые",
    "nothing_found": "Ничего не найдено",
    "search_by_site": "Поиск по сайту",
    "more_characters": " (введите 3 или более символов)",
  },
  'lesson': {
    'newTask': 'Новый вопрос',
    'chain': 'Цепочка',
    'table': 'Таблица',
    'text': 'Текст',
    'clear': 'Очистить',
    'room': 'Комната',
    'defaultRoom': 'Комната по умолчанию',
    'ok': 'Ok',
    'empty': 'Ничего не найдено',
    'sizeHuge': 'Огромный',
    'sizeLarge': 'Большой',
    'sizeStandard': 'Стандартный',
    'markersBlue': 'Синий маркер',
    'markersGreen': 'Зеленый маркер',
    'markersOff': 'Выключить маркеры',
    'markersRed': 'Красный маркер',
    'markersDelete': 'Удалить маркеры',
    'withoutTitles': 'Без подписей',
    'titlesAtCellCorners': 'В углах ячеек',
    'titlesInEveryCell': 'В каждой ячейке',
    'titlesAroundChessboard': 'Вокруг доски',
    'standardView': 'Стандартный',
    'allChessboardsView': 'Все доски',
    'allCamsView': 'Все камеры',
    'size': 'Размер',
    'exit': 'Выйти',
    'knowledges': 'База знаний',
    'materials': 'Материалы',
    'my_materials': 'Мои материалы',
    'rooms': 'Комнаты',
    'profile': 'Профиль',
    'edit': 'Редактировать',
    'paste': 'Вставить',
    'copy': 'Копировать',
    'save': 'Сохранить',
    'cancel': 'Отменить',
    'delete': 'Удалить',
    'add': 'Добавить',
    'waitForTrainer': 'Ожидайте входа тренера',
    'chat': 'Чат',
    'cams': 'Камеры',
    'online': 'Онлайн',
    'view': 'Вид',
    'demonstration': 'Демонстрация',
    'settings': 'Настройки',
    'offline': 'Оффлайн',
    'black': 'Черные',
    'white': 'Белые',
    'tasks': 'Вопросы',
    'moves': 'Ходы',
    'activeLessons': 'Активные уроки',
    'activeLesson': 'Активный урок',
    'student': 'Студент',
    'trainer': 'Тренер',
    'lesson': 'Урок',
    'lessons': 'Занятия',
    "beginning": "В начало",
    "name": "Название",
    "task": "Задача с ответом",
    "game": "Игра с героми",
    "question": "Вопрос с описанием",
    "engine": "Игра с компьютером",
    "edit_task": "Редактирование задачи",
    "search": "Поиск",
    "edit_material": "Редактирование материала",
    "new_topic": "Добавить новую тему",
    "reset": "Сбросить",
    "materials_count": "Всего материалов по теме",
    "admin_panel": "Дашборд",
  },
  'captcha': {
    'language': 'Русский',
    'task': "Пожалуйста, выполните задание чтобы мы могли знать, что Вы реальный человек:",
    'errors': {
      'NO_ERROR': 'Ошибок не найдено',
      'INVALID_PARAMS': 'Неверные параметры каптчи',
      'WRONG_PROTOBUF_MESSAGE': 'Ошибочное сообщение протобафа',
      'CAPTCHA_NOT_FOUND': 'Каптча не найдена',
      'CAPTCHA_ALREADY_EXISTS': 'Неверные параметры каптчи',
      'WRONG_CAPTCHA_EMAIL': 'Неверный емайл каптчи',
      'CAPTCHA_IS_EXPIRED': 'Каптча просрочена',
      'ANSWER_IS_EXPIRED': 'Ответ просрочен',
      'WRONG_CAPTCHA_ANSWER': 'Неверный ответ каптчи',
      'WRONG_FEN_RANDOMIZED': 'Ошибка генерации FEN',
    },
    questions: {
      "Find the white pawns": "Найдите белые пешки",
      "Find the black pawns": "Найдите черные пешки",
      "Find the pawns": "Найдите пешки",
      "Find the white knights": "Найдите белых коней",
      "Find the black knights": "Найдите черных коней",
      "Find the knights": "Найдите коней",
      "Find the white bishops": "Найдите белых слонов",
      "Find the black bishops": "Найдите черных слонов",
      "Find the bishops": "Найдите слонов",
      "Find the white rooks": "Найдите белые ладьи",
      "Find the black rooks": "Найдите черные ладьи",
      "Find the rooks": "Найдите ладьи",
      "Find the white queens": "Найдите белых ферзей",
      "Find the black queens": "Найдите черных ферзей",
      "Find the queens": "Найдите ферзей",
      "Find the kings": "Найдите королей",
    },
  },
  'auth': {
    'new_password': 'Новый пароль',
    'repeat_new_password': 'Повторите новый пароль',
    'consider_new_password': 'Придумайте новый пароль и для подтверждения введите код, который был отправлен на Ваш электронный ящик',
    'confirm': 'Подтвердить',
    'agree_with': 'Я согласен (-на) с ',
    'terms': 'праивлами сервиса',
    'do_sign_up': 'Зарегистрироваться',
    'continue': 'Продолжить',
    'code': 'Код из письма',
    'email_to_restore': 'Введите адрес электронной почты для восстановления доступа',
    'receive_code_in_mail': 'Получить код в письме',
    'resend_code': 'Повторить отправку кода',
    'repeat_password': 'Повторите пароль',
    'login_with': 'Войдите с помощью',
    'login': 'Войти',
    'forgot_password': 'Восстановить пароль',
    'or': 'или',
    'email': 'Электронная почта',
    'wrong_email': 'Электронная почта не корректна',
    'password': 'Пароль',
    "password_not_match":"Пароли не совпадают",
    'no_account_yet': 'Ище нет аккаунта?',
    'sign_up': 'Регистрация',
    'yandex': 'Яндекс',
    'ok': 'Одноклассники',
    'mail': 'Майл.ру',
    'fb': 'Фейсбук',
    'twitter': 'Твитер',
    'vk': 'Вконтакте',
    'google': 'Гугл',
    'choose_lg': 'Выберите ваш язык',
    'language': 'Русский',
    "home_page": "на домашнюю страницу",
    "page_not_found": "К сожалению, страница не найдена...",
    "page_not_found_title": "Воспользуйтесь меню в верхней части экрана или вернитесь ",
    "lesson_not_found": "Урок не найден",
    "password_error": "Неверный пароль",
    "invalid_characters": "Некорректные символы",
    "enter_session_text": "Вы переходите по ссылке на занятие. Для доступа к кабинету введите свои данные",
    "username":  "Имя",
    "password_to_enter": "Пароль доступа",
    "with_out_registration": "Продолжить без регистрации",
    "change_password": "Для смены пароля пользователя заполните данные ниже или воспользуйтесь процедурой воссановления пароля на форме авторизации.",
    "current_password": "Текущий пароль",
    'errors': {
      'NO_ERROR': 'Ошибок нет',
      'USER_NOT_FOUND': 'Пользователь не найден',
      'USER_ALREADY_EXISTS': 'Пользователь уже существует',
      'INVALID_PARAMS': 'Неверные параметры доступа',
      'USER_NOT_ACTIVE': 'Пользователь не активирован',
      'WRONG_PASSWORD': 'Неверный пароль',
      'WRONG_ACTIVATION_CODE': 'Неверный код активации',
      'WRONG_SESSION_SIGN': 'Неверный знак сеанса сессии',
      'NOT_AUTHENTICATED': 'Пользователь не аутентифицирован',
      'WRONG_RESTORE_CODE': 'Неверный код востановления',
      'USER_ALREADY_ACTIVE': 'Пользователь уже актвирован',
      'WRONG_AUTH_CODE': 'Неверный код аутентификации',
      'IMPROPERLY_CONFIGURED': 'Переданы неверный настройки',
      'WRONG_PROVIDER': 'Неверный социальный провайдер',
      'WEAK_PASSWORD': 'Слишком слабый пароль',
      'SOCIAL_AUTH_ERROR': 'Ошибки при попытке получении информации о пользователе',
      'OAUTH_APP_ALREADY_EXISTS': 'OAuth приложение уже существует',
      'OAUTH_APP_NOT_FOUND': 'OAuth приложение не найдено',
      'OAUTH_CONSUMER_NOT_FOUND': 'OAuth потребитель не найден',
      'OAUTH_MAX_APPS_REACHED': 'Достигнуто максимальное количествоь OAuth приложений',
      'OAUTH_CODE_NOT_FOUND': 'OAuth код авторизации не найден',
      'OAUTH_ACCESS_TOKEN_NOT_FOUND': 'OAuth токен доступа не найден',
      'SMTP_SEND_MAIL_ERROR': 'Ошибка отправки SMTP майла',
      'WRONG_IMAGE_TYPE': 'Неверный тип изображения',
      'SEND_IMAGE_ERROR': 'Ошибка отправки изображения',
      'WRONG_LANGUAGE_TYPE': 'Неверный тип языка',
      'WRONG_DECODE_DATA': 'Ошибка декодирования данных',
      'WRONG_ENCODE_DATA': 'Ошибка кодирования данных',
      'WRONG_PROTOBUF_MESSAGE': 'Ошибочное сообщение протобафа',
      'PERMISSION_DENIED': 'Доспут запрещен',
      'CONTACT_ALREADY_EXISTS': 'Контакт уже существует',
      'CONTACT_NOT_FOUND': 'Контакт не найден',
      'CONTACT_NOT_ACTIVE': 'Контакт не активен',
    },
  },
  'permissions': {
    'OtherError': {
      'otherError': 'Ваша камера недоступна. Воспользуйтесь другим устройством.',
    },
    'BlockedAtSystemLevelMacOs': {
      'camera': 'Камера не подключена, либо доступ {{browserName}} к камере запрещён на уровне операционной системы.',
      'check': 'Проверьте, работает ли камера в других приложениях (например, Photo Booth). Если с камерой всё в порядке, убедитесь, что {{browserName}} имеет доступ к камере.',
      'p1_menu': 'Выберите меню Apple',
      'p1_pref': '→ «Системные настройки», нажмите «Защита и безопасность», затем нажмите «Конфиденциальность»;',
      'p2': 'Выберите «Камера», установите флажок рядом с приложением {{browserName}};',
      'p3': 'Выберите «Микрофон», установите флажок рядом с приложением {{browserName}};',
      'p4': 'Перезапустите {{browserName}} и попробуйте пройти интервью ещё раз.',
      'more': 'Подробнее на сайте',
      'support': 'поддержки Apple',
    },
    'NoSoundWindowsFirefox': {
      'sureConnected': 'Если вы уверены, что ваш микрофон подключен, вероятнее всего доступ к нему запрещён в настройках приватности Windows.',
      'goToStart': 'Перейдите Пуск',
      'parameters': 'Параметры',
      'privacy': 'Конфиденциальность',
      'mic': 'Микрофон. Убедитесь, что:',
      'p1': ' Доступ к микрофону для этого устройства включен;',
      'p2': '«Разрешить приложениям доступ к микрофону» находится в положении «Вкл.»;',
      'p3': '«Разрешить классическим приложениям доступ к микрофону» (если есть) находится в положении «Вкл.»;',
      'p4': 'После внесения изменений в настройки обновите страницу.',
      'more': 'Подробнее на сайте',
      'support': 'поддержки Microsoft',
    },
    'NotAllowedAndroidChrome': {
      'check': 'Не удалось получить доступ к камере и микрофону.',
      'p1': 'Слева от адреса сайта нажмите на значок замка',
      'p2': 'Нажмите на раздел Разрешения;',
      'p3': 'Разрешите доступ к Камере и Микрофону;',
      'p4': 'Перезагрузите страницу.',
      'i1': 'Если вы не видите раздел Разрешения, значит доступ к камере и микрофону заблокирован на уровне операционной системы Android. В Настройках Android в разделе Приложения убедитесь, что для Chrome разрешён доступ к Камере и Микрофону. После этого вернитесь в Chrome и перезагрузите страницу. Подробнее на сайте',
      'more': 'поддержки Android',
    },
    'NotAllowedBlink': {
      'check': 'Вы не дали разрешение на доступ к камере или микрофону.',
      'p1': 'Слева от адреса сайта нажмите на значок замка',
      'p2': 'Разрешите доступ к камере и микрофону;',
      'p3': 'Обновите страницу',
      'p3_check': 'или нажмите сюда',
    },
    'NotAllowedBlinkWindowsSystem': {
      'check': 'Невозможно получить доступ к микрофону/камере. Вероятнее всего доступ к микрофону запрещён в настройках конфиденциальности Windows 10.',
      'goToStart': 'Перейдите Пуск',
      'parameters': 'Параметры',
      'privacy': 'Конфиденциальность',
      'cameraAndSure': 'Перейдите в раздел Микрофон, а затем камера и убедитесь, что доступ к устройству не запрещён:',
      'p1': 'Доступ к микрофону/камере для этого устройства включен;',
      'p2': '«Разрешить приложениям доступ к камере» / «Разрешить приложениям доступ к микрофону» находится в положении «Вкл.»;',
      'p3': '«Разрешить классическим приложениям доступ к камере» / «Разрешить классическим приложениям доступ к микрофону» (если есть) находится в положении «Вкл.»;',
      'p4': 'После внесения изменений в настройки обновите страницу.',
      'more': 'Подробнее на сайте',
      'support': 'поддержки Microsoft',
    },
    'NotAllowedFirefox': {
      'check': 'Вы не дали разрешение на доступ к камере или микрофону.',
      'p1': 'Слева от адреса сайта нажмите на значок перечёркнутой камеры или микрофона;',
      'p2': 'Сбросьте запрет на доступ к камере и микрофону;',
      'p3': 'Обновите страницу;',
      'p4': 'Нажмите Разрешить, когда браузер снова запросит доступ.',
      'more': 'Если рекомендации не помогли, воспользуйтесь',
      'support': 'документацией Firefox',
    },
    'NotAllowedMacOsSafari': {
      'check': 'Вы не дали разрешение на доступ к камере или микрофону.',
      'p1': 'Выберите меню «Safari» → «Настройки» и нажмите «Веб-сайты»;',
      'p2': 'Выберите по очереди Камера и Микрофон и установите «Разрешить» для сайта huntica.works;',
      'p3': 'Закройте настройки и перезагрузите страницу.',
      'more': 'Подробнее на сайте',
      'support': 'поддержки Apple',
    },
    'NotConnected': {
      'notConnected': 'Похоже, что ваша камера не подключена. Пожалуйста подключите камеру.',
    },
    'NotReadableErrorAudioWindows': {
      'check': 'Невозможно получить доступ к микрофону. Вероятнее всего доступ к микрофону запрещён в настройках конфиденциальности Windows 10.',
      'goToStart': 'Перейдите Пуск',
      'parameters': 'Параметры',
      'privacy': 'Конфиденциальность',
      'mic': 'Микрофон. Убедитесь, что:',
      'p1': 'Доступ к микрофону для этого устройства включен;',
      'p2': '«Разрешить приложениям доступ к микрофону» находится в положении «Вкл.»;',
      'p3': '«Разрешить классическим приложениям доступ к микрофону» (если есть) находится в положении «Вкл.»;',
      'p4': 'После внесения изменений в настройки обновите страницу',
      'more': 'Подробнее на сайте',
      'support': 'поддержки Microsoft',
    },
    'NotReadableErrorVideoWindows': {
      'check': 'Невозможно получить доступ к камере. Возможные причины:',
      'p1': 'Камера сейчас используется другим приложением (например, Skype или Zoom). Обычно при этом на камере горит индикатор. Закройте все приложения, которые могут использовать камеру, после чего перезагрузите страницу.',
      'p2': 'Если вы уверены, что камера сейчас не используется, значит доступ к ней запрещён в настройках конфиденциальности Windows 10.',
      'goToStart': 'Перейдите Пуск',
      'parameters': 'Параметры',
      'privacy': 'Конфиденциальность',
      'cameraAndSure': 'Камера и убедитесь, что:',
      'a': 'Доступ к камере для этого устройства включен;',
      'b': '«Разрешить приложениям доступ к камере» находится в положении «Вкл.»;',
      'c': '«Разрешить классическим приложениям доступ к камере» (если есть) находится в положении «Вкл.»;',
      'd': 'После внесения изменений в Настройки обновите страницу.',
      'more': 'Подробнее на сайте',
      'support': 'поддержки Microsoft',
    },
  },
  'termsOfService': {
    'header': 'Соглашение об использовании',
    'parts': [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse consectetur ipsum vel lectus dapibus, in pulvinar purus scelerisque. Proin in sodales lectus. Phasellus dapibus nunc ultricies libero dictum accumsan. Vestibulum porta ornare ex, a molestie tortor placerat quis. Nam posuere dolor eu justo viverra laoreet dignissim ac risus. Maecenas nec arcu eu mi eleifend lacinia ac sed ante. Pellentesque vitae iaculis turpis. Nunc vestibulum quam ut magna commodo molestie. Aliquam neque elit, auctor ut libero id, congue iaculis elit. Aliquam nulla massa, consequat eu metus id, auctor posuere neque. Etiam ac turpis blandit, tempus elit sed, commodo arcu. Integer porttitor ligula eget felis euismod bibendum. Etiam vehicula lectus ligula, vel hendrerit diam iaculis at. Ut cursus imperdiet volutpat.',
      'Aliquam varius egestas ante, nec feugiat elit pharetra et. Ut nec enim tellus. Etiam condimentum tortor nec sem commodo iaculis. Cras eget nunc varius nisl rutrum dapibus. Morbi ac erat vitae nulla iaculis fermentum. Ut eget pellentesque ante. Donec rhoncus at odio at viverra. Sed a feugiat nulla. Vivamus imperdiet velit ac sapien condimentum mollis. Etiam vitae sem mi. Maecenas semper sapien ac metus vehicula tincidunt eu vitae sem. Vestibulum commodo, leo vitae ullamcorper pulvinar, elit ligula pharetra ligula, in commodo enim nulla at velit. Vestibulum pellentesque tincidunt libero et luctus.',
      'Duis sed orci malesuada, sagittis eros vel, semper risus. Sed aliquet leo ac sem cursus commodo. Pellentesque ut euismod sem. Cras facilisis ultrices diam, at condimentum magna rutrum eget. Cras sit amet sapien quis quam ullamcorper pulvinar a eget nulla. Sed lorem dui, dapibus sed ex vel, tincidunt consectetur felis. Donec eu vestibulum eros. Quisque quis ullamcorper lectus. Donec vestibulum rhoncus turpis ut cursus. Vestibulum tristique dictum erat, eget pulvinar ligula dictum at. Sed fringilla placerat nisl in vehicula. Donec sit amet semper nisl. Fusce molestie ornare ex, id rhoncus ante semper accumsan. Mauris bibendum, elit non gravida dictum, magna eros aliquet enim, sit amet semper libero dui et neque. Nullam mattis sem accumsan aliquet congue. Nunc id interdum lacus.',
      'Morbi nunc risus, elementum eu porta eu, gravida non nunc. Nunc eu metus ut libero faucibus commodo. Phasellus efficitur, sapien vel sollicitudin auctor, odio turpis finibus tortor, vel vestibulum nulla dui ac orci. Integer varius volutpat mattis. Nulla at dui elit. Nam sit amet augue rutrum, imperdiet quam ut, congue odio. Vestibulum sagittis purus eu arcu sagittis elementum. Vivamus iaculis, odio sit amet convallis elementum, eros tortor lobortis est, nec aliquet leo nibh et arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sit amet ligula et lectus euismod eleifend eu aliquet libero. Suspendisse scelerisque urna at velit efficitur euismod. Nulla sed velit neque. Vivamus accumsan aliquet rutrum. Quisque in nibh leo. Donec ultricies, felis sed viverra luctus, neque felis volutpat enim, vitae rutrum eros sem in tellus.',
      'Donec a dictum risus, eu lobortis leo. Maecenas commodo accumsan luctus. Pellentesque diam nulla, pulvinar non turpis at, vulputate congue dui. Nulla eu arcu at massa eleifend semper quis sed enim. Sed quis auctor justo. Curabitur iaculis sapien a tellus tempor, et gravida enim tincidunt. In augue magna, aliquam at volutpat et, ultricies in magna.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse consectetur ipsum vel lectus dapibus, in pulvinar purus scelerisque. Proin in sodales lectus. Phasellus dapibus nunc ultricies libero dictum accumsan. Vestibulum porta ornare ex, a molestie tortor placerat quis. Nam posuere dolor eu justo viverra laoreet dignissim ac risus. Maecenas nec arcu eu mi eleifend lacinia ac sed ante. Pellentesque vitae iaculis turpis. Nunc vestibulum quam ut magna commodo molestie. Aliquam neque elit, auctor ut libero id, congue iaculis elit. Aliquam nulla massa, consequat eu metus id, auctor posuere neque. Etiam ac turpis blandit, tempus elit sed, commodo arcu. Integer porttitor ligula eget felis euismod bibendum. Etiam vehicula lectus ligula, vel hendrerit diam iaculis at. Ut cursus imperdiet volutpat.',
      'Aliquam varius egestas ante, nec feugiat elit pharetra et. Ut nec enim tellus. Etiam condimentum tortor nec sem commodo iaculis. Cras eget nunc varius nisl rutrum dapibus. Morbi ac erat vitae nulla iaculis fermentum. Ut eget pellentesque ante. Donec rhoncus at odio at viverra. Sed a feugiat nulla. Vivamus imperdiet velit ac sapien condimentum mollis. Etiam vitae sem mi. Maecenas semper sapien ac metus vehicula tincidunt eu vitae sem. Vestibulum commodo, leo vitae ullamcorper pulvinar, elit ligula pharetra ligula, in commodo enim nulla at velit. Vestibulum pellentesque tincidunt libero et luctus.',
      'Duis sed orci malesuada, sagittis eros vel, semper risus. Sed aliquet leo ac sem cursus commodo. Pellentesque ut euismod sem. Cras facilisis ultrices diam, at condimentum magna rutrum eget. Cras sit amet sapien quis quam ullamcorper pulvinar a eget nulla. Sed lorem dui, dapibus sed ex vel, tincidunt consectetur felis. Donec eu vestibulum eros. Quisque quis ullamcorper lectus. Donec vestibulum rhoncus turpis ut cursus. Vestibulum tristique dictum erat, eget pulvinar ligula dictum at. Sed fringilla placerat nisl in vehicula. Donec sit amet semper nisl. Fusce molestie ornare ex, id rhoncus ante semper accumsan. Mauris bibendum, elit non gravida dictum, magna eros aliquet enim, sit amet semper libero dui et neque. Nullam mattis sem accumsan aliquet congue. Nunc id interdum lacus.',
      'Morbi nunc risus, elementum eu porta eu, gravida non nunc. Nunc eu metus ut libero faucibus commodo. Phasellus efficitur, sapien vel sollicitudin auctor, odio turpis finibus tortor, vel vestibulum nulla dui ac orci. Integer varius volutpat mattis. Nulla at dui elit. Nam sit amet augue rutrum, imperdiet quam ut, congue odio. Vestibulum sagittis purus eu arcu sagittis elementum. Vivamus iaculis, odio sit amet convallis elementum, eros tortor lobortis est, nec aliquet leo nibh et arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sit amet ligula et lectus euismod eleifend eu aliquet libero. Suspendisse scelerisque urna at velit efficitur euismod. Nulla sed velit neque. Vivamus accumsan aliquet rutrum. Quisque in nibh leo. Donec ultricies, felis sed viverra luctus, neque felis volutpat enim, vitae rutrum eros sem in tellus.',
      'Donec a dictum risus, eu lobortis leo. Maecenas commodo accumsan luctus. Pellentesque diam nulla, pulvinar non turpis at, vulputate congue dui. Nulla eu arcu at massa eleifend semper quis sed enim. Sed quis auctor justo. Curabitur iaculis sapien a tellus tempor, et gravida enim tincidunt. In augue magna, aliquam at volutpat et, ultricies in magna.',
    ],
    "downloadPDF": "cкачать PDF",
  },
  "supportUs": {
    "headerTitle": "Наша цель - сделать лучшую платформу для обучения игре в шахматы",
    "headerChessClubTitle": "ChessClub Training",
    "headerChessClubSubTitle": "станет лучше с Вашей помощью!",
    "donatTitle": "Вы можете пожертвовать любую сумму, выбрав удобную для Вас форму оплаты",
    "donatTitleElements": [
      [
        "https://chessclub.spb.ru/landing/img/sbp.svg",
        "Сервис быстрых платежей",
        "https://chessclub.spb.ru/landing/img/sbpQR.png",
        "",
        [
          "откройте приложение Вашего банка",
          "отсканируйте QR-код",
          "укажите сумму",
          "подтвердите платеж"
        ]
      ],
      [
        "https://chessclub.spb.ru/landing/img/wallet.svg",
        "Криптокошелёк",
        "https://chessclub.spb.ru/landing/img/cryptoQR.png",
        "0x53a210adA95b196393137205Ad1D3ED2d9F1A287",
        [
          "Tether (USDT) (ERC20)",
          "Ethereum (ETH)",
          "Solana (SOL)",
          "любые другие"
        ]
      ]
    ]
  },
  "settings": {
    "personalInfo": "Профиль",
    "security": "Безопасность",
    "decoration": "Оформление",
    "userName": "Пользователь",
    "firstName": "Имя",
    "lastName": "Фамилия",
    "dateOfBirth": "Дата рождения",
    "town": "Город",
    'email': 'Электронная почта',
    "sendingMessages": "Получать информационные рассылки о новостях сервиса",
    'password': 'Пароль',
    'repeat_password': 'Повторите пароль',
    "light": "Светлая",
    "dark": "Темная",
    "os_theme": "Использовать системную тему",
    "theme": "Тема",
    "active_color": "Цвет активного элемента",
    "moves_style": "Перемещение фигур по доске",
    "drag": "Перетаскивание",
    "click": "Кликами мышки",
    "flip": "Отразить",
    "rotate": "Повернуть",
    "cancel": "Отмена",
  },
  "textEditor": {
    "titles" :{
      "text1": "Заголовок 1",
      "text2": "Заголовок 2",
      "text3": "Заголовок 3",
      "text4": "Заголовок 4",
      "text0": "Обычный текст",
      "title": "Заголовок",
    },
  },
};