import { useAuthStore } from '../../store/auth/AuthStore';
import { useLocalization } from '../../l10n/UseLocalization';
import { Role } from '@chessclub/grpc_wrapper';
import React from 'react';
import { PageHeaderStyledComponents } from './PageHeaderStyledComponents';
import { LessonStatusPanel } from '../../pages/LessonPage/LessonStatusPanel/LessonStatusPanel';

const {
  StyledNavItems,
  StyledNavItem,
} = PageHeaderStyledComponents;

export function NavLinks(props: {alterNavigation?}) {

  const { user } = useAuthStore();
  const lang = useLocalization();

  if (!user || user.role === Role.GUEST)
    return props.alterNavigation

  const isStudent = user.role === Role.STUDENT || user.role === Role.EXTERNAL;

  return <div style={{display:'flex', justifyContent: 'center', alignItems: 'center'}}>
    <StyledNavItems>
      {!isStudent && <StyledNavItem
        $active={document.location.href.includes('/materials')}
        to='/materials'
        className='withHover'
      >
        {lang.lesson.materials}
      </StyledNavItem>}

      {user.profile && <StyledNavItem
        $active={document.location.href.includes('/rooms')}
        to='/rooms'
        className='withHover'
      >
        {isStudent? lang.lesson.lessons : lang.rooms.rooms}
      </StyledNavItem>}
    </StyledNavItems>

    <LessonStatusPanel />

  </div>
}