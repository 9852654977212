import React, { CSSProperties, ReactNode } from 'react';
import styled from 'styled-components';
import { SvgImage } from '../Icon/SvgImage';
import { useThemeColors } from '../../hooks/useThemeColors';
import { RawSvgString } from '*.svg';
import { Stack } from '../LowLevelComponents/Stack';

const StyledMenuItem = styled.div<{bg?:boolean;active?:boolean;height?:number;disabled?:boolean}>`
  min-width: 115px;
  max-width: 300px;
  height: ${p => p.height || 48}px;
  cursor: pointer;
  font-family: Roboto,serif;
  border-radius: 5px;
  display: flex;
  align-items: center;
  color: ${p => p.theme.currentTheme.iconMain};
  background: ${p => p.bg ? "#8883":null};
  &:hover {
    background:${p => p.theme.currentTheme.hover};
  }
  & * {
    vertical-align: middle
  }
  opacity: ${p => p.disabled?0.5:1};
  pointer-events: ${p => p.disabled?"none":"unset"};
`;

const StyledText = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

type PopupMenuItemParams = {
  icon?: RawSvgString | ReactNode;
  modifier?: RawSvgString | ReactNode;
  status?: RawSvgString | ReactNode;
  text: string | ReactNode;
  onClick?: () => void;
  style?: CSSProperties;
  active?: boolean;
  filled?: boolean;
  height?: number;
  disabled?:boolean;
  bg?: boolean;
};

export function PopupMenuItem(props: PopupMenuItemParams) {
  const { accent } = useThemeColors();

  return <StyledMenuItem {...props} >
    <Stack style={{paddingRight: props.icon ? 5: 10, paddingLeft: 5}}>
      {typeof props.icon === "string" ? <SvgImage
        filled={props.active}
        icon={props.icon as any}
      /> : props.icon}
      {typeof props.modifier === "string" ? <SvgImage
        filled={props.active}
        icon={props.modifier as any}
      /> : props.modifier}
    </Stack>
    <StyledText style={{color: props.active ? accent: null}}>
      {props.text}
    </StyledText>
    <div style={{paddingRight: props.status ? 5: 10, marginLeft:"auto"}}>
      {typeof props.status === "string" ? <SvgImage
        filled={props.active}
        icon={props.status as any}
      /> : props.status}
    </div>
  </StyledMenuItem>
}