import React from 'react';
import { SvgImage } from '../../../../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../../../../components/Icon/KnownIcons';
import { ChessclubUser } from '../../../../../../../interfaces/user';
import { useLessonStore } from '../../../../../../../store/lesson/LessonStore';

export function HandUpIndicator({ user } : {user: ChessclubUser}) {
  const { setStudentHandUp } = useLessonStore();
  if (!user.handUp)
    return null;
  return <SvgImage
    size={24}
    r={4}
    icon={KnownIcons.hand}
    active
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      setStudentHandUp({ userId: user.id, handUp: !user.handUp });
    }}
  />

}