import { useLocalization } from '../../l10n/UseLocalization';
import { useHistory, useLocation } from 'react-router-dom';
import { useAuthStore } from '../../store/auth/AuthStore';
import React from 'react';
import { PopupMenuItem } from '../PopupMenu/PopupMenuItem';
import { PopupMenuSeparator } from '../PopupMenu/PopupMenuSeparator';
import { KnownIcons } from '../Icon/KnownIcons';
import { Role } from '@chessclub/grpc_wrapper/src/api/types/IUser';


export function UserProfilePopupMenu() {
  const { lesson } = useLocalization();
  const location = useLocation();
  const { exit } = useAuthStore();
  const { user } = useAuthStore();
  const history = useHistory();

  const route = (route: string) => ({
    onClick: () => history.push(route),
    active: route === location.pathname
  });

  return <>
    {user.role !== Role.EXTERNAL && < PopupMenuItem text={lesson.settings} icon={KnownIcons.gear} {...route('/user-settings')}/>}
    {user.role !== Role.EXTERNAL && <PopupMenuItem text={user.role === Role.STUDENT ? lesson.lessons :lesson.rooms} icon={KnownIcons.arrow_up_in_rect} {...route('/rooms')}/>}
    {user.role !== Role.EXTERNAL && user.role !== Role.STUDENT  && <PopupMenuItem text={lesson.materials} icon={KnownIcons.two_and_half_lines} {...route('/materials')}/>}
    {user.role === Role.ADMIN && <PopupMenuItem text={lesson.admin_panel} icon={KnownIcons.copy_settings} {...route('/admin')}/>}
    {user.role !== Role.EXTERNAL && <PopupMenuSeparator/>}
    <PopupMenuItem text={lesson.exit} icon={KnownIcons.exit} onClick={() => exit()}/>
  </>
}

