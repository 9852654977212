import Dexie from 'dexie';

// const initialized = {};

export function indexeddbObject<T>(name: string) {
  // if (initialized[name]) {
  //   throw new Error('Already initialized indexeddbObject ' + name);
  // }
  // initialized[name] = 1;

  const dexie = new Dexie("chessclub--" + name);

  dexie.version(1).stores({
    object: 'key, value',
  });

  const object: Dexie.Table
    // @ts-ignore
    = dexie.object;


  return {

    async get(key: string): Promise<T> {
      return (await object.get(key))?.value;
    },

    async put(key: string, value: T): Promise<void> {
      await object.put({ key, value });
    },

    async del(key: string): Promise<void> {
      await object.delete(key);
    },
  };

}