import React, { CSSProperties } from 'react';
import { KnownIcons, LargeText, SvgImage } from '../../components';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { ContactsPageBg } from './ContactsPageBg';
import styled from 'styled-components';
import { Space } from '../../components/LowLevelComponents/Space';
import { Grid } from '../../components/MaterialUI/MaterialUI';

function Text(props:{text:string; style?: CSSProperties, className?:string}) {
  return <div {...props} dangerouslySetInnerHTML={{__html: props.text}}/>
}

const Title = styled(Text)` 
  font-family: Roboto,serif;
  font-size: 28px;
  align-items: center;
  text-align: center;
  color: ${p => p.theme.currentTheme.textMain};
`;

const SubTitle = styled(Text)`
  display: flex;
  flex-direction: row;
  justify-content: center;
  font-family: Roboto,serif;
  align-items: center;
  font-size: 21px;
  color: ${p => p.theme.currentTheme.textMain};
`;

const StyledEmailLink = styled.a`
  display: flex;
  padding-right: 10px;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;

  color: ${({ theme }) => theme.currentTheme.button};
  font: ${({ theme }) => theme.fonts.normal};

  &:hover {
    text-decoration: underline;
  }
`;

const StyledText = styled(Text)`
  display: flex;
  padding-right: 10px;
  align-items: center;
  flex-wrap: nowrap;
  border-radius: 8px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  
  color: ${({ theme }) => theme.currentTheme.iconMain};
  font: ${({ theme }) => theme.fonts.normal};
`;

// <a href="mailto:youremail@example.com
//          ?subject=Saying%20hello%20from%20GFG
//          &body=Hello%20there,%20this%20is%20predefined body
//          &cc=carboncopy@email.com
//          &bcc=blindcarboncopy@email.com
//          ">
//   Send Email With CC and BCC
// </a>

const emailToAdmin = 'mailto:admin@chessclub.spb.ru?subject=Saying%20hello%20from%20GFG';
const emailToSupport = 'mailto:support@chessclub.spb.ru?subject=Saying%20hello%20from%20GFG';


export function Contacts() {
  return <PageTemplate>

    <div style={{ maxWidth: 980, margin: '0 auto' }}>

      <ContactsPageBg/>

      <Grid container justifyContent='center' direction='column' >
        <Space size={200} />
        <Title style={{fontWeight: "bold"}}  text={"Контакты"} />
        <Space size={10} />
        <Grid container justifyContent='space-between' direction='row' style={{paddingTop: 10, flexWrap: "nowrap"}}>
          <div style={{display:"flex"}}>
            <SvgImage icon={KnownIcons.download}/>
            <StyledText text={"Общие вопросы:"} />
          </div>
          <StyledEmailLink href={emailToAdmin}>
            {"admin@chessclub.spb.ru"}
          </StyledEmailLink>
        </Grid>
        <Grid container justifyContent='space-between' direction='row' style={{paddingTop: 10, flexWrap: "nowrap"}}>
          <StyledText text={"Вопросы по работе  сайта:"} />
          <StyledEmailLink href={emailToSupport}>
            {"support@chessclub.spb.ru"}
          </StyledEmailLink>
        </Grid>
        <div style={{display:"flex", justifyContent:"center", paddingTop: 25}}>
          <SvgImage icon={KnownIcons.download}/>
          <StyledText text={"Задать вопрос в чат-бот:"} />
        </div>
        <Space size={280} />
        <SubTitle style={{fontWeight: "bold"}} text={"Наши партнеры"} />
        <Space size={20} />
      </Grid>

    </div>
  </PageTemplate>
}