import React from 'react';
import styled from 'styled-components';
import { Stack } from '../../../../../../../components/LowLevelComponents/Stack';
import { ChessclubUser } from '../../../../../../../interfaces/user';
import { useUiStore } from '../../../../../../../store/uiStore';

const StyledIconWrapper = styled.div`
  display: grid;
  grid-column: 1;
  grid-row: 1;
  z-index: 111;
  pointer-events: none;

  & > img {
    width: 38px;
    border-radius: 50px;
    margin: 7px;
    box-shadow: 0 0 0 2px ${p => p.theme.currentTheme.iconLight};
  }
`;

const StyledAttentionIcon = styled.div<{interfaceClr: string}>`
  display: grid;
  pointer-events: none;
  width: 12px;
  height: 12px;
  grid-column:1;
  grid-row:1;
  margin: 34px 34px 0 34px;
  border-radius: 50%;
  z-index: 115;
    ${({ theme, interfaceClr}) =>
  `background: ${theme.currentTheme[interfaceClr]}; 
  box-shadow: 0 0 0 1px ${theme.currentTheme.iconLight}`}
`;

export function CameraCardAvatar({ user }: { user: ChessclubUser }) {

  const { interfaceClr } = useUiStore();

  return <Stack >
    <StyledIconWrapper>
      <img
        className='hidden'
        alt={'avatar'}
        src={user.imageUrl}
      />
    </StyledIconWrapper>
    {user.attention && <StyledAttentionIcon className='hidden' interfaceClr={interfaceClr}/>}
  </Stack>
}