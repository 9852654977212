import styled from 'styled-components';
import React from 'react';
import { AdminDashboardSubpage } from '../AdminDashboardSubpage';
import { HidingScrollbar } from '../../../components/LowLevelComponents/HidingScrollbar';
import { useAdminKnowledgesStore } from '../../../store/admin/AdminKnowledgesStore';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import { useEditingTopicStore } from '../../../store/editing-topic/EditingTopicStore';
import { TaskEditView } from '../../../parts/TaskEditView/TaskEditView';
import { TopicEditView } from '../../../parts/TopicEditView/TopicEditView';
import { TopicEntry } from '../../../parts/MaterialsEditView/TopicEntry';
import { MaterialsList } from '../../../parts/MaterialsEditView/MaterialsList';
import { AddTopicElement } from '../../../parts/MaterialsEditView/AddTopicElement';
import { KnowledgeToolbar } from './toolbar/KnowledgeToolbar';
import { KnowledgeEditingTaskToolbar } from './toolbar/KnowledgeEditingTaskToolbar';
import { KnowledgeEditingTopicToolbar } from './toolbar/KnowledgeEditingTopicToolbar';
import { EditViewHeader } from '../../../parts/EditViewHeader';
import { newKnowledge } from './NewKnowledge';
import { newKnowledgeTopic } from './NewKnowledgeTopic';
import { media_max_width_1100, media_max_width_1300 } from '../../../mediaQuery';

const KnowledgePageContentLayout = styled.div`
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: 0fr;
  padding-top: var(--top-gap);
  height: 100%;
  gap: 30px;
  
  @media ${media_max_width_1300} {
    grid-template-columns: 350px 1fr;
  }
  @media ${media_max_width_1100} {
    grid-template-columns: 300px 1fr;
  }
  
`;

const KnowledgePagePartLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;  
`;


export function AdminDashboardSubpageKnowledge() {
  return <AdminDashboardSubpage
    subtitle={'База знаний'}
    toolbar={<KnowledgeToolbar />}
  >
    <KnowledgePageContent />
  </AdminDashboardSubpage>;
}

function KnowledgePageContent() {
  const { editingTask } = useEditingTaskStore();
  if (editingTask)
    return <TaskEditView>
      <EditViewHeader text={"Edit knowledge task"}>
        <KnowledgeEditingTaskToolbar/>
      </EditViewHeader>
    </TaskEditView>;

  return <KnowledgePageContentLayout>
    <KnowledgePagePartLayout style={{gap: 30}}>
      <KnowledgeTopics />
    </KnowledgePagePartLayout>
    <KnowledgePagePartLayout>
      <KnowledgePageRightPanelComponent />
    </KnowledgePagePartLayout>
  </KnowledgePageContentLayout>;
}

function KnowledgePageRightPanelComponent() {
  const { selectedTopic } = useAdminKnowledgesStore();
  const { editingTopic } = useEditingTopicStore();

  if (editingTopic)
    return <TopicEditView>
      <KnowledgeEditingTopicToolbar/>
    </TopicEditView>;

  if (!selectedTopic)
    return null;

  return <Knowledges />;
}

function Knowledges() {
  const {selectedTopic, pageKnowledges, deleteKnowledge, page, pageCount, setPage} = useAdminKnowledgesStore();
  return <MaterialsList
    page={page}
    pageCount={pageCount}
    selectedTopic={selectedTopic}
    setPage={setPage}
    materials={pageKnowledges}
    factory={newKnowledge}
    onDelete={deleteKnowledge}
  />;
}

function KnowledgeTopics() {
  const { knowledgeTopics, selectTopic, selectedTopic, deleteTopic } = useAdminKnowledgesStore();
  return <HidingScrollbar>
    <AddTopicElement topicFactory={newKnowledgeTopic}/>
    {knowledgeTopics.map((topic, i) => <TopicEntry
      topic={topic}
      selected={selectedTopic?.id === topic.id}
      key={i}
      onClick={() => selectTopic(topic)}
      onDelete={() => deleteTopic(topic)}
    />)}
  </HidingScrollbar>;
}

