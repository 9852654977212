import { Theme, useThemeStore } from '../../store/themeStore';
import React, { useRef } from 'react';
import { SvgImage } from '../Icon/SvgImage';
import { selectFiguresIcons } from '../ChessTools/figures-icons/selectFiguresIcons';
import { FullMoveDescription } from '@chessclub/web-game-server/src/shared/core/calcMoves';
import { FigureType } from '../ChessTools/figures-icons/FigureType';
import { useTasksStore } from '../../store/tasks/TasksStore';
import { KnownPieces, makePieceKey } from '@chessclub/web-game-server';
import { useLessonUiStore } from '../../store/lesson-ui/LessonUiStoreImpl';

let style = { verticalAlign: 'middle', display: 'inline-block' };

export function PgnRecordWidget(props: FullMoveDescription & { i: number }) {
  const {setMouseOverStep} = useLessonUiStore();
  const { theme } = useThemeStore();
  const { currentTaskGameApi, currentTaskGameState } = useTasksStore();
  const isWhite = props.piece?.color === 'w';
  const icons = selectFiguresIcons(isWhite, theme === Theme.dark);
  const split = props.move.split('-');
  const text = props.pgn || (split[1] || '+') + (split[2] || '-');
  const icon = getFigureType(props.piece?.type);
  const img = KnownPieces.getResourcesPath(makePieceKey(props.piece));
  const bgColor = currentTaskGameState?.moveIndex === props.i ? 'var(--color-hover)' : 'transparent';
  const delayRef = useRef<any>();

  function enter() {
    delayRef.current = setTimeout(() => setMouseOverStep({...props, index: props.i}), 100)
  }

  function leave() {
    delayRef.current && clearTimeout(delayRef.current)
    delayRef.current = null;
  }

  let border = `1px solid ${props.auto?"var(--color-accent)":"transparent"}`;
  return <div
    onClick={() => {
      currentTaskGameApi.setStepIndex(props.i);
      currentTaskGameApi.requestUpdate();
    }}
    style={{ background: bgColor, boxSizing: 'border-box', border, borderRadius:6 }}
    onMouseEnter={enter}
    onMouseLeave={leave}
  >
    {props.i !== undefined && props.i % 2 === 0 && <span>{1 + (props.i / 2) | 0}.</span>}
    {icon !== FigureType.pawn && (icon ? <SvgImage
      style={style}
      icon={icons[icon]}
      size={20}
    /> : <img
      height={20}
      src={img}
      style={style}
    />)}
    <span>{text}</span>
  </div>;
}

function getFigureType(type): FigureType {
  switch (type) {
    case 'b' :
      return FigureType.bishop;
    case 'r' :
      return FigureType.rook;
    case 'n' :
      return FigureType.knight;
    case 'k' :
      return FigureType.king;
    case 'q' :
      return FigureType.queen;
    case 'p' :
      return FigureType.pawn;
  }
}