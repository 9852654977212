import React from "react";
import { useLocalization } from "../../../l10n/UseLocalization";
import { useLessonStore } from '../../../store/lesson/LessonStore';
import { useLessonUiStore } from '../../../store/lesson-ui/LessonUiStoreImpl';
import { useUiStore } from '../../../store/uiStore';
import { LessonViewMode } from '../../../store/lesson-ui/LessonUiStoreApi';
import { PopupMenuItem } from '../../../components/PopupMenu/PopupMenuItem';
import { PopupMenuSeparator } from "../../../components/PopupMenu/PopupMenuSeparator";
import { KnownIcons } from '../../../components/Icon/KnownIcons';

export function LessonViewPopupMenu() {

  const { lesson: lang } = useLocalization();
  const { isStudentLayout } = useLessonStore();
  const { view, setView } = useLessonUiStore();
  const { sizes, changeSize } = useUiStore();

  const icon = LessonViewMode.CAMERAS === view ? KnownIcons.four_cams : KnownIcons.four_chessboards;

  return <>
    {[
      {
        text: lang.standardView,
        icon: KnownIcons.chessboard,
        onClick: () => setView(LessonViewMode.STANDARD),
        checked: view === LessonViewMode.STANDARD,
        active: view === LessonViewMode.STANDARD,
        skip: isStudentLayout,
      },
      {
        text: lang.allChessboardsView,
        icon: KnownIcons.four_chessboards,
        onClick: () => {
          setView(LessonViewMode.CHESSBOARDS);
        },
        checked: view === LessonViewMode.CHESSBOARDS,
        active: view === LessonViewMode.CHESSBOARDS,
        skip: isStudentLayout,
      },
      {
        text: lang.allCamsView,
        icon: KnownIcons.four_cams,
        onClick: () => setView(LessonViewMode.CAMERAS),
        checked: view === LessonViewMode.CAMERAS,
        active: view === LessonViewMode.CAMERAS,
        skip: isStudentLayout,
      },
      ...(view !== LessonViewMode.STANDARD
        ? [
          null,
          {
            text: lang.size + ' 1',
            icon,
            checked: sizes[view] === 1,
            active: sizes[view] === 1,
            onClick: () =>
              changeSize({
                [view]: 1,
              }),
          },
          {
            text: lang.size + ' 2',
            icon,
            checked: sizes[view] === 2,
            active: sizes[view] === 2,
            onClick: () =>
              changeSize({
                [view]: 2,
              }),
          },
          {
            text: lang.size + ' 3',
            icon,
            checked: sizes[view] === 3,
            active: sizes[view] === 3,
            onClick: () =>
              changeSize({
                [view]: 3,
              }),
          },
          {
            text: lang.size + ' 4',
            icon,
            checked: sizes[view] === 4,
            active: sizes[view] === 4,
            onClick: () =>
              changeSize({
                [view]: 4,
              }),
          },
        ]
        : []),// Только на больших экранах
    ].map((el, i) => {
      if (el === null)
        return <PopupMenuSeparator key={i} />
      return <PopupMenuItem key={i} {...el} />
    })}

  </>;
}