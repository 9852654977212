import React from 'react';
import styled from 'styled-components';
import { FullMoveDescription } from '@chessclub/web-game-server/src/shared/core/calcMoves';

const StyledSelectedText = styled.div`
  margin: 0;
  max-width: 98%;
  overflow-wrap: break-word;
  word-wrap: break-word;
  white-space: normal;
  user-select: text;

  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.iconMain};
  font-size: 16px;
  box-sizing: border-box;
  line-height: 1.2;
  padding: 5px 5px 5px 5px;
  min-width: 5%x;
`

export function MovesText({ moves }: { moves?: FullMoveDescription[]}) {

  let pairs : FullMoveDescription[][]= [];
  if (moves) {
    for (let i = 0; i < moves.length; i += 2) {
      pairs.push(moves.slice(i, i + 2));
    }
  }
  //contentEditable
  return <StyledSelectedText  >
    {moves.map((m, i) => {
      return (i % 2 ? "" :((1+ i/2)|0) + ".") + (m.pgn || m.move);
    }).join(" ")}
  </StyledSelectedText>;
}


