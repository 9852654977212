import React from 'react';
import { LessonToolbarButton } from '../components';
import { PopupMenuItem } from '../../../../components/PopupMenu/PopupMenuItem';
import { useLocalization } from '../../../../l10n/UseLocalization';
import { useLessonUiStore } from '../../../../store/lesson-ui/LessonUiStoreImpl';
import { MovesDisplayMode } from '../../../../store/lesson-ui/LessonUiStoreApi';
import { PopupMenu } from '../../../../components/PopupMenu/PopupMenu';
import { PopupMenuSeparator } from '../../../../components/PopupMenu/PopupMenuSeparator';
import { SvgImage } from '../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../components/Icon/KnownIcons';
import { TextEditorIcons } from '../../../../components/TextEditor/TextEditorIcons';
import { useTaskToDisplay } from '../../../../hooks/useTaskToDisplay';
import { useTasksStore } from '../../../../store/tasks/TasksStore';
import { QuestionLike } from '@chessclub/web-game-server/src/shared/core/api';
import { useLessonStore } from '../../../../store/lesson/LessonStore';

export function MovesDropdown() {
  return <PopupMenu popupContent={MovesDropdownContent}>
    <LessonToolbarButton>
      <SvgImage icon={KnownIcons.triple_dot_horizontal} rotate={90}/>
    </LessonToolbarButton>
  </PopupMenu>;
}

function MovesDropdownContent() {
  const { lesson } = useLocalization();
  const { movesMode, setMovesDisplayMode } = useLessonUiStore();
  const { taskToDisplay } = useTaskToDisplay();
  const { currentTaskGameState, currentTaskGameApi, updateQuestion, updateTaskFields } = useTasksStore();

  return <>
    {!useLessonStore.getState().isOnline &&
      <PopupMenuItem
    text={lesson.clear}
    icon={KnownIcons.eraser}
    onClick={() => {
      const actualMove = currentTaskGameState?.movesState[taskToDisplay.moveIndex];
      if (!actualMove)
        return;

      const newTask = {...taskToDisplay,  fen: actualMove.fen, moves: [], moveIndex: -1 }

      currentTaskGameApi.init(newTask as QuestionLike);
      currentTaskGameApi.requestUpdate();
      updateTaskFields(taskToDisplay.id, newTask)
    }}
  />}
    {!useLessonStore.getState().isOnline &&
      <PopupMenuSeparator/> }

    <PopupMenuItem
      text={lesson.table}
      icon={TextEditorIcons.table}
      active={movesMode === MovesDisplayMode.TABLE}
      onClick={() => setMovesDisplayMode(MovesDisplayMode.TABLE)}
    />
    <PopupMenuItem
      text={lesson.chain}
      icon={TextEditorIcons.chain}
      active={movesMode === MovesDisplayMode.CHAIN}
      onClick={() => setMovesDisplayMode(MovesDisplayMode.CHAIN)}
    />
    <PopupMenuItem
      text={lesson.text}
      icon={KnownIcons.two_and_half_lines}
      active={movesMode === MovesDisplayMode.TEXT}
      onClick={() => setMovesDisplayMode(MovesDisplayMode.TEXT)}
    />
  </>;
}