import React, { useEffect, useState } from 'react';
import { Grid } from '../../components/MaterialUI/MaterialUI';
import Input from '../../components/LowLevelComponents/Input';
import { CaptchaStep } from '../../components/Captcha/CapthcaStep';
import PasswordInput from '../../components/LowLevelComponents/PasswordInput';
import { ButtonWrapper, InputWrapper, StyledButton, StyledMessage } from '../RegisterPage/RegisterSteps';
import { useAuthStore } from '../../store/auth/AuthStore';
import { useStoredState } from '../../hooks/useStoredState';
import { ServerApi } from '../../transport/ServerApi';
import { loggedUserFromGrpcUser } from '../../logic/loggedUserFromGrpcUser';
import { useLocalization } from '../../l10n/UseLocalization';
import { useUiStore } from '../../store/uiStore';
import { TextLink } from '../../components/PageStyledComponents/TextLink';
import { EmailField } from '../../components/EmailField/EmailField';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { LoginPageContainer } from '../../components/LoginPageContainer/LoginPageContainer';
import { IAuthFormErrors } from '../../interfaces/auth';
import { LogoSize } from '../../components/Icon/Logo';


export function RecoverPage() {
  const { user, setUser } = useAuthStore();
  const {lang: siteLang} = useUiStore();
  const [notValid, setNotValid] = useState<boolean>(false)
  const [email, setEmail] = useStoredState<string | undefined>('register-email', '');
  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setRepeatPassword] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const initialErrors: IAuthFormErrors = { email: false, password: false, passwordRepeat: false, code:false };
  const [errors, setErrors] = useState(initialErrors);
  const [error, setError] = useState('');

  const [step, setStep] = useState<'initial' | 'enter-password' | 'captcha'>('initial');
  const { auth } = useLocalization();

  const [passwordDoesNotMatch, setPasswordsDoesNotMatch] = useState<boolean>(false);
  const [isButtonActionDisabled, setButtonActionDisabled] = useState<boolean>(true);

  const [inProgress, setInProgress] = useState<boolean>(false);

  useEffect(() => {
    let errors: IAuthFormErrors = {};

    switch (step) {
      case 'initial':
        if (!email || notValid) errors.email = true;
        setButtonActionDisabled(!email || notValid)
        break;
      case 'enter-password':
        let notMatchOrEmpty = password && passwordRepeat && password !== passwordRepeat;
        if (notMatchOrEmpty) {
          errors.password = true;
          errors.passwordRepeat = true;
        }
        setPasswordsDoesNotMatch(notMatchOrEmpty)
        errors.code = false;

        setButtonActionDisabled(!password || !passwordRepeat || !code || notMatchOrEmpty)
        break;
    }
    setError("");
    setErrors(errors);
  }, [password, passwordRepeat, code])


  const handleClick = async () => {
    let errors: IAuthFormErrors = {};

    switch (step) {
      case 'initial':
        const result = await ServerApi.accessService.restorePassword(email, siteLang);

        if( typeof result === 'string') {
          setError(result);
          setStep('initial');
        } else {
          setError('');
          setStep('captcha');
        }
        break;

      default:
        const user = await ServerApi.accessService.completeRestorePassword(
          email,
          password,
          code
        )

        if (typeof user === "string") {
          errors.code = true;
          setError(user)
        } else {
          setUser(loggedUserFromGrpcUser(user));
        }

        setStep('enter-password');
        break;
    }

    setErrors(errors);
  };

  return <PageTemplate header={false}>

    {step === 'initial' && (
      <LoginPageContainer>
        <Grid container justifyContent="center" direction="column">
          <StyledMessage>
            {auth.email_to_restore}
          </StyledMessage>
          <InputWrapper>
            <EmailField
              errorText={auth.errors[error]}
              email={email}
              notValid={notValid}
              setNotValid={setNotValid}
              setEmail={(email) => {
                setEmail(email);
              }}
            />
          </InputWrapper>
          <ButtonWrapper>
            <StyledButton
              fullWidth
              onClick={async () =>{
                setInProgress(true)
                await handleClick()
                setInProgress(false)
              }}
              disabled={isButtonActionDisabled || inProgress}
            >
              {auth.continue}
            </StyledButton>
          </ButtonWrapper>
          <TextLink linkText={auth.login} to={'/auth/login/'}/>
        </Grid>
      </LoginPageContainer>
    )}

    {step === 'captcha' && (
      <LoginPageContainer logoSize={LogoSize.medium}>
        <Grid container justifyContent="center" direction="column">
          <CaptchaStep
            email={email}
            setPhase={() => setStep('enter-password')}
            confirmButtonText={auth.continue}
            onSuccess={() => setStep('enter-password')}
          />
        </Grid>
      </LoginPageContainer>
    )}

    {step === 'enter-password' && (
      <LoginPageContainer>
        <Grid container justifyContent="center" direction="column">
          <StyledMessage>
            {auth.consider_new_password + ' ' + email}
          </StyledMessage>
          <InputWrapper>
            <PasswordInput
              showErrorText={true}
              errorText={password && passwordRepeat && passwordDoesNotMatch ?
                auth.password_not_match : auth.errors[error]}
              label={auth.new_password}
              fullWidth
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              error={errors.password}
            />
          </InputWrapper>
          <InputWrapper>
            <PasswordInput
              label={auth.repeat_new_password}
              fullWidth
              value={passwordRepeat}
              onChange={(e: any) => setRepeatPassword(e.target.value)}
              error={errors.passwordRepeat}
            />
          </InputWrapper>
          <InputWrapper>
            <Input
              label={auth.code}
              fullWidth
              value={code}
              onChange={(e: any) => setCode(e.target.value)}
              error={errors.code}
            />
          </InputWrapper>
          <ButtonWrapper>
            <StyledButton
              fullWidth
              onClick={async () =>{
                setInProgress(true)
                await handleClick()
                setInProgress(false)
              }}
              disabled={isButtonActionDisabled || inProgress}
            >
              {auth.confirm}
            </StyledButton>
          </ButtonWrapper>
          <TextLink linkText={auth.login} to={'/auth/login/'}/>
        </Grid>
      </LoginPageContainer>
    )}
  </PageTemplate>
}
