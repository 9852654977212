import React from 'react';
import { useLessonStore } from '../../../../store/lesson/LessonStore';
import { KnownIcons } from '../../../../components/Icon/KnownIcons';
import LessonTasksPanel from './LessonTasksPanel';
import { LessonAddTaskButton } from './LessonAddTaskButton';
import { LessonTabs } from '../../../../store/lesson-ui/LessonUiStoreApi';
import { LessonSideBarTabProps } from '../components';

export function LessonTasksTab() :LessonSideBarTabProps{
  const { isStudentLayout, demonstrationState, studentViewSettings } = useLessonStore();

  function calcShowTasksTab() {
    if (!isStudentLayout) {
      return true;
    } else if (demonstrationState) {
      return false;
    } else {
      return studentViewSettings.tasks;
    }
  }

  if (!calcShowTasksTab())
    return null

  return {
    type: LessonTabs.TASKS,
    icon: KnownIcons.question_in_rect,
    additionalButtons: isStudentLayout ? [] : [<LessonAddTaskButton key={0}/>],
    content: <LessonTasksPanel />,
  };

}
