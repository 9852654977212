import {create} from 'zustand';
import { LessonViewMode } from './lesson-ui/LessonUiStoreApi';
import { ServerApi } from '../transport/ServerApi';
import { useAuthStore } from './auth/AuthStore';
import { localStorageKeys, localStorageObject } from '../helpers/localStorageObject';
import { Lang } from '@chessclub/grpc_wrapper/src/api/types/IUser';
import { Piece } from '@chessclub/ext_chess.ts/src/types';

export enum InterfaceClr {
    orange = 'orange',
    sky = 'sky',
    green = 'green',
    violet = 'violet',
}

const savedInterfaceClr = localStorageObject<InterfaceClr>(localStorageKeys.interfaceClr_key, InterfaceClr.orange);
type Sizes = {
    [key in LessonViewMode]?: number;
};

const defaultViewSizes: Sizes = {
    [LessonViewMode.STANDARD]: 2,
    [LessonViewMode.CAMERAS]: 2,
    [LessonViewMode.CHESSBOARDS]: 2
};
const savedViewSizes = localStorageObject<Sizes>(localStorageKeys.viewSizes_key, defaultViewSizes);

const storedLang = localStorageObject<Lang>(localStorageKeys.lang_key, Lang.ru);

const storedRecentFigures = localStorageObject<Piece[]>(localStorageKeys.recentFigures_key, []);

export interface UiStoreInitialState {
    lang: Lang
    sizes: Sizes;
    interfaceClr: InterfaceClr;
    draggingFigure: null | Piece;
    placeholder: string;
    recentFigures: Piece[];
}

export interface UiStoreActions {
    setPlaceholder(placeholder: string);
    setLang(lang: Lang);
    setDraggingFigure(draggingFigure: Piece): void;
    setHandUpAnimate(handUpAnimate: boolean): void;
    setInterfaceCLr(interfaceClr: InterfaceClr): void;
    changeSize(sizes: Sizes): void;
}

export type UiStore = UiStoreInitialState & UiStoreActions;

const initialState: UiStoreInitialState = {
    lang: storedLang.restore(),
    sizes: savedViewSizes.restore(),
    interfaceClr: savedInterfaceClr.restore(),
    draggingFigure: null,
    placeholder: null,
    recentFigures: storedRecentFigures.restore(),
};

function setPageLang(lang: Lang) {
    document.documentElement.lang = lang;
}

export const useUiStore = create<UiStore>((
  set,
  get
) => {

    setPageLang(initialState.lang)

    return {
        ...initialState,

        setPlaceholder(placeholder: string) {
            set({placeholder})
        },

        async setLang(lang: Lang) {
            storedLang.save(lang);
            set({ lang });
            setPageLang(lang)
            const user = useAuthStore.getState().user;
            if (user && user.id) {
                await ServerApi.profileService.setProfileLanguage(user.id, lang);
            }
        },
        setDraggingFigure: (draggingFigure: Piece) => {
            const { recentFigures } = get();

            console.log(draggingFigure.id)

            if (draggingFigure.id && !recentFigures.find(piece => piece.id === draggingFigure.id)) {
                recentFigures.push(draggingFigure);
                if (recentFigures.length > 14) {
                    recentFigures.shift();
                }
                set({recentFigures});
                storedRecentFigures.save(recentFigures);
            }

            set({draggingFigure});
        },
        setInterfaceCLr: (interfaceClr: UiStoreInitialState['interfaceClr']) => {
            savedInterfaceClr.save(interfaceClr);
            set({ interfaceClr });
        },

        changeSize: (sizes: UiStoreInitialState['sizes']) => {
            savedViewSizes.save({ ...savedViewSizes.restore(), ...sizes });
            set({ sizes: { ...get().sizes, ...sizes } });
        },
    } as UiStore;
});
