import LessonTasksPanel from '../pages/LessonPage/LessonView/LessonTasksTab/LessonTasksPanel';

const version = 0;

export enum localStorageKeys {
  interfaceClr_key = 'interfaceClr',
  viewSizes_key = 'view-sizes',
  recentFigures_key = 'recent-figures',
  lang_key = 'lang',
  currentPiecesGroup_key = 'current-pieces-group',
  scrollbarPosTemp_key = 'scrollbar-pos-temp',
  scrollbarExtraPieces_key = "scrollbar-extra-pieces",
  scrollbarStudentMenu_key = "scrollbar-student-menu",
  scrollbarChat_key = "scrollbar-chat",
  scrollbarTasksPanel_key = "scrollbar-tasks-panel",
  scrollbarVideoRoom_key = "scrollbar-video-room"
}

export function localStorageObject<T>(key: string, defaultValue: T) {

  const versionKey = makeKey('version');
  const oldVersion = restore(versionKey, -1);

  if (oldVersion < version) {
    localStorage.removeItem(makeKey(oldVersion));
    save(versionKey, version);
  }

  key = makeKey(version);

  function makeKey(ver) {
    return `chessclub-${ver}-${key}`;
  }

  function save<X>(KEY: string, value: X) {
    localStorage.setItem(KEY, JSON.stringify(value));
  }

  function restore<X>(KEY: string, def: X): X {
    const value = localStorage.getItem(KEY);
    try {
      return value ? JSON.parse(value) : def;
    } catch (e) {
      return def;
    }
  }

  return {
    save: (value: T) => save(key, value),
    restore: () => restore(key, defaultValue),
    clear: () => localStorage.removeItem(key),
  };
}