import React from 'react';
import { useThemeColors } from '../../../../../../../hooks/useThemeColors';
import { useLessonStore } from '../../../../../../../store/lesson/LessonStore';
import { useConferenceStore } from '../../../../../../../store/conference/conferenceStore';

export function CameraCardSelection(props: { user }) {
  const { user } = props;
  const { accent, red } = useThemeColors();
  const { monitoringStudentId } = useLessonStore();
  const { selectedCameras } = useConferenceStore();
  const selected = !!selectedCameras.find((u) => u.id === user.id);

  const borderColor = monitoringStudentId ?
    monitoringStudentId === user.id ? accent : 'transparent' :
    selected ? red : 'transparent';

  return <div style={{
    pointerEvents: 'none',
    zIndex: 111,
    borderLeft: '3px solid ' + borderColor,
  }} />;

}