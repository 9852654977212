import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { ButtonWrapper, InputWrapper, StyledButton, StyledMessage } from './RegisterSteps';
import { Grid } from '../../components/MaterialUI/MaterialUI';
import { TextLink } from '../../components/PageStyledComponents/TextLink';
import { RegisterStep } from '../../store/auth/AuthStoreApi';
import { ServerApi } from '../../transport/ServerApi';
import { useUiStore } from '../../store/uiStore';
import { useLocalization } from '../../l10n/UseLocalization';
import { Role } from '@chessclub/grpc_wrapper';
import { EmailField } from '../../components/EmailField/EmailField';
import { validateEmail } from '../../logic/validateEmail';

type EmailPhaseProps = {
  email: string;
  setEmail(email: string): void;
  setPhase(phase: RegisterStep): void;
};

export const EmailStep = (props: EmailPhaseProps) => {
  const { auth } = useLocalization();
  const { lang } = useUiStore();
  const [error, setError] = useState('');
  const [notValid, setNotValid] = useState<boolean>(false);

  useEffect(() => {
    setError('');
    setNotValid(!validateEmail(props.email));
  }, [props.email]);

  const requestCaptchaIsNeeded = useCallback(async function() {
    const response = await ServerApi.captchaService.emailIsExpired(props.email);
    props.setPhase(response ? RegisterStep.CAPTCHA : RegisterStep.COMPLETE);
  }, [props.email]);

  const registerAttempt = useCallback(async function() {
    setError('');
    const href = document.location.href;
    const role = href.endsWith('register') || href.endsWith('register/') ? Role.TEACHER : Role.STUDENT;
    const result = await ServerApi.accessService.registration(props.email, lang, role);
    result ? setError(result) : await requestCaptchaIsNeeded();
  }, [props.email, lang]);

  return <>
    <StyledMessage>{auth.sign_up}</StyledMessage>
    <InputWrapper>
      <EmailField
        errorText={auth.errors[error]}
        notValid={notValid}
        setNotValid={setNotValid}
        email={props.email}
        // onEnterPressed={registerAttempt}
        setEmail={props.setEmail}
      />
    </InputWrapper>
    <ButtonWrapper>
      <ButtonWithProgress
        fullWidth
        onClick={registerAttempt}
        disabled={!props.email || notValid}
      >
        {auth.receive_code_in_mail}
      </ButtonWithProgress>
    </ButtonWrapper>
    <Grid container justifyContent='center' direction='column'>
      <TextLink linkText={auth.login} to={'/auth/login'} />
    </Grid>
  </>;
};


function ButtonWithProgress({ fullWidth, disabled, onClick, children }: PropsWithChildren<{
  disabled: boolean;
  onClick: () => void;
  fullWidth?:boolean;
}>) {

  const [inProgress, setInProgress] = useState<boolean>(false);

  const onButtonClick = useCallback(async () => {
    setInProgress(true);
    await onClick();
    setInProgress(false);
  }, [onClick]);

  return <StyledButton
    fullWidth={fullWidth}
    onClick={onButtonClick}
    disabled={disabled || inProgress}
  >
    {children}
  </StyledButton>;
}