import Dexie from 'dexie';
import { ITopic, UserId } from '@chessclub/grpc_wrapper';


const dexie = new Dexie('chessclub--admin-knowledges');

dexie.version(1).stores({
  selectedKnowledgeTopic: 'userId',
});

const selectedKnowledgeTopic: Dexie.Table
  // @ts-ignore
  = dexie.selectedKnowledgeTopic;

export const selectedKnowledgeTopicDb = {
  async getSelectedKnowledgeTopic(userId: UserId) : Promise<ITopic> {
    return (await selectedKnowledgeTopic.get(userId))?.topic;
  },

  async saveSelectedKnowledgeTopic(userId: UserId, topic: ITopic) {
    await selectedKnowledgeTopic.put({topic, userId});
  },

  async deleteSelectedKnowledgeTopic(userId: UserId) {
    await selectedKnowledgeTopic.delete(userId);
  },
}