import React from 'react';
import { useLessonStore } from '../../../store/lesson/LessonStore';
import { OnlineButton } from '../../../components/OnlineButton/OnlineButton';
import { DemonstrationButton } from '../LessonView/buttons/DemonstrationButton';
import { KnownIcons } from '../../../components/Icon/KnownIcons';
import { SvgImage } from '../../../components/Icon/SvgImage';
import { HandeHochButton } from '../LessonView/buttons/HandeHochButton';
import { PopupMenu } from '../../../components/PopupMenu/PopupMenu';
import { LessonViewPopupMenu } from '../Toolbar/LessonViewPopupMenu';
import { LessonStatusPanelWrapper } from './LessonStatusPanelWrapper';
import { TextEllipsis } from '../../../components/LowLevelComponents/EllipsedText';
import styled from 'styled-components';

const StyledLessonNameText = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  padding-right: 10px;
  font: ${p => p.theme.fonts.normal};
  color: ${p => p.theme.currentTheme.iconMain};
  overflow: hidden;
`;



export function TeacherLessonStatusPanel() {
  const { lessonName, roomId, isOnline, handIsUp } = useLessonStore();

  if (!roomId)
    return null;

  const active = document.location.href.includes('/lesson/');

  return <LessonStatusPanelWrapper
    $active={active}
    to={'/lesson/' + roomId}
    $online={isOnline}
    $hand={handIsUp}
  >
    <div>
      <OnlineButton roomId={roomId}>
        {isOnline ? <SvgImage icon={KnownIcons.power_button_filled} withHover filled={isOnline}/> //<OnOffIcon/>
          : <SvgImage icon={KnownIcons.power_button} withHover filled={isOnline} />}
      </OnlineButton>
    </div>

      <TextEllipsis text={lessonName}/>

    {isOnline && <div>
      <HandeHochButton />
      <DemonstrationButton />
      <PopupMenu popupContent={LessonViewPopupMenu}>
        <SvgImage withHover icon={KnownIcons.triple_dot_horizontal} rotate={90} />
      </PopupMenu>
    </div>}

  </LessonStatusPanelWrapper>;
}