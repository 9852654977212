import React from 'react';
import { useUiStore } from '../../store/uiStore';
import { Lang } from '@chessclub/grpc_wrapper/src/api/types/IUser';
import { PopupMenuItem } from '../PopupMenu/PopupMenuItem';
import { PopupMenu } from '../PopupMenu/PopupMenu';
import styled from 'styled-components';

const langs = {
  [Lang.ru]: "Русский",
  [Lang.en]: "English",
  [Lang.cn]: "中文",
  [Lang.hi]: "हिन्दी",
}

export function LangSwitch() {
  return <PopupMenu popupContent={LangSelectPopupContent}>
    <LangButton />
  </PopupMenu>
}

export const LangBtnStyled = styled.div`
  padding: 5px 5px;
  display: flex;
  align-items: center;
  height: 32px;
  color: ${({ theme }) => theme.currentTheme.iconMain};
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background-color: ${({ theme }) => theme.currentTheme.hover};
  }
`;



function LangButton() {
  const { lang, interfaceClr } = useUiStore();
  return <LangBtnStyled>
    {langs[lang]}
  </LangBtnStyled>
}

function LangSelectPopupContent() {
  const { setLang, lang } = useUiStore();
  return <>
    {Object.entries(langs).map((entry, i) => {
      return <PopupMenuItem
        height={38}
        active={lang === entry[0]}
        key={i}
        text={entry[1]}
        onClick={() => setLang(entry[0] as Lang)}
      />
    })}
  </>
}