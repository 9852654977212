import { create } from 'zustand';
import { MaterialsMode, MaterialsStoreActions, MaterialsStoreState } from './MaterialsStoreApi';
import { ITopic, TopicType, UserId } from '@chessclub/grpc_wrapper';
import { ServerApi } from '../../transport/ServerApi';
import { useAuthStore } from '../auth/AuthStore';
import { localStorageObject } from '../../helpers/localStorageObject';
import { IMaterial, MaterialSearchParams } from '@chessclub/grpc_wrapper/src/api/types/IMaterial';
import { searchCriteriaIsEmpty } from './searchCriteriaIsEmpty';
import { replaceById } from '../../helpers/replaceById';
import { selectedKnowledgeTopicDb, selectedMaterialTopicDb } from './materialStoreDb';

export type MaterialsStore = MaterialsStoreState & MaterialsStoreActions;

const savedMode = localStorageObject<MaterialsMode>('materials-mode', MaterialsMode.MATERIALS);
const savedKnowledgePage = localStorageObject<number>('materials-page-knowledge', 0);
const savedMaterialPage = localStorageObject<number>('materials-page-material', 0);
const savedPageSize = localStorageObject<number>('materials-page-size', 14);


const initialState: MaterialsStoreState = {
  mode: savedMode.restore(),
  materialTopics: [],
  knowledgeTopics: [],
  page: 0,
  setPage: null,
  pageCount: 0,
  pageSize: savedPageSize.restore(),
  materials: [],
  pageMaterials: [],
  selectedTopic: null,
  searchCriteria: null,
};

export const useMaterialsStore = create<MaterialsStore>((
  set,
  get,
) => {

  const privateState: { userId: UserId } = {
    userId: null,
  };

  useAuthStore.subscribe(async (state, prevState) => {
    if (state.user?.id !== prevState.user?.id) {
      await init(state.user?.id);
    }
  });

  async function getSelectedTopic(mode: MaterialsMode) {
    const { materialTopics, knowledgeTopics } = get();

    let selectedTopic = null;

    if (mode === MaterialsMode.MATERIALS) {
      selectedTopic = await selectedMaterialTopicDb.getSelectedMaterialTopic(privateState.userId) || materialTopics[0];
      if (selectedTopic && ![...materialTopics].find(t => t.id === selectedTopic.id)) {
        selectedTopic = materialTopics[0];
      }
    } else {
      selectedTopic = await selectedKnowledgeTopicDb.getSelectedKnowledgeTopic(privateState.userId) || knowledgeTopics[0];
      if (selectedTopic && ![...knowledgeTopics].find(t => t.id === selectedTopic.id)) {
        selectedTopic = knowledgeTopics[0];
      }
    }

    return selectedTopic;
  }


  function getPageCount(materials: IMaterial[], pageSize: number) {
    return Math.floor((materials?.length-1 || 0) / pageSize);
  }

  async function init(userId: UserId) {
    if (!userId)
      return;
    privateState.userId = userId;

    const { mode, pageSize } = get();

    const materialTopics = await ServerApi.materialsService.getMaterialTopicByUserId(userId);
    const knowledgeTopics = await ServerApi.knowledgeService.getKnowledgeTopicAll();

    const searchCriteria = null;
    const selectedTopic = await getSelectedTopic(mode);
    const materials = await requestMaterials(selectedTopic, searchCriteria, userId);
    const pageCount = getPageCount(materials, pageSize);

    set({ searchCriteria, materials, selectedTopic, knowledgeTopics, materialTopics, pageCount });
  }

  async function requestMaterials(
    selectedTopic: ITopic,
    searchCriteria: MaterialSearchParams,
    userId: UserId,
  ): Promise<IMaterial[]> {

    if (!selectedTopic) {
      return;
    }

    if (searchCriteriaIsEmpty(searchCriteria)) {
      if (selectedTopic.type === TopicType.MATERIAL) {
        return await ServerApi.materialsService.getNextMaterialByTopicId(
          selectedTopic.id, null, 10000);
      } else {
        return await ServerApi.knowledgeService.getNextKnowledgeByTopicId(
          selectedTopic.id, null, 10000);
      }
    } else {
      const searchParams = { ...searchCriteria, userId };
      if (selectedTopic.type === TopicType.MATERIAL) {
        return await ServerApi.materialsService.searchNextMaterial(searchParams);
      } else {
        return await ServerApi.knowledgeService.searchNextKnowledge(searchParams);
      }
    }
  }

  return {
    ...initialState,

    async setMode(mode: MaterialsMode) {
      const selectedTopic = await getSelectedTopic(mode);

      set({ mode });
      get().selectTopic(selectedTopic);
      savedMode.save(mode);
    },

    async setPage(page) {
      const { mode, materials, pageSize } = get();

      const start = !page ? 0 : page * pageSize;
      const pageMaterials = materials.slice(start, start + pageSize);
      const pageCount = getPageCount(materials, pageSize);

      if (mode === MaterialsMode.MATERIALS) {
        savedMaterialPage.save(page);
      } else {
        savedKnowledgePage.save(page);
      }

      set({ page, pageMaterials, pageCount });
    },

    async selectTopic(selectedTopic: ITopic) {
      const { mode, searchCriteria, pageSize } = get();

      if (mode === MaterialsMode.MATERIALS) {
        await selectedMaterialTopicDb.saveSelectedMaterialTopic(privateState.userId, selectedTopic);
      } else {
        await selectedKnowledgeTopicDb.saveSelectedKnowledgeTopic(privateState.userId, selectedTopic);
      }

      const materials = await requestMaterials(selectedTopic, searchCriteria, privateState.userId);
      const pageMaterials = materials.slice(0, pageSize);
      const pageCount = getPageCount(materials, pageSize);
      selectedTopic.itemsCount = materials.length;

      set({ selectedTopic, materials, pageMaterials, pageCount, page: 0 });
    },

    async putTopic(topic: ITopic) {
      const { materialTopics } = get();

      let isEdit = topic.id;
      if (isEdit) {
        await ServerApi.materialsService.updateMaterialTopic(topic);
      } else {
        topic.id = await ServerApi.materialsService.createMaterialTopic(topic, privateState.userId);
      }

      set({
        selectedTopic: topic,
        materialTopics: isEdit ? replaceById(materialTopics, topic) : [...materialTopics, topic],
        materials: [],
      });
    },

    async putMaterial(material: IMaterial) {
      const { selectedTopic, materials, materialTopics, page, pageSize } = get();
      const isEdit = material.id;
      if (isEdit) {
        await ServerApi.materialsService.updateMaterialFields(material);
      } else {
        material.topicId = selectedTopic.id;
        material.id = await ServerApi.materialsService.createMaterial(material);
      }

      const start = !page ? 0 : page * pageSize;
      const materialsAdd = isEdit ? replaceById(materials, material) : [...materials, material];
      selectedTopic.itemsCount = materialsAdd.length;
      const pageCount = getPageCount(materialsAdd, pageSize);

      set({
        materialTopics: replaceById(materialTopics, selectedTopic),
        materials: materialsAdd,
        pageMaterials: materialsAdd.slice(start, start + pageSize),
        pageCount: pageCount,
      });

      if (!isEdit) {
        get().setPage(pageCount);
      }
    },

    async deleteTopic(topic: ITopic) {
      const { selectedTopic, materialTopics } = get();
      await ServerApi.materialsService.deleteMaterialTopicById(topic.id);
      const newMaterialTopics = materialTopics.filter(t => t.id !== topic.id);
      set({
        selectedTopic: selectedTopic.id === topic.id ? newMaterialTopics[0] : selectedTopic,
        materialTopics: newMaterialTopics,
      });
    },

    async deleteMaterial(mat: IMaterial) {
      const { selectedTopic, materialTopics, materials, page, pageSize, pageCount } = get();

      const start = !page ? 0 : page * pageSize;
      const materialsDel = materials.filter(m => m.id !== mat.id);
      selectedTopic.itemsCount = materialsDel.length;

      const pageCountAfter = getPageCount(materialsDel, pageSize);

      set({
        materialTopics: replaceById(materialTopics, selectedTopic),
        materials: materialsDel,
        pageMaterials: materialsDel.slice(start, start + pageSize),
        pageCount: pageCountAfter,
      });

      await ServerApi.materialsService.deleteMaterialById(mat.id);

      if (page === pageCount && pageCountAfter < pageCount) {
        get().setPage(pageCountAfter);
      }
    },

  };
});