export const PiecesGroupNames = [
  'amega',
  'animals',
  'birds',
  'zones',
  'chars'
] as const;

export enum PiecesGroupEnums {
  amega = "amega",
  animals = "animals",
  birds = "birds",
  zones = "zones",
  chars = "chars"
}