import React from 'react';
import { LessonDesktop } from '../../../layouts/LessonDesktop';
import { AllChessboardsView } from './views/AllChessboardsView';
import { AllCamerasView } from './views/AllCamerasView';
import { ChessToolsWrapper } from './ChessToolsWrapper';
import { ChessboardWrapper } from './ChessboardWrapper';
import { useLessonUiStore } from '../../../store/lesson-ui/LessonUiStoreImpl';
import { LayoutSlots, LessonTabs, LessonViewMode } from '../../../store/lesson-ui/LessonUiStoreApi';
import { useLessonStore } from '../../../store/lesson/LessonStore';
import { AssignTasksView } from './views/AssignTasksView/AssignTasksView';
import { useTasksStore } from '../../../store/tasks/TasksStore';
import { useConferenceStore } from '../../../store/conference/conferenceStore';
import { LessonTasksTab } from './LessonTasksTab/LessonTasksTab';
import { LessonMovesTab } from './LessonMovesTab/LessonMovesTab';
import { LessonSideBar } from './components/LessonSideBar/LessonSideBar';
import { LessonCamerasTab } from './LessonCamerasTab/LessonCamerasTab';
import { LessonChatTab } from './LessonChatTab/LessonChatTab';
import { LessonSideBarTabProps } from './components';

export function LessonView() {

  const {tabsLayout} = useLessonUiStore();
  const {isOnline, isStudentLayout} = useLessonStore();

  const lessonCamerasTab = LessonCamerasTab();
  const lessonChatTab = LessonChatTab();
  const lessonTasksTab = LessonTasksTab();
  const lessonMovesTab = LessonMovesTab();

  function getTab(tab: LessonTabs) : LessonSideBarTabProps {
    switch (tab) {
      case LessonTabs.CAMS: return isOnline || !isStudentLayout ? lessonCamerasTab: null;
      case LessonTabs.CHAT: return lessonChatTab;
      case LessonTabs.MOVES: return lessonMovesTab;
      case LessonTabs.TASKS: return lessonTasksTab;
    }
  }

  if (useActualView().actualView === LessonViewMode.CAMERAS)
    return <AllCamerasView />;

  let leftTabs = tabsLayout.slots.topLeft.map(getTab);
  let rightTabs = tabsLayout.slots.topRight.map(getTab);

  return <LessonDesktop>
    <LessonSideBar
      slot={LayoutSlots.TOP_LEFT}
      tabs={leftTabs}
      gridArea={'L'}
    />
    <LessonSideBar
      slot={LayoutSlots.TOP_RIGHT}
      tabs={rightTabs}
      gridArea={"R"}
    />
    <ChessToolsWrapper />
    <CenterComponentSelector/>
  </LessonDesktop>;
}

function CenterComponentSelector() {
  const { selected } = useTasksStore();
  const { selectedCameras } = useConferenceStore();
  const { actualView, isOnline, monitoringStudentId } = useActualView();

  let view = actualView;
  if (selected.length || selectedCameras.length)
    view = null;
  if (!isOnline || monitoringStudentId)
    view = LessonViewMode.STANDARD;

  if (view === null)
    return <AssignTasksView />;

  if (view === LessonViewMode.CHESSBOARDS)
    return <AllChessboardsView />;

  if (view === LessonViewMode.STANDARD)
    return <ChessboardWrapper />;

  return null
}


function useActualView() {
  const { view } = useLessonUiStore();
  const { monitoringStudentId, isOnline } = useLessonStore();
  const actualView = monitoringStudentId || !isOnline ? LessonViewMode.STANDARD : view;
  return {actualView, monitoringStudentId, isOnline}
}
