import styled from 'styled-components';
import { Stack } from '../../../../../../../components/LowLevelComponents/Stack';

export const CameraCardContainer = styled(Stack)<{$cameraEnabled: boolean, $stream: boolean}>`
  aspect-ratio: 16/9;
  min-width: 192px;
  
  & .hidden {
    //opacity: 0;
    //transition: 0.3s;
    display: ${p => p.$cameraEnabled && p.$stream ? "none" : "unset"};
  }
  
  &:hover .hidden {
    //opacity: 1;
    display: unset;
  }
`;
