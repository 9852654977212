import React from 'react';
import { GridSize } from '@mui/material';
import { Grid } from '../../../../components/MaterialUI/MaterialUI';
import styled from 'styled-components';
import { AllGridDesktop } from '../../../../layouts/AllGridDesktop';
import { useLessonStore } from '../../../../store/lesson/LessonStore';
import { useUiStore } from '../../../../store/uiStore';
import { ChessboardWithServerLogic } from '../../../../components/Chessboard/ChessboardWithServerLogic';
import { useTaskToDisplay } from '../../../../hooks/useTaskToDisplay';
import { useLessonUiStore } from '../../../../store/lesson-ui/LessonUiStoreImpl';

const StyledChessboardCardsWrapper = styled(Grid)<{ size: number }>`
  margin-top: 6px;

  @media (max-width: 1264px) {
    .chessboard {
      order: ${({ size }) => (size === 4 ? 2 : size === 3 ? 2 : size)};
    }
  }

  @media (max-width: 959px) {
    .chessboard {
      ${({ size }) => (size === 3 || size === 2) && `order: 1;`}
    }
  }

  @media (max-width: 599px) {
    .chessboard {
      order: 1;
    }
  }

  @media (min-width: 1265px) {
    .chessboard {
      order: ${({ size }) => size};
    }
    .MuiGrid-item {
      flex-basis: unset !important;
      max-width: unset !important;
      ${({ size }) =>
        size === 1 ? 'width: 100% !important' : `width: calc((100% - ${(size - 1) * 20}px) / ${size}) !important`};
    }
  }
`;

const ChessboardWrapper = styled(Grid)`
  & div.under-chessboard-panel {
    opacity: 0;
    pointer-events: none;
  }
  &:hover div.under-chessboard-panel {
    opacity: 1;
    pointer-events: unset;
  }
`;

export function AllChessboardsView() {

  const { users, teacherId } = useLessonStore();
  const { sizes } = useUiStore();
  const { view } = useLessonUiStore();
  const size = sizes[view];

  const gridItemProps: { [s: string]: GridSize } = {
    xs: 12,
    sm: size === 1 ? 12 : size !== 4 ? 12 : 6,
    md: size === 1 ? 12 : size !== 4 ? 6 : 6,
    lg: size === 1 ? 12 : size !== 4 ? 4 : 3,
    xl: size === 1 ? 12 : size !== 4 ? 3 : 2,
  };

  const {taskToDisplay} = useTaskToDisplay();

  return <AllGridDesktop>
    <StyledChessboardCardsWrapper size={size} container spacing={2}>
      <ChessboardWrapper{...gridItemProps} style={{ order: 0 }} item>
        <ChessboardWithServerLogic boardId={taskToDisplay?.id} taskType={taskToDisplay?.type} multiBoardMode />
      </ChessboardWrapper>
      {users.filter(u => u.id !== teacherId).filter(u => u.socketId).map((u, i) => (
          <ChessboardWrapper {...gridItemProps} style={{ order: i + 1 }} item key={i}>
              <ChessboardWithServerLogic
                boardId={u.activeQuestionId}
                taskType={taskToDisplay?.type}
                multiBoardMode
              />
          </ChessboardWrapper>
        ))}
    </StyledChessboardCardsWrapper>
  </AllGridDesktop>;
}

