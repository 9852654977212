import React, { useEffect, useState } from 'react';
import { Captcha } from './Captcha';
import { ButtonWrapper, StyledButton, StyledText } from '../../pages/RegisterPage/RegisterSteps';
import { Box, Grid } from '../MaterialUI/MaterialUI';
import { TextLink } from '../PageStyledComponents/TextLink';
import { RegisterStep } from '../../store/auth/AuthStoreApi';
import { useTheme } from 'styled-components';
import { ServerApi } from '../../transport/ServerApi';
import { useLocalization } from '../../l10n/UseLocalization';
import { L10n } from '../../l10n/L10n';

type CaptchaPhaseProps = {
  email: string;
  confirmButtonText?: string;
  setPhase?(phase: RegisterStep): void;
  onSuccess?: () => void;
};

export const CaptchaStep = (props: CaptchaPhaseProps) => {
  const { onSuccess, setPhase, email } = props;
  const [answer, setAnswer] = useState<string | undefined>();
  const [captchaImage, setCaptchaImage] = useState<string | undefined>();
  const [rawCaptcha, setRawCaptcha] = useState<string>();
  const [error, setError] = useState('');
  const { captcha, auth } = useLocalization();
  const styledTheme = useTheme();
  const [inProgress, setInProgress] = useState<boolean>(false);

  useEffect(() => {
    setCaptchaImage(styleSvg(rawCaptcha, captcha, styledTheme.currentTheme.textMain));
  }, [captcha, rawCaptcha, styledTheme])

  useEffect(() => {
    async function checkCaptcha() {
      const response = await ServerApi.captchaService.emailIsExpired(email);
      if (response) {
        const response = await ServerApi.captchaService.getCaptchaByEmail(email);
        setRawCaptcha(response.svg)

      } else {
        onSuccess();
      }
    }

    checkCaptcha();
  }, []);

  async function submitCaptcha() {
      setError('');
      const result = await ServerApi.captchaService.checkAnswerByEmail(email, answer);
      if (!result) {
          setPhase && setPhase(RegisterStep.COMPLETE);
          onSuccess && onSuccess();
      } else {

          setError(result);
          setAnswer(undefined);
          const response = await ServerApi.captchaService.getCaptchaByEmail(email);
          setRawCaptcha(response.svg);
      }
  }

  return (
    <>
      <StyledText>
        {captcha.task}
      </StyledText>
      <div
        style={{
          visibility: error.length ? 'visible' : 'hidden',
          alignSelf: 'flex-start',
          margin: '0 auto',
          color: 'var(--color-error1)',
          font: 'var(--font-normal)',
          textAlign: 'center',
          height: 18,
        }}
      >
          {captcha.errors[error]}&nbsp;
      </div>
      <Captcha
        cellColor1={'#fff'}
        cellColor2={'#aaa'}
        image={captchaImage}

        onChange={setAnswer}
      />

      <Box width={'100%'} marginTop={'1.75rem'}>
        <ButtonWrapper>
          <Box width='100%' maxWidth={'512px'} display={'flex'} flexDirection={'row'} alignItems={'center'}>
            <StyledButton
              fullWidth
              onClick={async () =>{
                setInProgress(true)
                await submitCaptcha()
                setInProgress(false)
              }}
               disabled={!answer || inProgress}>
              {props.confirmButtonText || auth.sign_up}
            </StyledButton>
          </Box>
        </ButtonWrapper>
        <Grid container justifyContent='center' direction='column'>
          <TextLink linkText={auth.login} to={'/auth/login/'} />
        </Grid>
      </Box>
    </>
  );
};


function styleSvg(svg: string, captcha: L10n["captcha"], textMainColor: string) {
  const parser = new DOMParser();
  const serializer = new XMLSerializer();
  const doc = parser.parseFromString(svg, 'image/svg+xml');
  const textElement = doc.querySelector('text');

  if (textElement) {
    const originalText = textElement.textContent;
    textElement.textContent = captcha.questions[originalText] || originalText;
    textElement.setAttribute('fill', textMainColor);
    textElement.setAttribute('font-size', '14');
    textElement.setAttribute('font-weight', '400');
  }

  return serializer.serializeToString(doc);
}