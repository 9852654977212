import React from 'react';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { ContentContainer } from '../../components/ContentContainer';
import styled from 'styled-components';
import { AdminDashboardCard } from './AdminDashboardCard';

import student_hat from '../../svg/icons/student-hat.svg'
import human from '../../svg/icons/human.svg'
import demo_outline from '../../svg/icons/demo-outline.svg'
import settings from '../../svg/icons/settings.svg'

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 300px;
  grid-template-rows: 1fr ;
  height: 100%;
  gap: 40px;
  padding: 30px 0 0 0;
`;

export function AdminDashboard() {
  return <PageTemplate>
    <ContentContainer
      title={"Панель администратора"}
      toolbar={<div style={{height:42 }}/>}>
      <Container >
       <div/>

        <div style={{display: 'grid', gap: 10, gridTemplateRows: "repeat(4, 1fr)"}}>
          <AdminDashboardCard
            icon={student_hat}
            text={"База знанний"}
            to={"/admin/knowledge"}
          />
          <AdminDashboardCard
            icon={human}
            text={"Пользователи"}
            to={"/admin/users"}
          />
          <AdminDashboardCard
            icon={demo_outline}
            text={"Комнаты онлайн"}
            to={"/admin/rooms"}
          />
          <AdminDashboardCard
            icon={settings}
            text={"Журнал"}
            to={"/admin/logs"}
          />
        </div>
      </Container>
    </ContentContainer>
  </PageTemplate>;
}

