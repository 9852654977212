import React, { SyntheticEvent, useRef } from 'react';

import { IQuestion } from '../../../../../interfaces/question';
import { TextEllipsis } from '../../../../../components/LowLevelComponents/EllipsedText';
import { ChessboardSvg } from '../../../../../components/Chessboard/ChessboardSvg';
import { useLessonStore } from '../../../../../store/lesson/LessonStore';
import { useTasksStore } from '../../../../../store/tasks/TasksStore';
import { emitCentrifugeEvent } from '@chessclub/realtime_infrastructure';
import { makeRoomChannelKey, RoomChannel } from '../../../../../transport/messaging/RoomChannel';
import { useAuthStore } from '../../../../../store/auth/AuthStore';
import { KnownIcons, getIconByTaskType, getIconByTaskStatus } from '../../../../../components/Icon/KnownIcons';
import { SvgImage } from '../../../../../components/Icon/SvgImage';

import {
  StyledQuestionBg,
  StyledQuestionDescription,
  StyledQuestionDifficulty,
  StyledQuestionIcons,
  StyledQuestionInfo,
  StyledQuestionRow,
  StyledQuestionTitle, StyledStatusIcons,
} from './QuestionCardStyledComponents';
import { useTheme } from 'styled-components';
import { useUiStore } from '../../../../../store/uiStore';
import { useEditingTaskStore } from '../../../../../store/editing-task/EditingTaskStore';
import { DndType, useDndStore } from '../../../../../store/dndStore';
import { ConfirmationPopupMenu } from '../../../../../components/PopupMenu/ConfirmationPopupMenu';
import { ConfirmDeleteTaskButton } from './ConfirmDeleteTaskButton';
import { useLocalization } from '../../../../../l10n/UseLocalization';
import { L10n } from '../../../../../l10n/L10n';
import { TaskStatus, TaskType } from '@chessclub/grpc_wrapper';

function getTurnCount(fen: string) {
  const count = +fen.split(' ').pop() || 0;
  if (count === 1)
    return '1 Ход';
  if (count > 1 && count < 5)
    return count + ' Хода';
  return count + ' Ходов';
}

function getSide(fen: string, lesson: L10n['lesson']) {
  return fen.split(" ")[1] === "w" ? lesson.white : lesson.black
}

export function QuestionCard(props: IQuestion&{onClick?: any}) {
  let {
    id,
    title,
    fen,
    isActive,
    tools,
    rank,
    type,
    customData,
    userId,
  } = props;

  const { lesson } = useLocalization();

  const chessboardRef = useRef<HTMLDivElement>();
  const { startDrag } = useDndStore();
  let { interfaceClr } = useUiStore();
  const { currentTheme } = useTheme();
  const {
    tasks,
    updateSelection,
    setActiveTask,
    selected,
    updateTaskStatus,
    runtimeTasksStates,
    resetTaskRuntimeState,
  } = useTasksStore();
  const { startEditTask } = useEditingTaskStore();
  const {
    roomId,
    isOnline,
    demonstrationState,
    setCurrentMonitoringQuestionItem,
    monitoringStudentId,
    monitoringStudentDisplayingQuestionId,
    setActiveStudentQuestion,
    isStudentLayout,
    monitoringStudentSelectedQuestionId,
  } = useLessonStore();

  const { user } = useAuthStore();

  function questionRowClickHandler(e) {
    if (!isStudentLayout && (e.ctrlKey || e.metaKey)) {
      updateSelection(id);
    } else if (monitoringStudentId) {
      setCurrentMonitoringQuestionItem(id);
    } else {
      setActiveTask(id);
      isStudentLayout && emitCentrifugeEvent(
        makeRoomChannelKey(roomId),
        RoomChannel.STUDENT_BOARD_CHANGED,
        { questionId: id, userId: user.id },
      );
    }
  }


  function edit(e) {
    e.preventDefault();
    e.stopPropagation();
    startEditTask(tasks.find(t => t.id === id));
  }

  const assignAsActive = (e: SyntheticEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setActiveStudentQuestion(id);
  };

  const completeTask = () => updateTaskStatus(
    id,
    isStudentLayout ? TaskStatus.REVIEW : TaskStatus.RESOLVED,
    userId
  );
  const notCompleteTask = () => updateTaskStatus(
    id,
    isStudentLayout ? TaskStatus.WORK : TaskStatus.ERROR,
    userId
  );

  const demoOn = !!demonstrationState;
  const active = monitoringStudentId ? monitoringStudentDisplayingQuestionId === id : isActive;

  const questionProps = {} as any;

  if (!isStudentLayout) {
    questionProps.onDragStart = ev => {
      startDrag({ type: DndType.TASK, data: id });
      const c = chessboardRef.current.getBoundingClientRect().width / 2;
      ev.dataTransfer.setDragImage(chessboardRef.current, c, c);
    };
    questionProps.draggable = true;
  }

  let currentStudentQuestion = monitoringStudentSelectedQuestionId === id;

  let draftCopy = null;//isOnline ? null : runtimeTasksStates[id];

  let borderColor = demoOn && demonstrationState.boardId === id ? currentTheme[interfaceClr]
    : selected.includes(id) ? currentTheme.error1 : null;

  let actualFen = draftCopy ? draftCopy.fen : fen;
  let actualMarkers = draftCopy ? draftCopy.tools : tools;

  return <StyledQuestionRow
    {...questionProps}
    onClick={props.onClick || questionRowClickHandler}
    active={active}
    border={borderColor}
  >
    <StyledQuestionInfo style={{ paddingBottom: '0.3rem' }}>
      <StyledQuestionTitle active={active}>
        <SvgImage size={32} icon={getIconByTaskType(type)} />
        <TextEllipsis text={title} lineClamp={2} />
      </StyledQuestionTitle>
      <StyledQuestionDescription>
        <StyledQuestionDifficulty>{rank}</StyledQuestionDifficulty>
        <span>{getTurnCount(actualFen)}, {getSide(actualFen,lesson)}</span>
      </StyledQuestionDescription>
      <StyledQuestionIcons className='icons'>
        {demonstrationState?.boardId !== id &&<ConfirmDeleteTaskButton taskId={id}/>}
        {!isStudentLayout && <SvgImage icon={KnownIcons.pencil} onClick={edit} withHover />}
        {/*{!isStudentLayout && draftCopy && <ConfirmationPopupMenu*/}
        {/*  text={lesson.reset + "?"}*/}
        {/*  action={() => resetTaskRuntimeState(id)}*/}
        {/*>*/}
        {/*  <SvgImage icon={KnownIcons.reverse} withHover />*/}
        {/*</ConfirmationPopupMenu>}*/}

        {monitoringStudentId && !currentStudentQuestion &&
          <SvgImage icon={KnownIcons.checkmark} onClick={assignAsActive} withHover />}
        {(monitoringStudentId && customData?.status !== TaskStatus.RESOLVED) &&
          <SvgImage tooltip={lesson.save}
                    icon={KnownIcons.queen_outline}
                    withHover
                    onClick={completeTask}/>}
        {(monitoringStudentId && customData?.status !== TaskStatus.ERROR) &&
          <SvgImage tooltip={lesson.save}
                    icon={KnownIcons.error_outline}
                    withHover
                    onClick={notCompleteTask}/>}
      </StyledQuestionIcons>
    </StyledQuestionInfo>
    <StyledQuestionBg style={{ width: '226px', pointerEvents: 'none' }} active={active} ref={chessboardRef}>
      <ChessboardSvg fen={actualFen} altColor={!!draftCopy} markers={actualMarkers&&actualMarkers[-1]} />
    </StyledQuestionBg>
    <StyledStatusIcons>
      {currentStudentQuestion && <SvgImage icon={KnownIcons.checkmark_filled} size={32} filled />}
      {customData?.status && <SvgImage icon={getIconByTaskStatus(customData?.status, isStudentLayout)} size={32} filled />}
      {demonstrationState?.boardId === id && <SvgImage icon={KnownIcons.demo_filled} size={32} filled />}
    </StyledStatusIcons>
  </StyledQuestionRow>;
}
