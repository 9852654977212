import React from 'react';
import styled from 'styled-components';
import { SvgImage } from '../Icon/SvgImage';

import { many, max, min } from '@chessclub/web-game-server/src/shared/util/math';
import { KnownIcons } from '../Icon/KnownIcons';

const PaginationBody = styled.div`
  display: flex;
`;

const Btn = styled.div<{active?:boolean}>`
  color: var(${p => p.active ? "--color-accent" : "--color-icon-main"});
  pointer-events: ${p => p.active ? "none" : "unset"};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  font: var(--font-normal);
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--color-text-main);
  }
`;

export function Pagination(props: {
  page: number,
  pageCount: number,
  onSelect: (x: number) => void
}) {

  return <PaginationBody>

    <SvgImage
      icon={KnownIcons.fast_forward} rotate={180} withHover
      onClick={() => props.onSelect(max(0, props.page - 5))}
      disabled={props.page === 0}
    />
    <SvgImage
      icon={KnownIcons.arrow_right} rotate={180} withHover
      onClick={() => props.onSelect( props.page - 1)}
      disabled={props.page === 0}
    />

    {many(10, i => i - 4 + props.page)
      .filter(i => i >= 0 && i <= props.pageCount)
      .map(i => <Btn active={i === props.page} key={i} onClick={() => props.onSelect(i)}>{i+1}</Btn>)}

    <SvgImage
      icon={KnownIcons.arrow_right} withHover
      onClick={() => props.onSelect(props.page + 1)}
      disabled={props.page === props.pageCount || props.pageCount === -1}
    />
    <SvgImage
      icon={KnownIcons.fast_forward} withHover
      onClick={() => props.onSelect(min(props.pageCount, props.page + 5))}
      disabled={props.page === props.pageCount || props.pageCount === -1}
    />
  </PaginationBody>;
}