import { PiecesGroupEnums } from './ExtraPiesesGroupNames';
import { KnownIcons } from '../../Icon/KnownIcons';

export const ExtraPiecesNavigationIcons = {
  [PiecesGroupEnums.amega]: KnownIcons.omega,
  [PiecesGroupEnums.birds]: KnownIcons.bird,
  [PiecesGroupEnums.zones]: KnownIcons.zone,
  [PiecesGroupEnums.chars]: KnownIcons.character,
  [PiecesGroupEnums.animals]: KnownIcons.cat,
}

