import styled from 'styled-components';
import MenuList from '@mui/material/MenuList';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';

interface IMenuItem {
  disabled?: boolean;
  checked?: boolean;
  postfix?: string;
  leftpad?: boolean;
  compact?: boolean;
  menuitemcolorkey?: string;
  currentitem?: boolean;
  nohover?: boolean;
}

const StyledColorTitle = styled.div<{ topPadding?: boolean }>`
  color: ${({ theme }) => theme.currentTheme.iconMain};
  font-weight: bold;
  font: ${({ theme }) => theme.fonts.small};
  padding: ${({ topPadding }) => (topPadding ? '25px 0 10px 0' : '10px 0')};
`;

const StyledMenu = styled(MenuList)`
  padding: 0;
`;

const StyledMenuItem = styled(MenuItem).attrs({
  disableRipple: true,
})<IMenuItem>`
  padding: 5px 0;
  padding-left: ${({ leftpad }) => (leftpad ? '8px' : '0px')} !important;
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  background: ${({ theme, currentitem }) => (currentitem ? theme.currentTheme.hover : 'none')} !important;
  position: relative;
  border-radius: 8px !important;
  margin-top: 0.1rem !important;

  ${({ compact }) => !compact && `min-width: 160px;`}
  ${({ compact }) => compact && `padding-right: 8px !important;`}
  &:first-child {
    margin-top: 0 !important;
    margin-bottom: 0.1rem !important;
  }

  font: ${({ theme }) => theme.fonts.small} !important;
  color: ${({ theme, currentitem }) => theme.currentTheme[currentitem ? 'textMain' : 'iconMain']} !important;

  & > span:last-child {
    margin-left: 8px;
  }

  ${({ theme, nohover, disabled }) =>
          !nohover &&
          `
      &:hover {
        background-color: ${disabled ? theme.currentTheme.background : theme.currentTheme.hover} !important;
      }
  `}

  ${({ checked }) =>
          checked
                  ? `
    &::after {
      position: absolute;
      right: 0px;
      top: 0px;
      content: '';
      width: 30px;
      height: 30px;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='32' height='32' viewBox='0 0 32 32' fill='none'%3E%3Cpath d='M11 15L15 19L21 13' stroke='%23868686' stroke-width='3'/%3E%3C/svg%3E");
      margin-top: 5px;
    }
    padding-right: 60px !important;
  `
                  : null}

  ${({ theme, postfix }) =>
          postfix
                  ? `
    &::after {
        position: absolute;
        right: 0px;
        top: 6px;
        content: "${postfix}";
        width: 32px;
        height: 32px;
        font: ${theme.fonts.small};
        color: ${theme.currentTheme.iconMain} !important;
        display: flex;
        align-items: center;
    }
    padding-right: 60px !important;
    `
                  : null}
`;

const StyledDivider = styled.hr`
  width: 100%;
  padding: 0;
  margin: 5px 0;
  border-color: transparent;
  background-color: ${({ theme }) => theme.currentTheme.iconLight} !important;
  border-bottom: 0;
  opacity: 0.5;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
`;

const StyledIcon = styled.div<{ active?: boolean }>`
  > svg {
    width: 32px;
    height: 32px;
    background-color: ${({ active, theme }) => (active ? theme.currentTheme.iconMain : '')};
  }
`;

const MenuText = styled.span<{ active?: boolean }>``;

export const DropdownMenuStyledComponents = {
  MenuText, StyledIcon, StyledLink, StyledDivider,
  StyledMenuItem, StyledMenu, StyledColorTitle,
};