import { ITask, TaskType } from '@chessclub/grpc_wrapper';
import { useLessonUiStore } from '../../../store/lesson-ui/LessonUiStoreImpl';
import { useTasksStore } from '../../../store/tasks/TasksStore';
import React, { useEffect, useState } from 'react';
import { GameApi } from '@chessclub/web-game-server/src/client/hooks/useCentrifugeChessboard';
import { Resizer } from '../../../components/Resizer/Resizer';
import { ChessboardWithLocalLogic } from '../../../components/Chessboard/ChessboardWithLocalLogic';
import { UnderChessboardPanel } from '../../../components/Chessboard/UnderChessboardPanel';
import { GameState } from '@chessclub/web-game-server';
import { useRoomUserId } from '../../../hooks/useRoomUserId';
import { useLessonStore } from '../../../store/lesson/LessonStore';

export function LocalLessonChessboardWithControls(props: { task: ITask }) {

  const userId = useRoomUserId()
  const { boardRotations, rotateBoard } = useLessonUiStore();
  const {isStudentLayout} = useLessonStore();
  const { setCurrentTaskGameApi, currentTaskForceUpdate, runtimeTasksStates,
    currentTaskGameApi, currentTaskGameState: s, updateServerTaskState } = useTasksStore();
  const [gameApi, setGameApi] = useState<GameApi>();
  const taskToDisplay = props.task;//runtimeTasksStates[props.task.id] || props.task;

  useEffect(() => {
    gameApi && gameApi.requestUpdate()
  }, [currentTaskForceUpdate, gameApi])

  useEffect(() => {
    setCurrentTaskGameApi(gameApi);
  }, [gameApi])

  return <>
    <div>{s?.result}</div>
    <Resizer>
      <ChessboardWithLocalLogic
        task={{...taskToDisplay, taskId: taskToDisplay.id}}
        onGameApiChanged={setGameApi}
        onGameStateChanged={(gs: GameState) => updateServerTaskState(taskToDisplay.id, gs)}
        reversed={boardRotations[taskToDisplay.id]}
      />
    </Resizer>
    <UnderChessboardPanel
      //navigation
      isNavigationVisible={taskToDisplay?.type !== TaskType.GAME}

      isStartPosition={taskToDisplay?.moveIndex === -1}
      toStartPosition={() => {
        currentTaskGameApi.setStepIndex(-1);
        currentTaskGameApi.requestUpdate()
      }}

      hasPreviousStep={!!taskToDisplay?.moves?.length && taskToDisplay?.moveIndex !== -1}
      previousStep={() => {
        currentTaskGameApi.setStepIndex(taskToDisplay?.moveIndex-1)
        currentTaskGameApi.requestUpdate()
      }}

      hasNextStep={!!taskToDisplay?.moves?.length && taskToDisplay?.moveIndex !== taskToDisplay?.moves?.length-1}
      nextStep={() => {
        currentTaskGameApi.setStepIndex(taskToDisplay?.moveIndex+1)
        currentTaskGameApi.requestUpdate()
      }}

      isLastPosition={!taskToDisplay?.moves?.length || taskToDisplay?.moveIndex === taskToDisplay?.moves?.length-1}
      toLastPosition={() => {
        currentTaskGameApi.setStepIndex(taskToDisplay?.moves?.length-1)
        currentTaskGameApi.requestUpdate()
      }}

      // tools
      hasMarkers={!!(taskToDisplay?.tools && taskToDisplay?.tools[taskToDisplay?.moveIndex]?.length)}
      clearMarkers={() => gameApi?.clearMarkers({ userId })}

      reverse={() => rotateBoard(taskToDisplay?.id)}
      isReversed={boardRotations[taskToDisplay.id]}

      isRulesOnVisible={!isStudentLayout}
      isRulesOn={s?.applyRules}
      toggleRules={() => gameApi?.setApplyRules(!s?.applyRules, true)}

      isLegalMovesVisible={!isStudentLayout}
      isLegalMovesOn={s?.legalMoves}
      toggleLegalMoves={() => gameApi?.setLegalMoves(!s?.legalMoves, true)}

      isGameRunningVisible={taskToDisplay?.type === TaskType.GAME}
      isGameRunning={s?.isPlaying}
      toggleGameRunning={() => gameApi?.setPlayStop(!s?.isPlaying)}

      isTimerVisible={taskToDisplay?.type === TaskType.ENGINE}
      isTimerOn={s?.isTimerOn}
      toggleTimer={() => gameApi?.setUseTimer(!s?.isTimerOn, true)}
    />
  </>;
}