import React  from 'react';
import { ContentContainer } from '../../components/ContentContainer';
import { PageTemplate } from '../../components/PageTemplate/PageTemplate';
import { KnownIcons, randomIcon } from '../../components/Icon/KnownIcons';
import {  StyledInputWrapper} from '../../components/Modal/common';
import Input from '../../components/LowLevelComponents/Input';
import { SvgImage } from '../../components/Icon/SvgImage';
import styled from 'styled-components';
import { UsersList } from './UsersList/UsersList';
import { useEditingRoomStore } from '../../store/editing-room/EditingRoomStore';
import { StudentViewSettings } from './StudentViewSettings';
import { RoomEditToolbarVariant } from './RoomEditToolbarVariant';
import { EditViewHeader } from '../EditViewHeader';
import { IconSelectWidget } from '../../components/IconSelectWidget/IconSelectWidget';
import { useLocalization } from '../../l10n/UseLocalization';

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 600px 50px 450px 1fr;
`

export function RoomEditView() {
  const { updateEditingRoom, editingRoom} = useEditingRoomStore();

  const { rooms } = useLocalization();

  const link = `${document.location.origin}/lesson/${editingRoom.id}`;

  return <PageTemplate>
    <ContentContainer title={!editingRoom.id ? rooms.new_room : rooms.edit_room} toolbar={<RoomEditToolbarVariant/>}>

      <EditViewHeader text={!editingRoom.id ? rooms.new_room : rooms.edit_room}>
        <RoomEditToolbarVariant/>
      </EditViewHeader>
      <Wrapper>

        <div/>

        <div>
          <StyledInputWrapper>
            <IconSelectWidget
              icon={editingRoom.customData?.icon as any || randomIcon()}
              onChange={icon => updateEditingRoom({customData: {icon}})}
            />
            <Input
              label={rooms.name}
              fullWidth
              value={editingRoom.name || ''}
              onChange={(e) => updateEditingRoom({ name: e.target.value })}
            />
          </StyledInputWrapper>

          <StyledInputWrapper>
            <Input
              label={rooms.description}
              fullWidth
              rows={4}
              value={editingRoom.description || ''}
              onChange={(e) => updateEditingRoom({ description: e.target.value })}
            />
          </StyledInputWrapper>

          {/*<StyledDivider />*/}
          {/* Room Link  */}
          {editingRoom.id && (
            <StyledInputWrapper>
              <Input
                label={rooms.link}
                value={link}
                fullWidth
                disabled

                endAdornment={<SvgImage
                  style={{margin: 5}}
                  withHover
                  icon={KnownIcons.copy}
                  onClick={() => navigator.clipboard.writeText(link)}
                />}
              />
              {/*<IconButton>*/}

              {/*</IconButton>*/}
            </StyledInputWrapper>
          )}
          {/* Password Input */}
          <StyledInputWrapper>
            <Input
              autoComplete={'off'}
              label={rooms.password_optional}
              value={editingRoom.password || ''}
              onChange={(e) => updateEditingRoom({password:  e.target.value})}
              fullWidth
            />
          </StyledInputWrapper>
          <StudentViewSettings settings={editingRoom.settings}/>
        </div>

        <div/>

        <UsersList users={editingRoom.studentsProfiles} ownerId={editingRoom.userId}/>

      </Wrapper>


    </ContentContainer>
  </PageTemplate>;

}
