import {
  IconButton as MaterialUiIconButton,
  TextField  as MaterialUiTextField,
  Table as MaterialUiTable,
  TableBody as MaterialUiTableBody,
  TableCell as MaterialUiTableCell,
  TableRow as MaterialUiTableRow,
  InputAdornment as MaterialUiInputAdornment,
  Popper as MaterialUiPopper,
  ClickAwayListener as MaterialUiClickAwayListener,
  Container as MaterialUiContainer,
  Box as MaterialUiBox,
  GridSize as MaterialUiGridSize,
  Grid as MaterialUiGrid,
  useMediaQuery as MaterialUiUseMediaQuery
} from '@mui/material'

export const IconButton = MaterialUiIconButton;
export const TextField = MaterialUiTextField;
export const Table = MaterialUiTable;
export const TableBody = MaterialUiTableBody;
export const TableCell = MaterialUiTableCell;
export const TableRow = MaterialUiTableRow;
export const InputAdornment = MaterialUiInputAdornment;
export const Popper = MaterialUiPopper;
export const ClickAwayListener = MaterialUiClickAwayListener;
export const Container = MaterialUiContainer;
export const Box = MaterialUiBox;
export const Grid = MaterialUiGrid;
export const useMediaQuery = MaterialUiUseMediaQuery;