import { Grid, InputAdornment } from '../../components/MaterialUI/MaterialUI';
import Input from '../../components/LowLevelComponents/Input';
import React, { useEffect, useState } from 'react';
import PasswordInput from '../../components/LowLevelComponents/PasswordInput';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import { useLocalization } from '../../l10n/UseLocalization';
import { ButtonWrapper, InputWrapper, StyledButton, StyledMessage } from '../RegisterPage/RegisterSteps';
import { testNameTextFields } from '../../store/auth/testUsername';
import { IAuthFormErrors } from '../../interfaces/auth';
import { useTheme } from 'styled-components';
import { useAuthStore } from '../../store/auth/AuthStore';
import { useStoredState } from '../../hooks/useStoredState';



export function EnterLessonInitialPhase( {passwordInit}: {passwordInit}) {

  const { auth } = useLocalization();
  const [studentName, setStudentName] = useStoredState<string | undefined>('studentName', '');
  const [password, setPassword] = useState<string>("");
  const initialErrors: IAuthFormErrors = { username: false, password: false };
  const { authError, lessonAuth } = useAuthStore();
  const [errors, setErrors] = useState(initialErrors);
  const [error, setError] = useState('');
  const styledTheme = useTheme();

  const [isButtonActionDisabled, setButtonActionDisabled] = useState<boolean>(true);
  const [inProgress, setInProgress] = useState<boolean>(false);

  useEffect(() => {
    let errors: IAuthFormErrors = {};
    setError("");

    let notUsernameMatch = studentName && !testNameTextFields(studentName);
    if (notUsernameMatch) {
      errors.username = true;
      setError("invalid_characters");
    }

    setButtonActionDisabled( passwordInit ?
      !studentName || notUsernameMatch || !password:
      !studentName || notUsernameMatch)

    setErrors(errors);
  }, [password, studentName])

  useEffect(() => {
    let errors: IAuthFormErrors = {};

    if(authError?.text) {
      errors.username = true;
      errors.password = true;
    }
    setError(authError?.text);
    setErrors(errors);

    setButtonActionDisabled(true)
  }, [authError?.text])


  async function externalRegistration() {
    let errors: IAuthFormErrors = {};

    let notPasswordMatch = password && passwordInit && (password !== passwordInit);
    if (notPasswordMatch) {
      errors.password = true;
      setError("password_error");
    }

    if (!notPasswordMatch) {
      lessonAuth(studentName);
    }

    setErrors(errors);
  }

  return <>
    <Grid container justifyContent='center' direction='column'>
      <StyledMessage>
        {auth.enter_session_text}
      </StyledMessage>
      <InputWrapper>
        <div
          style={{
            visibility: errors.username || errors.password ? 'visible' : 'hidden',
            alignSelf: 'flex-start',
            marginLeft: '1rem',
            color: styledTheme.currentTheme['error1'],
            font: styledTheme.fonts.normal,
            paddingBottom:5,
          }}
        >
          {auth[error] || auth.errors[error]}&nbsp;
        </div>
        <Input
          label={auth.username}
          fullWidth
          value={studentName}
          onChange={(e) => {setStudentName(e.target.value)}}
          error={errors.username}
          endAdornment={studentName && (
            <InputAdornment
              position='start'
              onClick={() => setStudentName('')}
            >
              <SvgImage style={{ marginRight: '.5rem' }} icon={KnownIcons.cross} withHover />
            </InputAdornment>
          )}
        />
      </InputWrapper>
      {passwordInit && (
        <InputWrapper>
          <PasswordInput
            label={auth.password_to_enter}
            fullWidth
            value={password}
            onChange={(e) => {setPassword(e.target.value)}}
            error={errors.password}
          />
        </InputWrapper>
      )}
      <ButtonWrapper>
        <StyledButton
          fullWidth
          disabled={isButtonActionDisabled || inProgress}
          onClick={async () => {
            setInProgress(true)
            await externalRegistration()
            setInProgress(false)
          }}>
          {auth.with_out_registration}
        </StyledButton>
      </ButtonWrapper>
    </Grid>
  </>;
}