import React, { useRef } from 'react';
import { HidingScrollbar } from '../../../../../components/LowLevelComponents/HidingScrollbar';
import { useLessonStore } from '../../../../../store/lesson/LessonStore';
import { LocalCameraCard } from './CameraCard/variants/LocalCameraCard';
import { RemoteCameraCard } from './CameraCard/variants/RemoteCameraCard';
import { localStorageKeys } from '../../../../../helpers/localStorageObject';


export function VideoRoom() {
  const { teacherId, users: usersRoom, currentUser, isStudentLayout, isOnline } = useLessonStore();

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const filteredUsers = usersRoom
    .filter(u => isOnline ? u.socketId : true)
    .filter(u => u.id !== teacherId )
    .filter(u => u.id !== currentUser?.id);

  return <HidingScrollbar
    elementRef={scrollRef}
    localStorageParamName={localStorageKeys.scrollbarVideoRoom_key}
    style={{
      overflowX: 'hidden',
      overflowY: 'scroll',
      border: 'none',
      height: '100%',
    }}
  >
    {isStudentLayout && <LocalCameraCard key={-1}/>}
    {filteredUsers.map((user, i) => <div key={i} style={{ marginTop: 3, marginRight: 1 }}>
      <RemoteCameraCard user={user} />
    </div>)}
  </HidingScrollbar>;
}
