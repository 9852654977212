import { L10n } from './L10n';

export const Lang_cn : L10n = {
  'landing': {
    'mainTitle': '培训<br/>在新水平',
    'mainSubTitle': '<br/>远程 国际象棋培训平台',
    'aboutUs': '关于平台',
    'aboutUsText': '<b>ChessClub Training 是一个为那些认为国际象棋不仅仅是一场游戏的人而设的平台！</b><br/><br/>我们使学习过程尽可能<br/>舒适、高效和有趣，<br/>无论是对教师还是学生',
    'ourCapabilitiesTitle': '我们的能力',
    'ourCapabilitiesElements': [
      ['https://chessclub.spb.ru/landing/img/crown.svg', '进行个人和小组课程'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', '在儿童国际象棋教学中引入新的游戏形式（游戏化）'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', '所有必要的教学材料都集中在一个地方'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', '扩展的教学工具'],
      ['https://chessclub.spb.ru/landing/img/crown.svg', '具有临场感的远程学习'],
    ],
    'ourPlatformTitle': '如果您是以下情况，我们的平台适合您：',
    'ourPlatformElements': [
      ['https://chessclub.spb.ru/landing/img/knight_black.svg', 'https://chessclub.spb.ru/landing/img/knight_white.svg', '在国际象棋教育领域自由职业'],
      ['https://chessclub.spb.ru/landing/img/rook_black.svg', 'https://chessclub.spb.ru/landing/img/rook_white.svg', '国际象棋俱乐部的教师或专业国际象棋教练'],
      ['https://chessclub.spb.ru/landing/img/king_black.svg', 'https://chessclub.spb.ru/landing/img/king_white.svg', '进行国际象棋的大师班、讲座或强化课程'],
      ['https://chessclub.spb.ru/landing/img/queen_black.svg', 'https://chessclub.spb.ru/landing/img/queen_white.svg', '希望您的孩子能够逻辑思考并学习国际象棋'],
      ['https://chessclub.spb.ru/landing/img/pawn_black.svg', 'https://chessclub.spb.ru/landing/img/pawn_white.svg', '希望找到教练并学习国际象棋'],
    ],
    'ourAdvantagesTitle': '我们的优势',
    'ourAdvantagesElements': [
      ['https://chessclub.spb.ru/landing/img/pawns-6.svg', '能够以会议模式进行小组在线课程'],
      ['https://chessclub.spb.ru/landing/img/board.svg', '具有不同任务自定义选项的独特国际象棋棋盘'],
      ['https://chessclub.spb.ru/landing/img/arrows.svg', '带有光标和棋子移动同步的虚拟指示器'],
      ['https://chessclub.spb.ru/landing/img/pawns-3.svg', '适用于不同年龄类别的课程工具'],
      ['https://chessclub.spb.ru/landing/img/ai.svg', '应用人工智能技术'],
      ['https://chessclub.spb.ru/landing/img/pc.svg', '适用于PC、笔记本电脑、智能手机、平板电脑的自适应界面，移动版本'],
      ['https://chessclub.spb.ru/landing/img/two-knights.svg', '进行在线比赛的能力<br/>(开发中)'],
      ['https://chessclub.spb.ru/landing/img/server.svg', '与TimeWeb服务的协同作用（可靠的服务器托管）'],
    ],
    'ourExpertsTitle': '我们的专家',
    'ourExpertsElements': [
      ['https://chessclub.spb.ru/landing/img/vladimir-bykov.png', '<b>弗拉基米尔·比科夫</b><br/><br/>圣彼得堡国际象棋联合会执行董事。“青铜骑士”队队长。全国级国际象棋裁判。'],
      ['https://chessclub.spb.ru/landing/img/ekaterina-sudoplatova.png', '<b>叶卡捷琳娜·苏多普拉托娃</b><br/><br/>女子国际象棋特级大师。国际大师。俄罗斯两届冠军和世界女子冠军，欧洲冠军，俄罗斯奥林匹克队成员，俄罗斯女子冠军，多次圣彼得堡冠军。教练，额外教育教师。'],
      ['https://chessclub.spb.ru/landing/img/alina-balayan.png', '<b>阿丽娜·巴拉扬</b><br/><br/>女子国际大师。俄罗斯国际象棋运动员。女子快速赛俄罗斯冠军，团队冠军，多次圣彼得堡冠军。教练，额外教育教师。'],
      ['https://chessclub.spb.ru/landing/img/valeriy-popov.png', '<b>瓦列里·波波夫</b><br/><br/>特级大师。国际大师。欧洲冠军。高级教练。圣彼得堡州立预算教育机构国际象棋部高级教练。'],
      ['https://chessclub.spb.ru/landing/img/dina-belenkaya.png', '<b>迪娜·贝连卡娅</b><br/><br/>女子国际象棋特级大师。多次俄罗斯冠军团队奖牌获得者，多次圣彼得堡冠军。国际象棋教师。'],
      ['https://chessclub.spb.ru/landing/img/anastasia-bondaruk.png', '<b>阿纳斯塔西娅·邦达鲁克</b><br/><br/>女子国际象棋特级大师。国际大师。世界快速赛冠军，多次俄罗斯冠军，多次圣彼得堡冠军，俄罗斯国家队成员。'],
    ],
    'imagesSlider': [
      ['在线课程', 'https://chessclub.spb.ru/landing/img/online-black.png', 'https://chessclub.spb.ru/landing/img/online-white.png'],
      ['离线课程', 'https://chessclub.spb.ru/landing/img/online-black.png', 'https://chessclub.spb.ru/landing/img/offline-white.png'],
      ['编辑任务', 'https://chessclub.spb.ru/landing/img/online-black.png', 'https://chessclub.spb.ru/landing/img/edit-white.png'],
    ]
  },
  'footer': {
    'help': '帮助',
    'contacts': '联系方式',
    'supportUs': '支持项目',
    'termsOfService': '服务条款',
  },
  'rooms': {
    "rooms": '房间',
    "new_room": "新房间",
    "edit_room": "编辑房间",
    "name": "名称",
    "description": "描述",
    "link": "链接",
    "external_view": "学生房间的外部视图",
    "password_optional": "密码（可选）",
    "trainees": "学员",
    "nothing_found": "未找到任何内容",
    "search_by_site": "按网站搜索",
    "more_characters": " (输入3个或更多字符)",
  },
  'lesson': {
    'newTask': '新问题',
    'chain': '链',
    'table': '表',
    'text': '文本',
    'clear': '清除',
    'room': '房间',
    'defaultRoom': '默认房间',
    'ok': '好的',
    "empty": "未找到任何内容",
    'sizeHuge': '巨大',
    'sizeLarge': '大',
    'sizeStandard': '标准',
    'markersBlue': '蓝色标记',
    'markersGreen': '绿色标记',
    'markersOff': '关闭标记',
    'markersRed': '红色标记',
    'markersDelete': '删除标记',
    'withoutTitles': '无标签',
    'titlesAtCellCorners': '在单元格角落',
    'titlesInEveryCell': '在每个单元格中',
    'titlesAroundChessboard': '在棋盘周围',
    'standardView': '标准',
    'allChessboardsView': '所有棋盘',
    'allCamsView': '所有摄像头',
    'size': '大小',
    'exit': '退出',
    "knowledges": "知识库",
    "materials": "材料",
    "my_materials": "我的材料",
    'rooms': '房间',
    'profile': '个人资料',
    'edit': '编辑',
    'paste': '粘贴',
    'copy': '复制',
    'save': '保存',
    'cancel': '取消',
    'delete': '删除',
    'add': '添加',
    'waitForTrainer': '等待教练加入',
    'chat': '聊天',
    'cams': '摄像头',
    'online': '在线',
    'view': '视图',
    'demonstration': '演示',
    'settings': '设置',
    'offline': '离线',
    'black': '黑色',
    'white': '白色',
    'tasks': '问题',
    'moves': '走法',
    'activeLessons': '活跃课程',
    'activeLesson': '活跃课程',
    'student': '学生',
    'trainer': '教练',
    'lesson': '课程',
    "lessons": "课程",
    "beginning": "开始",
    "name": "名称",
    "task": "带答案的任务",
    "game": "与英雄的游戏",
    "question": "带描述的问题",
    "engine": "与电脑的游戏",
    "edit_task": "编辑任务",
    "search": "搜索",
    "edit_material": "编辑材料",
    "new_topic": "添加新主题",
    "reset": "重置",
    "materials_count": "主题下的材料总数",
    "admin_panel": "仪表板"
  },
  'captcha': {
    'language': '中文',
    'task': "请完成任务，以便我们知道您是真实的人：",
    'errors': {
      'NO_ERROR': '未发现错误',
      'INVALID_PARAMS': '无效的验证码参数',
      'WRONG_PROTOBUF_MESSAGE': '错误的protobuf消息',
      'CAPTCHA_NOT_FOUND': '验证码未找到',
      'CAPTCHA_ALREADY_EXISTS': '无效的验证码参数',
      'WRONG_CAPTCHA_EMAIL': '无效的验证码电子邮件',
      'CAPTCHA_IS_EXPIRED': '验证码已过期',
      'ANSWER_IS_EXPIRED': '答案已过期',
      'WRONG_CAPTCHA_ANSWER': '错误的验证码答案',
      'WRONG_FEN_RANDOMIZED': 'FEN生成错误',
    },
    "questions": {
      "Find the white pawns": "找到白色兵",
      "Find the black pawns": "找到黑色兵",
      "Find the pawns": "找到所有兵",
      "Find the white knights": "找到白色马",
      "Find the black knights": "找到黑色马",
      "Find the knights": "找到所有马",
      "Find the white bishops": "找到白色象",
      "Find the black bishops": "找到黑色象",
      "Find the bishops": "找到所有象",
      "Find the white rooks": "找到白色车",
      "Find the black rooks": "找到黑色车",
      "Find the rooks": "找到所有车",
      "Find the white queens": "找到白色后",
      "Find the black queens": "找到黑色后",
      "Find the queens": "找到所有后",
      "Find the kings": "找到所有王",
    },
  },
  'auth': {
    'new_password': '新密码',
    'repeat_new_password': '重复新密码',
    'consider_new_password': '想出一个新密码，并为确认输入发送到您电子邮箱的代码',
    'confirm': '确认',
    'agree_with': '我同意 ',
    'terms': '服务条款',
    'do_sign_up': '注册',
    'continue': '继续',
    'code': '邮件中的代码',
    'resend_code': '重新发送验证码',
    'email_to_restore': '输入电子邮件地址以恢复访问',
    'receive_code_in_mail': '在邮件中接收代码',
    'repeat_password': '重复密码',
    'login_with': '使用登录',
    'login': '登录',
    'forgot_password': '恢复密码',
    'or': '或',
    'email': '电子邮件',
    'wrong_email': '错误的电子邮件',
    'password': '密码',
    "password_not_match":"密码不匹配",
    'no_account_yet': '还没有账户？',
    'sign_up': '注册',
    'yandex': '雅虎',
    'ok': '同学',
    'mail': '邮件',
    'fb': '脸书',
    'twitter': '推特',
    'vk': 'VK',
    'google': '谷歌',
    'choose_lg': '选择您的语言',
    'language': '中文',
    "home_page": "返回主页",
    "page_not_found": "抱歉，页面未找到...",
    "page_not_found_title": "请使用屏幕顶部的菜单或返回",
    "lesson_not_found": "课程未找到",
    "password_error": "密码错误",
    "invalid_characters": "无效字符",
    "enter_session_text": "您正在通过链接进入一个课程。要访问教室，请输入您的凭据",
    "username": "用户名",
    "password_to_enter": "访问密码",
    "with_out_registration":  "继续无注册",
    "change_password": "要更改用户密码，请填写下面的详细信息或在登录表单上使用密码恢复程序。",
    "current_password": "当前密码",
    'errors': {
      'NO_ERROR': '没有错误',
      'USER_NOT_FOUND': '用户未找到',
      'USER_ALREADY_EXISTS': '用户已存在',
      'INVALID_PARAMS': '无效的访问参数',
      'USER_NOT_ACTIVE': '用户未激活',
      'WRONG_PASSWORD': '密码错误',
      'WRONG_ACTIVATION_CODE': '激活码错误',
      'WRONG_SESSION_SIGN': '会话标志错误',
      'NOT_AUTHENTICATED': '用户未认证',
      'WRONG_RESTORE_CODE': '恢复代码错误',
      'USER_ALREADY_ACTIVE': '用户已激活',
      'WRONG_AUTH_CODE': '认证代码错误',
      'IMPROPERLY_CONFIGURED': '提供了错误的设置',
      'WRONG_PROVIDER': '错误的社交提供者',
      'WEAK_PASSWORD': '密码太弱',
      'SOCIAL_AUTH_ERROR': '获取用户信息时出错',
      'OAUTH_APP_ALREADY_EXISTS': 'OAuth应用程序已存在',
      'OAUTH_APP_NOT_FOUND': 'OAuth应用程序未找到',
      'OAUTH_CONSUMER_NOT_FOUND': 'OAuth消费者未找到',
      'OAUTH_MAX_APPS_REACHED': '已达到最大OAuth应用程序数量',
      'OAUTH_CODE_NOT_FOUND': '未找到OAuth授权代码',
      'OAUTH_ACCESS_TOKEN_NOT_FOUND': '未找到OAuth访问令牌',
      'SMTP_SEND_MAIL_ERROR': '发送SMTP邮件时出错',
      'WRONG_IMAGE_TYPE': '图像类型错误',
      'SEND_IMAGE_ERROR': '发送图像时出错',
      'WRONG_LANGUAGE_TYPE': '语言类型错误',
      'WRONG_DECODE_DATA': '解码数据时出错',
      'WRONG_ENCODE_DATA': '编码数据时出错',
      'WRONG_PROTOBUF_MESSAGE': '错误的protobuf消息',
      'PERMISSION_DENIED': '访问被拒绝',
      'CONTACT_ALREADY_EXISTS': '联系人已存在',
      'CONTACT_NOT_FOUND': '联系人未找到',
      'CONTACT_NOT_ACTIVE': '联系人未激活',
    },
  },
  'permissions': {
    'OtherError': {
      'otherError': '您的相机不可用。请使用其他设备。',
    },
    'BlockedAtSystemLevelMacOs': {
      'camera': '相机未连接，或{{browserName}}对相机的访问在操作系统级别被阻止。',
      'check': '检查相机在其他应用程序中是否正常工作（例如，Photo Booth）。如果相机正常，请确保{{browserName}}可以访问相机。',
      'p1_menu': '选择苹果菜单',
      'p1_pref': '→ “系统偏好设置”，点击“安全性与隐私”，然后点击“隐私”；',
      'p2': '选择“相机”，勾选{{browserName}}应用程序旁边的框；',
      'p3': '选择“麦克风”，勾选{{browserName}}应用程序旁边的框；',
      'p4': '重新启动{{browserName}}并尝试再次进行面试。',
      'more': '更多详情请访问网站',
      'support': '苹果支持',
    },
    'NoSoundWindowsFirefox': {
      'sureConnected': '如果您确定麦克风已连接，访问可能在Windows隐私设置中被阻止。',
      'goToStart': '转到开始',
      'parameters': '设置',
      'privacy': '隐私',
      'mic': '麦克风。确保：',
      'p1': '此设备的麦克风访问已启用；',
      'p2': '“允许应用访问麦克风”已开启；',
      'p3': '“允许经典应用访问麦克风”（如果有）已开启；',
      'p4': '更改设置后，请刷新页面。',
      'more': '更多详情请访问网站',
      'support': '微软支持',
    },
    'NotAllowedAndroidChrome': {
      'check': '无法访问相机和麦克风。',
      'p1': '在网站地址左侧，点击锁定图标',
      'p2': '点击权限部分；',
      'p3': '允许访问相机和麦克风；',
      'p4': '刷新页面。',
      'i1': '如果您没有看到权限部分，则相机和麦克风的访问在Android操作系统级别被阻止。在Android设置的应用程序部分，确保Chrome可以访问相机和麦克风。之后返回Chrome并刷新页面。更多详情请访问网站',
      'more': 'Android支持',
    },
    'NotAllowedBlink': {
      'check': '您未授权访问相机或麦克风。',
      'p1': '在网站地址左侧，点击锁定图标',
      'p2': '允许访问相机和麦克风；',
      'p3': '刷新页面',
      'p3_check': '或点击这里',
    },
    'NotAllowedBlinkWindowsSystem': {
      'check': '无法访问麦克风/相机。访问麦克风可能在Windows 10隐私设置中被阻止。',
      'goToStart': '转到开始',
      'parameters': '设置',
      'privacy': '隐私',
      'cameraAndSure': '转到麦克风部分，然后是相机，确保设备访问未被阻止：',
      'p1': '此设备的麦克风/相机访问已启用；',
      'p2': '“允许应用访问相机” / “允许应用访问麦克风”已开启；',
      'p3': '“允许经典应用访问相机” / “允许经典应用访问麦克风”（如果有）已开启；',
      'p4': '更改设置后，请刷新页面。',
      'more': '更多详情请访问网站',
      'support': '微软支持',
    },
    'NotAllowedFirefox': {
      'check': '您未授权访问相机或麦克风。',
      'p1': '在网站地址左侧，点击被划掉的相机或麦克风图标；',
      'p2': '重置对相机和麦克风的访问限制；',
      'p3': '刷新页面；',
      'p4': '当浏览器再次请求访问时，点击允许。',
      'more': '如果建议无效，请使用',
      'support': 'Firefox文档',
    },
    'NotAllowedMacOsSafari': {
      'check': '您未授权访问相机或麦克风。',
      'p1': '选择“Safari”菜单 → “偏好设置”，然后点击“网站”；',
      'p2': '依次选择相机和麦克风，并为网站huntica.works设置“允许”；',
      'p3': '关闭设置并刷新页面。',
      'more': '更多详情请访问网站',
      'support': '苹果支持',
    },
    'NotConnected': {
      'notConnected': '您的相机似乎未连接。请连接相机。',
    },
    'NotReadableErrorAudioWindows': {
      'check': '无法访问麦克风。访问麦克风可能在Windows 10隐私设置中被阻止。',
      'goToStart': '转到开始',
      'parameters': '设置',
      'privacy': '隐私',
      'mic': '麦克风。确保：',
      'p1': '此设备的麦克风访问已启用；',
      'p2': '“允许应用访问麦克风”已开启；',
      'p3': '“允许经典应用访问麦克风”（如果有）已开启；',
      'p4': '更改设置后，请刷新页面',
      'more': '更多详情请访问网站',
      'support': '微软支持',
    },
    'NotReadableErrorVideoWindows': {
      'check': '无法访问相机。可能的原因：',
      'p1': '相机当前被其他应用程序使用（例如，Skype或Zoom）。通常情况下，相机上会亮起指示灯。关闭所有可能使用相机的应用程序，然后刷新页面。',
      'p2': '如果您确定相机当前未被使用，则访问可能在Windows 10隐私设置中被阻止。',
      'goToStart': '转到开始',
      'parameters': '设置',
      'privacy': '隐私',
      'cameraAndSure': '相机并确保：',
      'a': '此设备的相机访问已启用；',
      'b': '“允许应用访问相机”已开启；',
      'c': '“允许经典应用访问相机”（如果有）已开启；',
      'd': '更改设置后，请刷新页面。',
      'more': '更多详情请访问网站',
      'support': '微软支持',
    },
  },
  'termsOfService': {
    'header': '服务条款',
    'parts': [
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse consectetur ipsum vel lectus dapibus, in pulvinar purus scelerisque. Proin in sodales lectus. Phasellus dapibus nunc ultricies libero dictum accumsan. Vestibulum porta ornare ex, a molestie tortor placerat quis. Nam posuere dolor eu justo viverra laoreet dignissim ac risus. Maecenas nec arcu eu mi eleifend lacinia ac sed ante. Pellentesque vitae iaculis turpis. Nunc vestibulum quam ut magna commodo molestie. Aliquam neque elit, auctor ut libero id, congue iaculis elit. Aliquam nulla massa, consequat eu metus id, auctor posuere neque. Etiam ac turpis blandit, tempus elit sed, commodo arcu. Integer porttitor ligula eget felis euismod bibendum. Etiam vehicula lectus ligula, vel hendrerit diam iaculis at. Ut cursus imperdiet volutpat.',
      'Aliquam varius egestas ante, nec feugiat elit pharetra et. Ut nec enim tellus. Etiam condimentum tortor nec sem commodo iaculis. Cras eget nunc varius nisl rutrum dapibus. Morbi ac erat vitae nulla iaculis fermentum. Ut eget pellentesque ante. Donec rhoncus at odio at viverra. Sed a feugiat nulla. Vivamus imperdiet velit ac sapien condimentum mollis. Etiam vitae sem mi. Maecenas semper sapien ac metus vehicula tincidunt eu vitae sem. Vestibulum commodo, leo vitae ullamcorper pulvinar, elit ligula pharetra ligula, in commodo enim nulla at velit. Vestibulum pellentesque tincidunt libero et luctus.',
      'Duis sed orci malesuada, sagittis eros vel, semper risus. Sed aliquet leo ac sem cursus commodo. Pellentesque ut euismod sem. Cras facilisis ultrices diam, at condimentum magna rutrum eget. Cras sit amet sapien quis quam ullamcorper pulvinar a eget nulla. Sed lorem dui, dapibus sed ex vel, tincidunt consectetur felis. Donec eu vestibulum eros. Quisque quis ullamcorper lectus. Donec vestibulum rhoncus turpis ut cursus. Vestibulum tristique dictum erat, eget pulvinar ligula dictum at. Sed fringilla placerat nisl in vehicula. Donec sit amet semper nisl. Fusce molestie ornare ex, id rhoncus ante semper accumsan. Mauris bibendum, elit non gravida dictum, magna eros aliquet enim, sit amet semper libero dui et neque. Nullam mattis sem accumsan aliquet congue. Nunc id interdum lacus.',
      'Morbi nunc risus, elementum eu porta eu, gravida non nunc. Nunc eu metus ut libero faucibus commodo. Phasellus efficitur, sapien vel sollicitudin auctor, odio turpis finibus tortor, vel vestibulum nulla dui ac orci. Integer varius volutpat mattis. Nulla at dui elit. Nam sit amet augue rutrum, imperdiet quam ut, congue odio. Vestibulum sagittis purus eu arcu sagittis elementum. Vivamus iaculis, odio sit amet convallis elementum, eros tortor lobortis est, nec aliquet leo nibh et arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sit amet ligula et lectus euismod eleifend eu aliquet libero. Suspendisse scelerisque urna at velit efficitur euismod. Nulla sed velit neque. Vivamus accumsan aliquet rutrum. Quisque in nibh leo. Donec ultricies, felis sed viverra luctus, neque felis volutpat enim, vitae rutrum eros sem in tellus.',
      'Donec a dictum risus, eu lobortis leo. Maecenas commodo accumsan luctus. Pellentesque diam nulla, pulvinar non turpis at, vulputate congue dui. Nulla eu arcu at massa eleifend semper quis sed enim. Sed quis auctor justo. Curabitur iaculis sapien a tellus tempor, et gravida enim tincidunt. In augue magna, aliquam at volutpat et, ultricies in magna.',
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse consectetur ipsum vel lectus dapibus, in pulvinar purus scelerisque. Proin in sodales lectus. Phasellus dapibus nunc ultricies libero dictum accumsan. Vestibulum porta ornare ex, a molestie tortor placerat quis. Nam posuere dolor eu justo viverra laoreet dignissim ac risus. Maecenas nec arcu eu mi eleifend lacinia ac sed ante. Pellentesque vitae iaculis turpis. Nunc vestibulum quam ut magna commodo molestie. Aliquam neque elit, auctor ut libero id, congue iaculis elit. Aliquam nulla massa, consequat eu metus id, auctor posuere neque. Etiam ac turpis blandit, tempus elit sed, commodo arcu. Integer porttitor ligula eget felis euismod bibendum. Etiam vehicula lectus ligula, vel hendrerit diam iaculis at. Ut cursus imperdiet volutpat.',
      'Aliquam varius egestas ante, nec feugiat elit pharetra et. Ut nec enim tellus. Etiam condimentum tortor nec sem commodo iaculis. Cras eget nunc varius nisl rutrum dapibus. Morbi ac erat vitae nulla iaculis fermentum. Ut eget pellentesque ante. Donec rhoncus at odio at viverra. Sed a feugiat nulla. Vivamus imperdiet velit ac sapien condimentum mollis. Etiam vitae sem mi. Maecenas semper sapien ac metus vehicula tincidunt eu vitae sem. Vestibulum commodo, leo vitae ullamcorper pulvinar, elit ligula pharetra ligula, in commodo enim nulla at velit. Vestibulum pellentesque tincidunt libero et luctus.',
      'Duis sed orci malesuada, sagittis eros vel, semper risus. Sed aliquet leo ac sem cursus commodo. Pellentesque ut euismod sem. Cras facilisis ultrices diam, at condimentum magna rutrum eget. Cras sit amet sapien quis quam ullamcorper pulvinar a eget nulla. Sed lorem dui, dapibus sed ex vel, tincidunt consectetur felis. Donec eu vestibulum eros. Quisque quis ullamcorper lectus. Donec vestibulum rhoncus turpis ut cursus. Vestibulum tristique dictum erat, eget pulvinar ligula dictum at. Sed fringilla placerat nisl in vehicula. Donec sit amet semper nisl. Fusce molestie ornare ex, id rhoncus ante semper accumsan. Mauris bibendum, elit non gravida dictum, magna eros aliquet enim, sit amet semper libero dui et neque. Nullam mattis sem accumsan aliquet congue. Nunc id interdum lacus.',
      'Morbi nunc risus, elementum eu porta eu, gravida non nunc. Nunc eu metus ut libero faucibus commodo. Phasellus efficitur, sapien vel sollicitudin auctor, odio turpis finibus tortor, vel vestibulum nulla dui ac orci. Integer varius volutpat mattis. Nulla at dui elit. Nam sit amet augue rutrum, imperdiet quam ut, congue odio. Vestibulum sagittis purus eu arcu sagittis elementum. Vivamus iaculis, odio sit amet convallis elementum, eros tortor lobortis est, nec aliquet leo nibh et arcu. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Vestibulum sit amet ligula et lectus euismod eleifend eu aliquet libero. Suspendisse scelerisque urna at velit efficitur euismod. Nulla sed velit neque. Vivamus accumsan aliquet rutrum. Quisque in nibh leo. Donec ultricies, felis sed viverra luctus, neque felis volutpat enim, vitae rutrum eros sem in tellus.',
      'Donec a dictum risus, eu lobortis leo. Maecenas commodo accumsan luctus. Pellentesque diam nulla, pulvinar non turpis at, vulputate congue dui. Nulla eu arcu at massa eleifend semper quis sed enim. Sed quis auctor justo. Curabitur iaculis sapien a tellus tempor, et gravida enim tincidunt. In augue magna, aliquam at volutpat et, ultricies in magna.',
    ],
    "downloadPDF": "下载 PDF",
  },
  "supportUs": {
    "headerTitle": "我们的目标是创建一个最好的学习下棋的平台",
    "headerChessClubTitle": "ChessClub 培训",
    "headerChessClubSubTitle": "在您的帮助下会变得更好！",
    "donatTitle": "您可以选择方便的支付方式捐赠任意金额",
    "donatTitleElements": [
      [
        "https://chessclub.spb.ru/landing/img/sbp.svg",
        "快速支付服务",
        "https://chessclub.spb.ru/landing/img/sbpQR.png",
        "",
        [
          "打开您的银行应用",
          "扫描二维码",
          "输入金额",
          "确认支付"
        ]
      ],
      [
        "https://chessclub.spb.ru/landing/img/wallet.svg",
        "加密钱包",
        "https://chessclub.spb.ru/landing/img/cryptoQR.png",
        "0x53a210adA95b196393137205Ad1D3ED2d9F1A287",
        [
          "Tether (USDT) (ERC20)",
          "以太坊 (ETH)",
          "Solana (SOL)",
          "其他任何"
        ]
      ]
    ]
  },
  "settings": {
    "personalInfo": "个人资料",
    "security": "安全",
    "decoration": "主题",
    "userName": "用户名",
    "firstName": "名字",
    "lastName": "姓氏",
    "dateOfBirth": "出生日期",
    "town": "城市",
    "email": "电子邮件",
    "sendingMessages": "接收服务新闻通讯",
    'password': '密码',
    'repeat_password': '重复密码',
    "light": "浅色",
    "dark": "深色",
    "os_theme": "使用系统主题",
    "theme": "主题",
    "active_color": "活动元素颜色",
    "moves_style": "在棋盘上移动棋子",
    "drag": "拖动",
    "click": "鼠标点击",
    "flip": "翻转",
    "rotate": "旋转",
    "cancel": "取消",
  },
  "textEditor": {
    "titles": {
      "text1": "标题 1",
      "text2": "标题 2",
      "text3": "标题 3",
      "text4": "标题 4",
      "text0": "普通文本",
      "title": "标题"
    },
  },
};