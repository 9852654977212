import styled from 'styled-components';

const StyledName = styled.span`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.iconMain};
  margin-left: ${({ theme }) => theme.spacing.small}px;
`;


const StyledUserProfile = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

`;

const StyledAvatar = styled.img`
  width: 42px;
  height: 42px;
  min-width: 42px;
  border-radius: 50%;
  border: 2px solid ${({ theme }) => theme.currentTheme.iconLight};
`;

const StyledUserInfo = styled.span`
  display: flex;
  align-items: center;
  //gap: 5px;
`;

export const UserProfileStyledComponents = {
  StyledUserInfo, StyledAvatar, StyledUserProfile, StyledName
};