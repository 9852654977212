import React from 'react';
import { useLessonStore } from '../../../store/lesson/LessonStore';
import { HandeHochButton } from '../LessonView/buttons/HandeHochButton';
import { LessonStatusPanelWrapper } from './LessonStatusPanelWrapper';
import { CanMoveButton } from '../LessonView/buttons/CanMoveButton';
import { TextEllipsis } from '../../../components/LowLevelComponents/EllipsedText';

export function StudentLessonStatusPanel() {
  const { teacherName, roomId, isOnline } = useLessonStore();

  if (!roomId)
    return null;

  const active = document.location.href.includes('/lesson/');

  return <LessonStatusPanelWrapper
    $active={active} to={'/lesson/' + roomId}
    $online={isOnline}
    $student={true}
  >
    <TextEllipsis text={teacherName}/>

    {isOnline && <div style={{display:'flex', gap:5}}>
      <HandeHochButton />
      <CanMoveButton />
      </div>
    }
      </LessonStatusPanelWrapper>;
}