import React, { CSSProperties, useEffect, useRef, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import { useUiStore } from '../../store/uiStore';
import { RawSvgString } from '*.svg';
import { Theme, useThemeStore } from '../../store/themeStore';

export interface SvgImageProps {
  rotate?: number;
  style?: CSSProperties;
  icon?: RawSvgString;
  active?: boolean;
  filled?: boolean;
  r?: number;
  onClick?: (e) => void;
  withHover?: boolean;
  disabled?: boolean;
  size?: number;
  color?: string
  className?: string;
  tooltip?:string;

}

const StyledImage = styled.div<SvgImageProps & { bg?: string; }>`
  width: ${p => p.size || 32}px;
  height: ${p => p.size || 32}px;
  display: flex;
  border-radius: ${props => props.r || 8}px;
  background-color: ${props => props.bg};
  opacity: ${p => p.disabled ? 0.5 : 1};
  pointer-events: ${p => p.disabled ? "none" : "unset"};
  transform: rotate(${p => p.rotate || 0}deg);
  
  ${p => p.withHover && `
    cursor: pointer;
    & * {
      transition: 0.1s;
    }
    &:hover * {
      stroke: ${p.theme.currentTheme.iconHover};
    } 
  `}
`;

export function SvgImage(props: SvgImageProps) {

  // const ref= useRef();
  const { interfaceClr } = useUiStore();
  const { currentTheme } = useTheme();
  const { theme } = useThemeStore();
  const clr = props.color || currentTheme[interfaceClr];

  const bg = props.filled ? null : props.active ? clr : null;
  const fg = props.filled ? clr : props.active ? currentTheme.background : null;

  let content : string = props.icon;

  content = fg
    ? content?.split("#868686").join(fg)
      .split("#939393").join(fg)
      .split("#545454").join(currentTheme.background)
      .split("#454545").join(currentTheme.iconLight)
    : content;

  content =  content?.split("#FFFFE9")
    .join(theme === Theme.dark ? "#DCDCDC" : "white");

  const [__html, setHTML] = useState<string>(content);

  useEffect(() => {
    setHTML(content)
  }, [content])

  // useEffect(() => {
  //   // macos safari crutch
  //   let svg = (ref.current as any).querySelector('svg');
  //   svg.setAttribute('width', props.size || 32)
  //   svg.setAttribute('height', props.size || 32)
  // }) // every render

  return <StyledImage
    disabled={props.disabled}
    title={props.tooltip}
    // ref={ref}
    rotate={props.rotate}
    className={props.className}
    size={props.size}
    withHover={props.withHover}
    onClick={(e) => {
      props.onClick && e.stopPropagation();
      props.onClick && props.onClick(e);
    }}
    style={props.style}
    bg={bg}
    r={props.r}
    dangerouslySetInnerHTML={{__html}}
  />
}