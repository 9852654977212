import { useAuthStore } from '../../store/auth/AuthStore';
import { useLocalization } from '../../l10n/UseLocalization';
import { Role } from '@chessclub/grpc_wrapper';
import { PopupMenu } from '../PopupMenu/PopupMenu';
import { UserProfilePopupMenu } from './UserProfilePopupMenu';
import { UserProfile } from './UserProfile/UserProfile';
import React from 'react';
import { TextLink } from '../PageStyledComponents/TextLink';

export function UserProfileWidget() {

  const { user } = useAuthStore();
  const { auth } = useLocalization();

  if (!user || user.role === Role.GUEST) {
    return <>
      <div style={{ display: 'flex', gap: 20, paddingTop: 26, paddingRight: 20 }}>
        <TextLink linkText={auth.sign_up} to={"/auth/register/" + document.location.pathname.split("/").pop()}/>
        <TextLink linkText={auth.login} to={"/auth/login/" + document.location.pathname.split("/").pop()}/>
      </div>
    </>;
  }

  return <div className="withHover" style={{display:"flex", justifyContent: "end", alignItems: "center", height: 46}}>
      <PopupMenu popupContent={UserProfilePopupMenu}>
        <UserProfile />
      </PopupMenu>
  </div>;
}