import React from 'react';
import { ChessboardSvg } from '../../../../components/Chessboard/ChessboardSvg';
import { useLessonUiStore } from '../../../../store/lesson-ui/LessonUiStoreImpl';
import { useTaskToDisplay } from '../../../../hooks/useTaskToDisplay';
import { useTasksStore } from '../../../../store/tasks/TasksStore';
import { SvgImage } from '../../../../components/Icon/SvgImage';
import { getIconByTaskStatus, KnownIcons } from '../../../../components/Icon/KnownIcons';
import { useEditingTaskStore } from '../../../../store/editing-task/EditingTaskStore';
import { useLocalization } from '../../../../l10n/UseLocalization';
import { useLessonStore } from '../../../../store/lesson/LessonStore';
import { TaskStatus } from '@chessclub/grpc_wrapper/src/api/types/QuestionBase';
import styled from 'styled-components';
import { Stack } from '../../../../components/LowLevelComponents/Stack';
import { ConfirmDeleteTaskButton } from '../components/Question/ConfirmDeleteTaskButton';

const StyledQuestionStatusIcons = styled.div`
  display: flex;
  align-items: top;
  justify-content: flex-end;
  z-index: 999;
  margin: 5px;  
  gap: 5px;
`;


export function MovesTabCurrentQuestion() {

  const { mouseOverMove } = useLessonUiStore();
  const { isStudentLayout, demonstrationState, monitoringStudentId} = useLessonStore();
  const { taskToDisplay } = useTaskToDisplay();
  const { currentTaskGameState, currentTaskGameApi, updateTaskStatus } = useTasksStore();
  const { startEditTask } = useEditingTaskStore();
  const { lesson } = useLocalization();

  if (!taskToDisplay)
    return null;

  const fen = mouseOverMove?.fen || currentTaskGameState?.fen || taskToDisplay.fen;

  const markers = currentTaskGameState?.markers && (mouseOverMove ?
    currentTaskGameState?.markers[mouseOverMove.index] : currentTaskGameState?.markers[-1]);

  const activateStartPosition = () => {
    currentTaskGameApi.setStepIndex(-1);
    currentTaskGameApi.requestUpdate();
  };
  const editTask = () => startEditTask(taskToDisplay);
  const completeTask = () => updateTaskStatus(
    taskToDisplay.id,
    isStudentLayout ? TaskStatus.REVIEW : TaskStatus.RESOLVED,
    taskToDisplay.userId);
  const notCompleteTask = () => updateTaskStatus(
    taskToDisplay.id,
    isStudentLayout ? TaskStatus.WORK : TaskStatus.ERROR,
    taskToDisplay.userId);

  return <div>

    <Stack onClick={activateStartPosition}>
      <ChessboardSvg fen={fen} markers={markers} />
      <StyledQuestionStatusIcons>
        {taskToDisplay.customData?.status && <SvgImage icon={getIconByTaskStatus(taskToDisplay.customData?.status, isStudentLayout)} size={32} filled />}
        {demonstrationState?.boardId === taskToDisplay.id && <SvgImage icon={KnownIcons.demo_filled} size={32} filled />}
      </StyledQuestionStatusIcons>
    </Stack>

    <div style={{display:'flex', padding: 6}}>
      {demonstrationState?.boardId !== taskToDisplay.id && <ConfirmDeleteTaskButton taskId={taskToDisplay.id}/>}
      {!isStudentLayout && <SvgImage tooltip={lesson.edit} icon={KnownIcons.pencil} withHover onClick={editTask} />}
      {/*<SvgImage tooltip={lesson.reset} icon={KnownIcons.reverse} withHover  />*/}
      {/*{(monitoringStudentId || isStudentLayout) && <SvgImage tooltip={lesson.save} icon={KnownIcons.diskette} withHover  onClick={completeTask}/>}*/}
      {(isStudentLayout && taskToDisplay.customData?.status !== TaskStatus.REVIEW) &&
        <SvgImage tooltip={lesson.save}
                  icon={KnownIcons.return_outline}
                  withHover
                  onClick={completeTask}/>}
      {(monitoringStudentId && taskToDisplay.customData?.status !== TaskStatus.RESOLVED) &&
        <SvgImage tooltip={lesson.save}
                  icon={KnownIcons.queen_outline}
                  withHover
                  onClick={completeTask}/>}
      {(monitoringStudentId && taskToDisplay.customData?.status !== TaskStatus.ERROR) &&
        <SvgImage tooltip={lesson.save}
                  icon={KnownIcons.error_outline}
                  withHover
                  onClick={notCompleteTask}/>}

    </div>

  </div>;
}