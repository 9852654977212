import React, { useState } from 'react';
import styled from 'styled-components';
import Input from '../../LowLevelComponents/Input';
import { Button } from '../../LowLevelComponents/Button';
import {
  CloseIcon,
  StyledInputWrapper as CommonInputWrapper,
  StyledModalBody,
  StyledModalFooter,
  StyledModalHeader,
  StyledModalTitle,
} from '../common';
import { InputAdornment } from '../../../components/MaterialUI/MaterialUI';
import { useModalStore } from '../../../store/modalStore';
import { SvgImage } from '../../Icon/SvgImage';
import { KnownIcons } from '../../Icon/KnownIcons';

const StyledInputWrapper = styled(CommonInputWrapper)`
  display: grid;
  grid-template-columns: 90% 10%;
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

export interface ITextEditorTableModalProps {
  setTable: (rows: number, cols: number) => void;
}

export function Modal_TextEditorTable(props: ITextEditorTableModalProps) {
  let { setTable } = props;
  const { closeModal } = useModalStore();
  // Component state
  const [rows, setRows] = useState<number>(3);
  const [cols, setCols] = useState<number>(3);

  // Handlers
  const handleRowsChange = (e: React.ChangeEvent<HTMLInputElement>) => setRows(+e.target.value);
  const handleColsChange = (e: React.ChangeEvent<HTMLInputElement>) => setCols(+e.target.value);

  const handleCancelButton = (e) => closeModal();

  const handleSaveButton = (e) => {
    if (cols > 30 || rows > 50 || cols === 0 || rows === 0) return;
    setTable(rows, cols);
    setRows(3);
    setCols(3);
    closeModal();
  };
  return (
    <>
      <StyledModalHeader>
        <StyledModalTitle>Добавить таблицу</StyledModalTitle>
        <CloseIcon />
      </StyledModalHeader>
      <StyledModalBody>
        <StyledInputWrapper>
          <Input
            error={rows > 50 || rows === 0}
            type='number'
            autoFocus
            disabled={false}
            label='Количество рядов'
            fullWidth
            value={rows}
            onChange={handleRowsChange}
            endAdornment={
              rows ? (
                <InputAdornment onClick={() => setRows(0)} style={{ paddingRight: '2px' }} position='start'>
                  <SvgImage icon={KnownIcons.cross}/>
                </InputAdornment>
              ) : null
            }
          />
        </StyledInputWrapper>
        <StyledInputWrapper>
          <Input
            error={cols > 30 || cols === 0}
            type='number'
            autoFocus
            disabled={false}
            label='Количество столбцов'
            fullWidth
            value={cols}
            onChange={handleColsChange}
            endAdornment={
              cols ? (
                <InputAdornment onClick={() => setCols(0)} style={{ paddingRight: '2px' }} position='start'>
                  <SvgImage icon={KnownIcons.cross}/>
                </InputAdornment>
              ) : null
            }
          />
        </StyledInputWrapper>
      </StyledModalBody>
      <StyledModalFooter mobileTwoButtons>
        <Button type='secondary' onClick={handleCancelButton}>
          Отменить
        </Button>
        <Button type='primary' onClick={handleSaveButton}>
          Сохранить
        </Button>
      </StyledModalFooter>
    </>
  );
}

