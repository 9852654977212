import { FEN, QuestionBase, TaskType } from '@chessclub/grpc_wrapper';
import { create } from 'zustand';
import { uuid } from '@chessclub/web-game-server/src/client/util/uuid';
import { subscribeAtPageChange } from '../../helpers/subscribeAtPageChange';
import { cloneObject } from '../../helpers/cloneObject';
import { indexeddbObject } from '../../helpers/indexeddbObject';
import { adminKnowledgePath, lessonPath, materialsPath } from '../../app/routesPaths';
import { EditingTaskStoreApi, EditingTaskStoreData } from './EditingTaskStoreApi';
import { Chess_ts } from '@chessclub/web-game-server/src/shared/core/chess_ts';
import { KnownPieces } from '@chessclub/web-game-server';


type EditingTaskStore = EditingTaskStoreData & EditingTaskStoreApi;

const editingTaskDb = indexeddbObject<QuestionBase>('editing-task');

export const useEditingTaskStore = create<EditingTaskStore>((
  set,
  get,
) => {

  subscribeAtPageChange(async () => {
    const k = key();
    if (!k)
      return;

    let editingTask = await editingTaskDb.get(k);

    if (!editingTask?.fen) {
      await editingTaskDb.del(key());
      return;
    }

    set({ editingTask });
  });

  function sortCastling(a, b) {
    return a.charCodeAt(0) - b.charCodeAt(0);
  }

  function key() {
    const url = document.location.pathname;
    if (url.includes(materialsPath))
      return 'materials';
    if (url.includes(adminKnowledgePath))
      return 'knowledge';
    if (url.includes(lessonPath))
      return url.split(lessonPath)[1];
    return null;
  }

  return {
    snapshotId: uuid(),
    editingTask: null,

    updateSize(size: number) {
      const task = get().editingTask;
      const chess = new Chess_ts();
      chess.loadFEN(task.fen)
      chess.chess.resize({m:size, n:size})
      let fen = chess.fen();
      const editingTask = {...task, fen, tools: {}, moves: [], moveIndex: -1};
      set({ editingTask, snapshotId: uuid()});
    },

    async updateEditingTaskData(task: Partial<QuestionBase&{}>, forceUpdate?:boolean) {
      const editingTask = { ...get().editingTask, ...task };
      // debugger
      const newState = { editingTask } as Partial<EditingTaskStoreData>;
      if (forceUpdate) {
        newState.snapshotId = uuid()
      }
      set(newState);
      await editingTaskDb.put(key(), editingTask);
    },

    requestUpdate() {
      set({ snapshotId: uuid() });
    },

    async stopEditTask() {
      await editingTaskDb.del(key());
      set({ editingTask: null, snapshotId: uuid() });
    },

    async startEditTask(task: QuestionBase) {
      task.type = task.type || TaskType.TASK;
      const editingTask = cloneObject(task);
      set({ editingTask, snapshotId: uuid() });
      await editingTaskDb.put(key(), editingTask);
    },

    async updateEditingTaskCastling(char: string) {
      const task = get().editingTask;
      const fenParts = task.fen.trim().split(' ');
      const castling = fenParts[2].split('').filter(c => c !== '-');
      const index = castling.indexOf(char);
      index > -1 ? castling.splice(index, 1) : castling.push(char);
      fenParts[2] = castling.sort(sortCastling).join('') || '-';
      const editingTask = { ...task, fen: fenParts.join(' ') as FEN };
      set({ editingTask, snapshotId: uuid() });
      await editingTaskDb.put(key(), editingTask);
    },

    async updateEditingTaskSide(side: string) {
      const task = get().editingTask;
      const fenParts = task.fen.trim().split(' ');
      fenParts[1] = side;
      const editingTask = { ...task, fen: fenParts.join(' ') as FEN };
      set({ editingTask, snapshotId: uuid() });
      await editingTaskDb.put(key(), editingTask);
    },

  } ;
});


