import React, { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { useLessonStore } from '../../../store/lesson/LessonStore';
import { useUiStore } from '../../../store/uiStore';
import { useAuthStore } from '../../../store/auth/AuthStore';
import { SvgImage } from '../../Icon/SvgImage';
import { KnownIcons } from '../../Icon/KnownIcons';
import { debounce } from '../../../helpers/debounce';
import { TextEllipsis } from '../../LowLevelComponents/EllipsedText';

const StudentsIconsPanelWrapper = styled.div`
  display: flex;
  transition: width 350ms ease-in-out;
  margin-left: auto; 
`;

const StudentsVisibleIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  margin-left: auto;
`;

interface StyledIconWrapperParams {
  collapsed?: boolean;
  interfaceClr: string;
  chosen?: boolean;
}

const StyledIconWrapper = styled.div<StyledIconWrapperParams>`
  display: grid;
  align-items: center;
  margin: 0 3px;
  width: ${({ collapsed }) => collapsed ? "2px" : "42px" };
  transition: width 350ms ease-in-out;
  cursor: pointer;

  & > img {
    border-radius: 50%;
    ${({ theme, interfaceClr, chosen }) => chosen
  ? `
        border: 1px solid ${theme.currentTheme.iconLight}; 
        box-sizing: border-box; 
        box-shadow: 0 0 0 2px ${theme.currentTheme[interfaceClr]};
      `
  : `
        box-shadow: 0 0 0 2px ${theme.currentTheme.iconLight};
      `}
  }
`;

const StyledAttentionIcon = styled.div<StyledIconWrapperParams>`
  display: grid;
  align-items: center;
  cursor: pointer;
  width: 12px;
  height: 12px;
  grid-column:1;
  grid-row:1;
  margin: 30px 30px 0 30px;
  border-radius: 50%;
    ${({ theme, interfaceClr}) =>
  `background: ${theme.currentTheme[interfaceClr]}; 
  box-shadow: 0 0 0 1px ${theme.currentTheme.iconLight}`}
`;

const StyledHandUpIcon = styled(SvgImage)`
  margin: -30px 0px 0px 26px;
  grid-column:1;
  grid-row:1;
  box-shadow: 0 0 0 1px ${p => p.theme.currentTheme.iconLight};
`;

const StyledStudentNameText = styled.div`
  display: grid;
  align-items: center;
  height: 42px;
  padding-right: 10px;
  font: ${p => p.theme.fonts.normal};
  color: ${p => p.theme.currentTheme.iconMain};
  overflow: hidden;
`;


export function StudentsIcons() {
  const { user } = useAuthStore();
  const { interfaceClr } = useUiStore();
  const { users, somebodyLeaveJoin, somebodyLeaveJoinUserId, isStudentLayout , monitoringStudentId,
    isOnline, handIsUp, setStudentHandUp, handIsUpStudentId,
    setShowStudentPopupMenu, toggleStudentMonitoringMode, taskIsCompleted, taskCompletedStudentId } = useLessonStore();
  const [ visibleCount, setVisibleCount ] = useState<number>(10)

  const containerRef = useRef<HTMLDivElement>(null);

  const menuItems = useMemo(() => {
    return users
      .filter(u => u.id !== user.id)
  }, [users.length]);

  const allItems = useMemo(() => {
    let handUpUser = menuItems.find(({ id }) => id === handIsUpStudentId);
    if (handUpUser) {
      handUpUser.handUp = handIsUp;
    }

    let completedTaskUser = menuItems.find(({ id }) => id === taskCompletedStudentId);
    if (completedTaskUser) {
      completedTaskUser.attention = taskIsCompleted;
    }

    let joinUpUser = menuItems.find(({ id }) => id === somebodyLeaveJoinUserId);
    if (joinUpUser) {
      joinUpUser.leaveJoin = somebodyLeaveJoin;
    }

    if (! isOnline) {
      menuItems.map(user => {
        if (user.handUp  || user.attention) {
          user.handUp = false;
          user.attention = false;
        }
      });
    }

    return menuItems
      .sort((a, b) => {
        return (b.id === monitoringStudentId ?
            5 : b.handUp && b.id !== handIsUpStudentId ?
              4 : b.handUp && b.id === handIsUpStudentId ?
                3 : b.attention ?
                  2 : b.socketId || b.leaveJoin ? 1 : 0) -
          (a.id === monitoringStudentId ?
            5 : a.handUp && a.id !== handIsUpStudentId ?
              4 : a.handUp && a.id === handIsUpStudentId ?
                3 : a.attention ?
                  2 : a.socketId || a.leaveJoin ? 1 : 0);
      });
  }, [menuItems, isOnline, monitoringStudentId, handIsUp,
    handIsUpStudentId, taskIsCompleted, taskCompletedStudentId, somebodyLeaveJoin, somebodyLeaveJoinUserId]);


  const visibleItems = useMemo(() => {
    return allItems
      .filter((user, i) => i < visibleCount);
  }, [allItems, visibleCount, monitoringStudentId, handIsUp,
    handIsUpStudentId, taskCompletedStudentId, taskIsCompleted, somebodyLeaveJoin, somebodyLeaveJoinUserId]);


  useEffect(() => {
    const resizeHandler = debounce(() => {
      const { offsetWidth } = containerRef.current?.parentElement || {};
      let count = offsetWidth ? Math.floor((offsetWidth - 730) / 42) : 10;
      if (count >= menuItems.length) count = menuItems.length;
      count = count > 10 ? 10 : count <= 0 ? 1 : count
      setShowStudentPopupMenu(count < menuItems.length);
      setVisibleCount(count > 10 ? 10 : count <= 2 ? 3 : count);
    }, 100);

    window.addEventListener("resize", resizeHandler);
    resizeHandler();
    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, []);

  if (isStudentLayout)
    return null;
  // console.log("render")
  return <StudentsIconsPanelWrapper ref={containerRef}>
    {monitoringStudentId && <StyledStudentNameText>
      <TextEllipsis text={allItems.find(u => u.id === monitoringStudentId)?.displayName}/>
    </StyledStudentNameText>}
    <StudentsVisibleIconsWrapper>
      {visibleItems.map(({ attention, handUp,
                           displayName, id,
                           imageUrl}, index) => (
        <StyledIconWrapper
          collapsed={monitoringStudentId && id !== monitoringStudentId}
          interfaceClr={interfaceClr}
          chosen={monitoringStudentId === id}
          onClick={() => toggleStudentMonitoringMode(id)}
          key={id}
          title={displayName}
          style={{zIndex: 1000 - index}}
        >
          <img width={42} key={id} src={imageUrl} alt='person' style={{gridColumn: 1, gridRow: 1}}/>
          {handUp && <StyledHandUpIcon
            size={20}
            icon={KnownIcons.hand}
            active
            r={16}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setStudentHandUp({ userId: id, handUp: !handUp })
            }}
          />}
          {attention && <StyledAttentionIcon interfaceClr={interfaceClr} />}
        </StyledIconWrapper>
      ))}
    </StudentsVisibleIconsWrapper>
  </StudentsIconsPanelWrapper>;
}


