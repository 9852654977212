import React, { CSSProperties } from 'react';
import { StyledButton } from '../ChessTools/common';
import styled from 'styled-components';
import { SvgImage } from '../Icon/SvgImage';
import { KnownIcons } from '../Icon/KnownIcons';
import { PlayPause } from '../AnimatedIcon/variants/PlayPause';
import { Lamp } from '../AnimatedIcon/variants/Lamp';
import { Stopwatch } from '../AnimatedIcon/variants/Stopwatch';
import { ChessRules } from '../AnimatedIcon/variants/ChessRules';
import { Space } from '../LowLevelComponents/Space';

const StyledPanel = styled.div`
  display: flex;
  padding-top: 10px;
  justify-content: center;
`;

export interface UnderChessboardPanelProps {
  style?:CSSProperties;

  //navigation
  isNavigationVisible: boolean;

  isStartPosition: boolean;
  toStartPosition?: () => void;

  hasPreviousStep: boolean;
  previousStep?: () => void;

  hasNextStep: boolean;
  nextStep?: () => void;

  isLastPosition: boolean;
  toLastPosition?: () => void;

  // tools
  isReversed: boolean;
  reverse?: () => void;

  isRulesOnVisible?: boolean;
  isRulesOn: boolean;
  toggleRules?: () => void;

  isLegalMovesVisible?: boolean;
  isLegalMovesOn: boolean;
  toggleLegalMoves?: () => void;

  isTimerVisible?: boolean;
  isTimerOn: boolean;
  toggleTimer?: () => void;

  isGameRunningVisible?: boolean;
  isGameRunning: boolean;
  toggleGameRunning?: () => void;

  hasMarkers: boolean;
  clearMarkers?: () => void;
}

export function UnderChessboardPanel(props: UnderChessboardPanelProps) {
  return <StyledPanel className='under-chessboard-panel' style={props.style}>

    <StyledButton onClick={props.reverse}>
      <SvgImage icon={KnownIcons.rotate} filled={props.isReversed}/>
    </StyledButton>

    {props.isRulesOnVisible && <StyledButton onClick={props.toggleRules}>
      <ChessRules active={props.isRulesOn} />
    </StyledButton>}

    {props.isLegalMovesVisible && <StyledButton onClick={props.toggleLegalMoves}>
      <Lamp active={props.isLegalMovesOn} />
    </StyledButton>}

    {props.isTimerVisible && < StyledButton onClick={props.toggleTimer}>
      <Stopwatch active={props.isTimerOn}/>
    </StyledButton>}

    <StyledButton onClick={props.clearMarkers} disabled={!props.hasMarkers}>
      <SvgImage icon={KnownIcons.eraser} disabled={!props.hasMarkers}/>
    </StyledButton>

    {props.isGameRunningVisible && <StyledButton onClick={props.toggleGameRunning}>
      <PlayPause active={props.isGameRunning}/>
    </StyledButton>}

    {props.isNavigationVisible && <Space size={32} />}

    {props.isNavigationVisible && <StyledButton onClick={props.toStartPosition} disabled={props.isStartPosition}>
      <SvgImage icon={KnownIcons.fast_forward} disabled={props.isStartPosition} rotate={180}/>
    </StyledButton>}

    {props.isNavigationVisible && <StyledButton onClick={props.previousStep} disabled={!props.hasPreviousStep}>
      <SvgImage icon={KnownIcons.arrow_right} disabled={!props.hasPreviousStep} rotate={180}/>
    </StyledButton>}

    {props.isNavigationVisible && <StyledButton onClick={props.nextStep} disabled={!props.hasNextStep}>
      <SvgImage icon={KnownIcons.arrow_right} disabled={!props.hasNextStep}/>
    </StyledButton>}

    {props.isNavigationVisible && <StyledButton onClick={props.toLastPosition} disabled={props.isLastPosition}>
      <SvgImage icon={KnownIcons.fast_forward} disabled={props.isLastPosition}/>
    </StyledButton>}

  </StyledPanel>;
}



