import React, { useEffect, useState } from 'react';
import { boardSettings } from './boardSettings';
import { CentrifugeChessboard } from '@chessclub/web-game-server/index';
import { useRoomUserId } from '../../hooks/useRoomUserId';
import { useTasksStore } from '../../store/tasks/TasksStore';
import { BoardChannel, BoardState, GameState, makeBoardChannelKey } from '@chessclub/web-game-server';
import { UnderChessboardPanel } from './UnderChessboardPanel';
import { useLessonUiStore } from '../../store/lesson-ui/LessonUiStoreImpl';
import { GameApi } from '@chessclub/web-game-server/src/client/hooks/useCentrifugeChessboard';
import { Resizer } from '../Resizer/Resizer';
import { useModalStore } from '../../store/modalStore';
import { useLessonStore } from '../../store/lesson/LessonStore';
import { TaskId, TaskType } from '@chessclub/grpc_wrapper';
import { useChessboardStore } from '../../store/chessboard/ChessboardStore';
import { emitCentrifugeEvent } from '@chessclub/realtime_infrastructure';
import { useChannelEvent } from '@chessclub/web-game-server/src/client/hooks/useChannelEventListener';

export type ChessboardWithServerLogicProps = {
  boardId: TaskId;
  taskType: TaskType;
  multiBoardMode?: boolean;
  resizer?: boolean;
  noButtons?: boolean;
};

export function ChessboardWithServerLogic(props: ChessboardWithServerLogicProps) {
  const {markerHue, interactivityMode} = useChessboardStore();
  const { boardId, multiBoardMode, resizer } = props;
  const localUser = useRoomUserId();
  const {setCurrentTaskGameState, setCurrentTaskBoardState, currentTaskGameApi,
    setCurrentTaskGameApi, updateServerTaskState, currentTaskBoardState} = useTasksStore();
  const [gameState, setGameState] = useState<GameState>();
  const [gameApi, setGameApi] = useState<GameApi>();
  const [boardState, setBoardState] = useState<BoardState>();
  const {boardRotations, rotateBoard, setBoardRotation} = useLessonUiStore();
  const {users, isStudentLayout} = useLessonStore();
  const {modal, popup} = useModalStore();


  useEffect(() => {
    !multiBoardMode && setCurrentTaskGameState(gameState);
  }, [gameState, multiBoardMode])

  useEffect(() => {
    !multiBoardMode && setCurrentTaskBoardState(boardState);
  }, [boardState, multiBoardMode])

  useEffect(() => {
    !multiBoardMode && setCurrentTaskGameApi(gameApi);
  }, [gameApi, multiBoardMode])

  let channelKey = makeBoardChannelKey(boardId);

  useChannelEvent(channelKey, BoardChannel.SET_ROTATION, ({black}) => {
    isStudentLayout&&setBoardRotation(boardId, black);
  }, [boardId]);

  const avatars = {};
  users.forEach(u => {
    avatars[u.id] = u.imageUrl;
  })

  if (!boardId)
    return null;


  const centrifugeChessboard = <CentrifugeChessboard
    interactivityMode={interactivityMode}
    taskId={boardId}
    localUser={localUser}
    avatars={avatars}
    onGameStateChanged={(gs: GameState) => {
      setGameState(gs);
      updateServerTaskState(boardId, gs);
    }}
    onGameApiChanged={setGameApi}
    onBoardStateChanged={setBoardState}
    params={{
      ...boardSettings,
      markerHue,
      rotated: boardRotations[boardId],
      blocked: !!modal || !!popup
    }}
  />;

  return <>

    { resizer ? <Resizer>
      {centrifugeChessboard}
    </Resizer> : <>{centrifugeChessboard}</> }

    {props.noButtons&&<div style={{width: 300}}/>}

    <UnderChessboardPanel
      //navigation
      isNavigationVisible={!multiBoardMode && props.taskType !== TaskType.GAME}

      isStartPosition={gameState?.moveIndex === -1}
      toStartPosition={() => {
        currentTaskGameApi.setStepIndex(-1)
        currentTaskGameApi.requestUpdate();
      }}

      hasPreviousStep={!!gameState?.moves?.length && gameState?.moveIndex !== -1}
      previousStep={() => {
        currentTaskGameApi.setStepIndex(gameState?.moveIndex-1)
        currentTaskGameApi.requestUpdate();
      }}

      hasNextStep={!!gameState?.moves?.length && gameState?.moveIndex !== gameState?.moves?.length-1}
      nextStep={() => {
        currentTaskGameApi.setStepIndex(gameState?.moveIndex+1)
        currentTaskGameApi.requestUpdate();
      }}

      isLastPosition={!gameState?.moves?.length || gameState?.moveIndex === gameState?.moves?.length-1}
      toLastPosition={() => {
        currentTaskGameApi.setStepIndex(gameState?.moves?.length-1)
        currentTaskGameApi.requestUpdate()
      }}

      // tools
      hasMarkers={!!(gameState?.markers && gameState?.markers[gameState?.moveIndex]?.length)}
      clearMarkers={() => gameApi?.clearMarkers({ userId: localUser })}

      style={{display: props.noButtons ? "none":  "flex"}}
      isReversed={boardRotations[boardId]}
      reverse={() => {
        !isStudentLayout && emitCentrifugeEvent(channelKey,
          BoardChannel.SET_ROTATION, {black: !boardRotations[boardId]})
        rotateBoard(boardId);
      }}

      isRulesOnVisible={!isStudentLayout}
      isRulesOn={gameState?.applyRules}
      toggleRules={() => gameApi?.setApplyRules(!gameState?.applyRules)}

      isLegalMovesVisible={!isStudentLayout}
      isLegalMovesOn={gameState?.legalMoves}
      toggleLegalMoves={() => gameApi?.setLegalMoves(!gameState?.legalMoves)}

      isGameRunningVisible={props.taskType === TaskType.GAME}
      isGameRunning={gameState?.isPlaying}
      toggleGameRunning={() => gameApi?.setPlayStop(!gameState?.isPlaying)}

      isTimerVisible={props.taskType === TaskType.ENGINE}
      isTimerOn={gameState?.isTimerOn}
      toggleTimer={() => gameApi?.setUseTimer(!gameState?.isTimerOn)}
    />
  </>
 ;
}