import { useUiStore } from '../store/uiStore';
import { Lang } from '@chessclub/grpc_wrapper/src/api/types/IUser';
import { L10n } from './L10n';

import { Lang_ru } from './Lang_ru';
import { Lang_resources } from '@chessclub/resources/l10n/Lang_resources';
import { Lang_resources_ru } from '@chessclub/resources/l10n/Lang_resources_ru';
import { Lang_resources_en } from '@chessclub/resources/l10n/Lang_resources_en';
import { Lang_resources_cn } from '@chessclub/resources/l10n/Lang_resources_cn';
import { Lang_resources_hi } from '@chessclub/resources/l10n/Lang_resources_hi';
import { Lang_en } from './Lang_en';
import { Lang_cn } from './Lang_cn';
import { Lang_hi } from './Lang_hi';


const langs: {[key: string]: L10n & Lang_resources} = {
  [Lang.ru] : Object.assign({}, Lang_ru, Lang_resources_ru),
  [Lang.en] :  Object.assign({}, Lang_en, Lang_resources_en),
  [Lang.cn] :  Object.assign({}, Lang_cn, Lang_resources_cn),
  [Lang.hi] :  Object.assign({}, Lang_hi, Lang_resources_hi),
};

const loading = [];

function load(src: string) {
  if (loading.includes(src))
    return;
  loading.push(src);
  const script = document.createElement('script');
  script.src = src;
  // script.onload = () =>
  document.body.append(script)
}

// load('1.js');


export function useLocalization(): L10n & Lang_resources {

  const { lang } = useUiStore();

  return langs[lang || Lang.ru];
}

export function t(s, obj) {
  Object.entries(obj).forEach(([key, value])=>{
    s = s.split(`{{${key}}}`).join(value);
  })
  return s;
}