import React from 'react';
import { TaskCountIndicator } from '../buttons/TaskCountIndicator';
import { ICameraCard } from './CameraCardBase';
import styled from 'styled-components';
import { HandUpIndicator } from '../buttons/HandUpIndicator';
import { CanMoveIndicator } from '../buttons/CanMoveIndicator';

const CameraCardRightPanelWrapper = styled.span`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: end;
  padding: 4px;
  box-sizing: border-box;
  gap: 3px;
  pointer-events: none;

  & svg {
    pointer-events: all !important;
  }
`;

export function CameraCardRightPanel(props: ICameraCard) {
  return <CameraCardRightPanelWrapper>
    <TaskCountIndicator {...props} />
    <CanMoveIndicator {...props} />
    <HandUpIndicator {...props} />
  </CameraCardRightPanelWrapper>
}

