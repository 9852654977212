import React from 'react';
import styled from 'styled-components';
import { AddCard } from '../../components/LowLevelComponents/AddCard';
import { useEditingTaskStore } from '../../store/editing-task/EditingTaskStore';
import { MaterialCard } from './MaterialCard';
import { IMaterial } from '@chessclub/grpc_wrapper/src/api/types/IMaterial';
import { Pagination } from '../../components/Pagination/Pagination';
import { TextNode } from '../../components/LowLevelComponents/TextNode';
import { useLocalization } from '../../l10n/UseLocalization';
import { ITopic } from '@chessclub/grpc_wrapper';
import { media_max_width_1500 } from '../../mediaQuery';

const MaterialCardsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  align-content: flex-start;
  padding-top: var(--top-gap) !important;
  gap: 20px;
  
  @media ${media_max_width_1500} {
    grid-template-columns: 1fr 1fr;
  }
`;

export function MaterialsList(props: {
  materials: IMaterial[];
  factory: () => IMaterial;
  onDelete?: (mat: IMaterial) => void;
  onClick?: (mat: IMaterial) => void;
  accent?: (mat: IMaterial) => boolean;
  page: number;
  setPage?: (page: number) => void;
  selectedTopic: ITopic;
  pageCount: number;
}) {

  const { lesson } = useLocalization();
  const { startEditTask } = useEditingTaskStore();

  return <>
    <div style={{display: "flex", flexDirection: "row"}}>
      <Pagination page={props.page} pageCount={props.pageCount} onSelect={props.setPage}/>
      <TextNode style={{paddingLeft: 10, color: 'var(--color-icon-main)' }}>{lesson.materials_count}</TextNode>
      <TextNode style={{paddingLeft: 5, color: 'var(--color-icon-main)' }}>({props.selectedTopic?.itemsCount})</TextNode>
    </div>
    <MaterialCardsContainer>
      {props.factory && <AddCard onClick={() => startEditTask(props.factory())} />}
      {props.materials.map((mat, i) => <MaterialCard
        material={mat}
        key={i}
        onClick={() => props.onClick(mat)}
        onEdit={props.factory ? () => startEditTask(mat) : null}
        onDelete={props.onDelete ? () => props.onDelete(mat) : null}
        accent={props.accent && props.accent(mat)}
      />)}
    </MaterialCardsContainer>
  </>;
}