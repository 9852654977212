import { randomFen } from '../../../logic/randomFen';
import { BoardLabels, TaskType } from '@chessclub/grpc_wrapper/src/api/types/QuestionBase';

export function newKnowledge() {
  return {
    fen: randomFen(),
    title: 'new knowledge',
    moves: [],
    tools: {},
    type: TaskType.TASK,
    customData: {
      boardLabels: BoardLabels.OUTSIDE,
      rulesSettingOn: false,
      highlightMovesSettingOn: false,
      timerSettingOn: false,
    },
  };
}