import React, { useState } from 'react';
import styled from 'styled-components';

import { EmailStep } from './EmailStep';
import { CaptchaStep } from '../../components/Captcha/CapthcaStep';
import { CompleteStep } from './CompleteStep';
import { useStoredState } from '../../hooks/useStoredState';
import { Button } from '../../components/LowLevelComponents/Button';
import { RegisterStep } from '../../store/auth/AuthStoreApi';
import { useLocalization } from '../../l10n/UseLocalization';
import { LoginPageContainer } from '../../components/LoginPageContainer/LoginPageContainer';
import { Grid } from '../../components/MaterialUI/MaterialUI';
import { LogoSize } from '../../components/Icon/Logo';

export const StyledMessage = styled.p`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};
  padding: 0px;
  margin: 40px 0px 28px 0px;
  text-align: center;
`;

export const StyledText = styled.p`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.textMain};
  padding: 0px;
  margin: 0px 0px 10px 0px;
  text-align: center;
  padding-top: 10px;
`

export const InputWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.medium}px;

  &:last-child {
    margin-bottom: 0px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.spacing.large}px;
  display: flex;
  justify-content: center;
  width: 100%;
  
  button {
    height: 48px;
    padding-top: 14px;
  }
`;

export const StyledButton = styled(Button)`
  padding: 14px 0;
  height: 3rem;
  padding-top: 0.813rem;
  background: ${({ theme, disabled }) =>
  disabled ? theme.currentTheme.iconMain : theme.currentTheme.button} !important;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};
  &:hover {
    background: ${({ theme, disabled }) => !disabled && theme.currentTheme.buttonHover} !important;
  }
`;

export const RegisterSteps = () => {
  const [email, setEmail] = useStoredState<string | undefined>('register-email', '');
  const [phase, setPhase] = useState<RegisterStep>(RegisterStep.EMAIL);
  const lang = useLocalization();
  switch (phase) {
    case RegisterStep.EMAIL:
      return  <LoginPageContainer >
        <Grid container justifyContent='center' direction='column'>
          <EmailStep email={email} setEmail={setEmail} setPhase={setPhase} />
        </Grid>
      </LoginPageContainer>
    case RegisterStep.CAPTCHA:
      return  <LoginPageContainer logoSize={LogoSize.medium}>
        <Grid container justifyContent='center' direction='column'>
          <CaptchaStep confirmButtonText={lang.auth.confirm} email={email} setPhase={setPhase} />
        </Grid>
      </LoginPageContainer>
    case RegisterStep.COMPLETE:
      return  <LoginPageContainer >
        <Grid container justifyContent='center' direction='column'>
          <CompleteStep email={email} />
        </Grid>
      </LoginPageContainer>
  }
};
