import { ITopic, UserId } from '@chessclub/grpc_wrapper';
import { IMaterial, MaterialSearchParams } from '@chessclub/grpc_wrapper/src/api/types/IMaterial';

export enum  MaterialsMode {
  MATERIALS = "MATERIALS",
  KNOWLEDGE = "KNOWLEDGE",
  // BOTH = "BOTH",
}

export interface MaterialsStoreState {
  mode: MaterialsMode;
  setPage:(page: number) => void;
  page: number;
  pageCount: number;
  pageSize: number;
  selectedTopic: ITopic;
  materialTopics: ITopic[];
  knowledgeTopics: ITopic[];
  searchCriteria: MaterialSearchParams
  materials: IMaterial[];
  pageMaterials: IMaterial[];
}

export interface MaterialsStoreActions {
  setMode(mode: MaterialsMode);


  putTopic(topic: ITopic);
  deleteTopic(topic: ITopic);
  selectTopic(topic: ITopic);

  putMaterial(material: IMaterial);
  deleteMaterial(mat: IMaterial);
}