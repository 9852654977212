import React from 'react';
// import Paper from '@mui/material/Paper';
// import MenuList from '@mui/material/MenuList';
// import MenuItem from '@mui/material/MenuItem';
import ListItemText from '@mui/material/ListItemText';
import styled from 'styled-components';
import { IMessageMenu } from '../../../../../../interfaces/chat-message';
import { SvgImage } from '../../../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../../../components/Icon/KnownIcons';

const StyledContainer = styled.div`
  position: absolute;
  z-index: 2;
`;
// const StyledPaper = styled(Paper)`
//   && {
//     background-color: ${({ theme }) => theme.currentTheme.background};
//     border-radius: 12px;
//     font: ${({ theme }) => theme.fonts.small};
//     color: ${({ theme }) => theme.currentTheme.iconMain};
//     border: 1px solid #2c2c2c;
//     padding: 0px 5px !important;
//     box-shadow: 0px 4px 12px rgb(0 0 0 / 30%);
//     border: 1px solid ${({ theme }) => theme.currentTheme.hover};
//   }
// `;

const StyledMenuItem = styled.div<{theme?, disabled?}>`
  border-radius: 8px !important;
  ${({ theme, disabled }) =>
    `
        &:hover {
          background-color: ${disabled ? theme.currentTheme.background : theme.currentTheme.hover} !important;
        }
    `}
`;

export function MessageMenu(props: IMessageMenu) {
  const messageMenuRef = React.useRef<any>();
  const {
    isHidden,
    hide,
    me = false,
    pos,
    text,
    author,
    id,
    deleteMessage,
    setEditMessage,
    setReplyMessage,
    setEditorContent,
  } = props;
  const offset = { left: 0, top: 0 };
  React.useLayoutEffect(() => {
    if (messageMenuRef.current) {
      const messageMenuRect = messageMenuRef.current.getBoundingClientRect();
      const width = messageMenuRect.width;
      const height = messageMenuRect.height;
      if (pos.x + width >= visualViewport.width) {
        offset.left = width;
      }
      if (pos.y + height >= visualViewport.height) {
        offset.top = height;
      }
    }
    messageMenuRef.current.style.left = `${pos.x - offset.left}px`;
    messageMenuRef.current.style.top = `${pos.y - offset.top}px`;
  });

  const handleClick = (e: MouseEvent) => {
    if (e.which === 1 && messageMenuRef.current && !messageMenuRef.current.contains(e.target)) {
      hide(true);
    }
  };

  React.useEffect(() => {
    if (!isHidden) {
      document.body.addEventListener('mousedown', handleClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isHidden]);

  return (
    <StyledContainer ref={messageMenuRef} onClick={() => hide(true)} hidden={isHidden}>
      {/*<StyledPaper>*/}
      {/*  <MenuList>*/}
          <StyledMenuItem onClick={(e) => setReplyMessage({ id, type: 'reply', header: author, text })}>
            <SvgImage style={{ marginRight: '.5rem' }}  icon={KnownIcons.arrow_left}/>
            <ListItemText primaryTypographyProps={{ style: { fontSize: '15px' } }}>Ответить</ListItemText>
          </StyledMenuItem>
          {me && (
            <StyledMenuItem
              onClick={(e) => {
                setEditMessage({ id, type: 'edit', header: 'редактировать', text });
                setEditorContent(decodeURIComponent(escape(window.atob(text))));
              }}
            >
              <SvgImage style={{ marginRight: '.5rem' }}  icon={KnownIcons.pencil}/>
              <ListItemText primaryTypographyProps={{ style: { fontSize: '15px' } }}>Редактировать</ListItemText>
            </StyledMenuItem>
          )}
          {me && (
            <StyledMenuItem onClick={(e) => deleteMessage(id)}>
              <SvgImage style={{ marginRight: '.5rem' }}  icon={KnownIcons.cross}/>
              <ListItemText primaryTypographyProps={{ style: { fontSize: '15px' } }}>Удалить</ListItemText>
            </StyledMenuItem>
          )}
        {/*</MenuList>*/}
      {/*</StyledPaper>*/}
    </StyledContainer>
  );
}

