import React, { useEffect, useState } from 'react';
import { ButtonWrapper, InputWrapper, StyledButton, StyledMessage } from './RegisterSteps';
import styled, { useTheme } from 'styled-components';
import Input from '../../components/LowLevelComponents/Input';
import Checkbox from '../../components/LowLevelComponents/Checkbox';
import { Link } from '../../components/LowLevelComponents/Link';
import PasswordInput from '../../components/LowLevelComponents/PasswordInput';
import { Grid, InputAdornment } from '../../components/MaterialUI/MaterialUI';
import { useAuthStore } from '../../store/auth/AuthStore';
import { ServerApi } from '../../transport/ServerApi';
import { useLocalization } from '../../l10n/UseLocalization';
import { SvgImage } from '../../components/Icon/SvgImage';
import { KnownIcons } from '../../components/Icon/KnownIcons';
import { useUiStore } from '../../store/uiStore';
import { TextLink } from '../../components/PageStyledComponents/TextLink';
import { loggedUserFromGrpcUser } from '../../logic/loggedUserFromGrpcUser';
import { IAuthFormErrors } from '../../interfaces/auth';

const AgreementWrapper = styled.div`
  padding: 0;
  margin: 14px 0 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const Agreement = styled.span`
  font: ${({ theme }) => theme.fonts.normal};
  color: ${({ theme }) => theme.currentTheme.iconMain};

  a:hover {
    text-decoration: underline;
  }
`;

type CompleteRegisterPhaseProps = {
  email: string;
};

export const CompleteStep = (props: CompleteRegisterPhaseProps) => {
  const [checked, setChecked] = useState(false);
  const [password, setPassword] = useState<string>('');
  const [passwordRepeat, setPasswordRepeat] = useState<string>('');
  const [code, setCode] = useState<string>('');
  const initialErrors: IAuthFormErrors = { email: false, password: false, passwordRepeat: false, code:false };
  const [errors, setErrors] = useState(initialErrors);
  const [error, setError] = useState('');
  const { setUser } = useAuthStore();
  const { lang: siteLang } = useUiStore();
  const { auth } = useLocalization();
  const styledTheme = useTheme();

  const [passwordDoesNotMatch, setPasswordsDoesNotMatch] = useState<boolean>(false);
  const [isButtonActionDisabled, setButtonActionDisabled] = useState<boolean>(true);

  const [inProgress, setInProgress] = useState<boolean>(false);

  useEffect(() => {
    let errors: IAuthFormErrors = {};

    let notMatchOrEmpty = password && passwordRepeat && (password !== passwordRepeat);
    if (notMatchOrEmpty) {
      errors.password = true;
      errors.passwordRepeat = true;
    }
    setPasswordsDoesNotMatch(notMatchOrEmpty)
    errors.code = false;

    setButtonActionDisabled(!password || !passwordRepeat || notMatchOrEmpty || !code || !checked)

    setError("");
    setErrors(errors);
  }, [password, passwordRepeat, code, checked])


  async function completeRegistration() {
    let errors: IAuthFormErrors = {};

    const user = await ServerApi.accessService.completeRegistration(props.email, password, code, siteLang);

    if (typeof user === 'string') {
      errors.code = true;
      setError(user);
      setErrors(errors)
      return;
    }

    setUser(loggedUserFromGrpcUser(user));
  }

  return (
    <>
      <StyledMessage>{auth.sign_up}</StyledMessage>
      <InputWrapper>
        <div
          style={{
            visibility: passwordDoesNotMatch || error.length ? 'visible' : 'hidden',
            alignSelf: 'flex-start',
            marginLeft: '1rem',
            color: styledTheme.currentTheme['error1'],
            font: styledTheme.fonts.normal,
            paddingBottom:5,
          }}
        >
          {password && passwordRepeat && passwordDoesNotMatch ? auth.password_not_match : auth.errors[error]}&nbsp;
        </div>
        <Input label={auth.email} hideClear fullWidth value={props.email} />
      </InputWrapper>
      <InputWrapper>
        <PasswordInput
          label={auth.password}
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          error={errors.password}
        />
      </InputWrapper>
      <InputWrapper>
        <PasswordInput
          label={auth.repeat_password}
          fullWidth
          value={passwordRepeat}
          onChange={(e) => setPasswordRepeat(e.target.value)}
          error={errors.passwordRepeat}
        />
      </InputWrapper>
      <InputWrapper>
        <Input
          label={auth.code}
          fullWidth
          value={code}
          onChange={(e) => setCode(e.target.value)}
          error={errors.code}
          endAdornment={
            code && (
              <InputAdornment position='start' onClick={() => setCode('')}>
                <SvgImage style={{ marginRight: '.5rem' }} icon={KnownIcons.cross} />
              </InputAdornment>
            )
          }
        />
      </InputWrapper>
      <AgreementWrapper>
        <Checkbox onChange={(e) => setChecked(e.target.checked)} checked={checked} />
        <Agreement>
          {auth.agree_with}<Link to='/terms-of-service'>{auth.terms}</Link>
        </Agreement>
      </AgreementWrapper>
      <ButtonWrapper>
        <StyledButton
          fullWidth
          onClick={async () =>{
            setInProgress(true)
            await completeRegistration()
            setInProgress(false)
          }}
          disabled={isButtonActionDisabled || inProgress}
        >
          {auth.do_sign_up}
        </StyledButton>
      </ButtonWrapper>
      <Grid container justifyContent='center' direction='column'>
        <TextLink linkText={auth.login} to={'/auth/login'} />
      </Grid>
    </>
  );
};
