import React, { useRef } from 'react';
import styled from 'styled-components';
import { QuestionCard }  from '../components/Question/QuestionCard';
import { HidingScrollbar } from '../../../../components/LowLevelComponents/HidingScrollbar';
import { useTasksStore } from '../../../../store/tasks/TasksStore';
import { useLocalization } from '../../../../l10n/UseLocalization';
import { TextNode } from '../../../../components/LowLevelComponents/TextNode';
import { Space } from '../../../../components/LowLevelComponents/Space';
import { localStorageKeys } from '../../../../helpers/localStorageObject';


const StyledQuestions = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  /* max-height: calc(125px * 3); */
  max-height: 214px;
`;

const StyledQuestionsWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export function LessonTasksPanel() {
  const {lesson} = useLocalization();
  const { tasks } = useTasksStore();

  const scrollRef = useRef<HTMLDivElement | null>(null);

  return <StyledQuestionsWrapper>
    <HidingScrollbar
      elementRef={scrollRef}
      localStorageParamName={localStorageKeys.scrollbarTasksPanel_key}
      style={{ flexGrow: 1, overflowX: 'hidden', overflowY: 'scroll', borderTop: 'none' }}>
      <StyledQuestions style={{ flexGrow: 1 }}>
        <Space size={10}/>
        {tasks.length ? tasks.map(task => {
          return <QuestionCard {...task} key={task.id} />;
        }) : <TextNode style={{ padding: `10px 5px` }}>{lesson.empty}</TextNode>}
      </StyledQuestions>
    </HidingScrollbar>
  </StyledQuestionsWrapper>;
}

export default LessonTasksPanel;
