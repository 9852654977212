import plus from '../../svg/icons/add.svg';
import power_button from '../../svg/icons/on-off.svg';
import power_button_filled from '../../svg/icons/on-off-filled.svg';
import gear from '../../svg/icons/settings.svg';
import arrow_up_in_rect from '../../svg/icons/demo-outline.svg';
import chessboard from '../../svg/icons/view-lessons.svg';
import four_chessboards from '../../svg/icons/all-chessboards.svg';
import four_cams from '../../svg/icons/all-camera.svg';
import cross from '../../svg/icons/cancel.svg';
import check_mark from '../../svg/icons/save.svg';
import pencil from '../../svg/icons/edit.svg';
import trash from '../../svg/icons/delete.svg';
import copy from '../../svg/icons/copy.svg';
import paste from '../../svg/icons/paste.svg';
import question_in_rect from '../../svg/icons/questions.svg';
import question from '../../svg/icons/question.svg';
import stopwatch from '../../svg/icons/timer.svg';
import chat from '../../svg/icons/chat.svg';
import camera from '../../svg/icons/cam.svg';
import folder from '../../svg/icons/folder.svg';
import reverse from '../../svg/icons/reverse.svg';
import game_controller from '../../svg/icons/game-controller.svg';
import two_and_half_lines from '../../svg/icons/two-and-half-lines.svg';
import arrow_right from '../../svg/icons/arrow_right.svg';
import magnifying_glass from '../../svg/icons/find.svg';
import flip_vertical from '../../svg/icons/flip-vertical.svg';
import triple_dot_horizontal from '../../svg/icons/menu.svg';
import arrow_left from '../../svg/icons/back.svg';
import attach from '../../svg/icons/attach.svg';
import pawn from '../../svg/icons/figure.svg';
import pawn_with_arrows from '../../svg/icons/figure-move.svg';
import checkmark from '../../svg/icons/checkmark-outline.svg';
import checkmark_filled from '../../svg/icons/checkmark-filled.svg';
import human from '../../svg/icons/human.svg';
import lock from '../../svg/icons/lock.svg';
import hand from '../../svg/icons/hand-transparent.svg';
import hand_filled from '../../svg/icons/hand-filled.svg';
import camera_filled from '../../svg/icons/cam-gray-filled.svg';
import micro_filled from '../../svg/icons/micro.svg';
import circle from '../../svg/icons/circle.svg';
import x8 from '../../svg/icons/dimension-8x.svg';
import x10 from '../../svg/icons/dimension-10x.svg';
import x16 from '../../svg/icons/dimension-16x.svg';
import arrow from '../../svg/icons/arrow.svg';
import pacman from '../../svg/icons/game1.svg';
import eye from '../../svg/icons/eye-enable.svg';
import planet from '../../svg/icons/planet.svg';
import exit from '../../svg/icons/exit.svg';
import ing_yang from '../../svg/icons/ing-yang.svg';
import rotate from '../../svg/icons/rotate.svg';
import rulemove from '../../svg/icons/rulemove.svg';
import tolight from '../../svg/icons/tolight.svg';
import play from '../../svg/icons/play.svg';
import my_cam from '../../svg/icons/my-cam.svg';
import copy_settings from '../../svg/icons/copy-settings.svg';
import diskette from '../../svg/icons/save.svg';
import download from '../../svg/icons/download.svg';
import demonstration from '../../svg/icons/demo-main-outline.svg';
import demonstration_filled from '../../svg/icons/demo-main-filled.svg';
import game_with_engine from '../../svg/icons/engine.svg';
import queen_filled from '../../svg/icons/queen-filled.svg';
import queen_outline from '../../svg/icons/queen-outline.svg';
import demo_filled from '../../svg/icons/demo-filled.svg';
import error_filled from '../../svg/icons/error-filled.svg';
import error_outline from '../../svg/icons/error-outline.svg';
import return_filled from '../../svg/icons/return-filled.svg';
import return_outline from '../../svg/icons/return-outline.svg';
import wait_filled from '../../svg/icons/wait-filled.svg';
import wait_outline from '../../svg/icons/wait-outline.svg';
import tablet_outline from '../../svg/icons/tablet-outline.svg';
import review_outline from '../../svg/icons/review-outline.svg';
import eraser from '../../svg/icons/eraser.svg';
import fast_forward from '../../svg/icons/fast-forward.svg';
import bird from '../../svg/icons/bird.svg';
import zone from '../../svg/icons/zone.svg';
import omega from '../../svg/icons/omega.svg';
import cat from '../../svg/icons/cat-face.svg';
import character from '../../svg/icons/character.svg';



import { pick } from '@chessclub/web-game-server/src/shared/util/math';
import { TaskStatus, TaskType } from '@chessclub/grpc_wrapper';

export const KnownIcons = {
  plus,
  power_button,
  power_button_filled,
  gear,
  arrow_up_in_rect,
  chessboard,
  four_chessboards,
  four_cams,
  cross,
  check_mark,
  pencil,
  trash,
  copy,
  paste,
  question_in_rect,
  question,
  stopwatch,
  chat,
  camera,
  folder,
  reverse,
  game_controller,
  two_and_half_lines,
  arrow_right,
  magnifying_glass,
  triple_dot_horizontal,
  arrow_left,
  attach,
  pawn,
  pawn_with_arrows,
  checkmark,
  checkmark_filled,
  human,
  lock,
  hand,
  hand_filled,
  camera_filled,
  micro_filled,
  circle,
  x8,
  x10,
  x16,
  arrow,
  pacman,
  eye,
  planet,
  exit,
  ing_yang,
  rotate,
  rulemove,
  lamp: tolight,
  play,
  flip_vertical,
  my_cam,
  copy_settings,
  diskette,
  download,
  demonstration,
  demonstration_filled,
  demo_filled,
  game_with_engine,
  queen_filled,
  queen_outline,
  error_filled,
  error_outline,
  return_filled,
  return_outline,
  wait_filled,
  wait_outline,
  tablet_outline,
  review_outline,
  eraser,
  fast_forward,
  bird,
  zone,
  omega,
  cat,
  character,
};

export function randomIcon() {
  return pick(Object.keys(KnownIcons))
}

export function getIconByTaskType(taskType: TaskType): any {
  switch (taskType) {
    case TaskType.ENGINE:
      return KnownIcons.game_with_engine;
    case TaskType.TASK:
      return KnownIcons.chessboard;
    case TaskType.GAME:
      return KnownIcons.game_controller;
    case TaskType.QUESTION:
      return KnownIcons.question_in_rect;
    default:
      return KnownIcons.question_in_rect;
  }
}

export function getIconByTaskStatus(taskStatus: TaskStatus, isStudentLayout: boolean): any {
  switch (taskStatus) {
    case TaskStatus.WORK:
      return null;
    case TaskStatus.REVIEW:
      return isStudentLayout ? KnownIcons.return_filled : KnownIcons.wait_filled;
    case TaskStatus.RESOLVED:
      return KnownIcons.queen_filled;
    case TaskStatus.ERROR:
      return KnownIcons.error_filled;
    default:
      return null;
  }
}