import React, { useEffect, useState } from 'react';
import { MediaService, Room, RoomSession, SignallingService, UserType, StartRoomService } from '@chessclub/video-conf';

import { useLessonStore } from '../store/lesson/LessonStore';
import { useConferenceStore } from '../store/conference/conferenceStore';
import { CentrifugeTransport } from '../transport/CentrifugeTransport';
import { SignallingInterface } from '@chessclub/video-conf/dist/lib/signalling/SignallingInterface';
import { chessClubConfig } from '../config';


export function ConferenceSupport() {

  const [users, setUsers] = useState<string[]>([]);
  const [userId, setUserId] = useState<string>(null);
  const [signalling, setSignalling] = useState<SignallingInterface>(null);

  const {
    roomId,
    currentUser,
    joinUsersSocketIds,
    teacherId,
    isOnline
  } = useLessonStore();

  const {
    streamSessions,
    setStream,
    setStreamSessions,
    addStreamSession,
    getLastAudioState,
    getLastVideoState,
    setRoom
  } = useConferenceStore();

  const mediaStreamConstraints = {
    audio: true,
    video: {
      facingMode: 'user',
      width: { ideal: 341 },
      height: { ideal: 341/1.777 },
      frameRate: { max: 120 }
    }
  };

  async function on() {
    if (!roomId || !isOnline || !currentUser || !joinUsersSocketIds)
      return;
    const transport = new CentrifugeTransport();
    const service = new SignallingService(transport);
    const stream = await MediaService.createStream(mediaStreamConstraints);
    setStream(stream);
    setUsers(joinUsersSocketIds.filter((s) => s !== currentUser.socketId));
    setUserId(currentUser.socketId);
    setSignalling(service);
  }

  function off() {
    if (!roomId || !isOnline)
      return
     // MediaService.removeStream();
     // unsubscribeFromChannel(createChannelKey(roomId));
  }

  function onConnect(session: RoomSession): void {
    addStreamSession(session);
  }

  function onLeft(session: RoomSession): void {
    setStreamSessions(streamSessions.filter((s) => s.user !== session.user) as RoomSession[]);
  }

  function onRoomCreated(room: Room): void {
    setRoom(room);
    if (MediaService.getTrackState('audio') !== getLastAudioState(room.id))
      room.toggleMyAudio();
    if (MediaService.getTrackState('video') !== getLastVideoState(room.id))
      room.toggleMyVideo();
  }

  const startRoomService = new StartRoomService();


  useEffect(() => {
    on();
    return off;
  }, [roomId, isOnline, currentUser, joinUsersSocketIds]);

  useEffect(() => {
    if (signalling && roomId && isOnline) {
      startRoomService.startRoom(
          signalling,
          roomId,
          userId,
          chessClubConfig.rtcConfig,
          users,
          onLeft,
          onRoomCreated,
          onConnect,
          currentUser?.id === teacherId ? UserType.admin : UserType.user,
          mediaStreamConstraints,
      )
    }
  }, [signalling, roomId, isOnline]);

  return <>
    <div/>
  </>
}