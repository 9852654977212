import React from 'react';
import { useLessonStore } from '../../../../store/lesson/LessonStore';
import { SvgImage } from '../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../components/Icon/KnownIcons';

export function HandeHochButton() {

  const { handeHoch, handIsUp, handUpAction, isStudentLayout } = useLessonStore();

  const onClick = (e) => {
    e.stopPropagation();
    handUpAction();
    e.preventDefault();
  };

  return <SvgImage
    size={32}
    icon={KnownIcons.hand}
    active={isStudentLayout ? handIsUp : handeHoch}
    onClick={onClick}
    withHover = {isStudentLayout ? true : handeHoch}
  />;
}