import { TopicType } from '@chessclub/grpc_wrapper/src/api/types/ITopic';
import { randomIcon } from '../../../components/Icon/KnownIcons';

export function newKnowledgeTopic() {
  return {
    name: 'new topic',
    type: TopicType.KNOWLEDGE,
    customData: {
      icon: randomIcon(),
    },
  };
}