import styled from 'styled-components';
import { Link } from '../../../components/LowLevelComponents/Link';

const columns = p => {
  if (p.$student) {
    return p.$online ? '1fr 70px' : '1fr'
  } else {
    return p.$online ? '32px 1fr 96px' : '32px 1fr'
  }
};

export const LessonStatusPanelWrapper = styled(Link)<{
  $active: boolean;
  $online: boolean;
  $student?: boolean;
  $hand?: boolean
}>`
  display: grid;
  grid-template-columns: ${columns};
  max-width: 600px;
  min-width: 128px;
  border-radius: 8px;
  background: ${p => p.$active ? 'var(--color-selected) !important' : ''};
  box-sizing: border-box;
  padding: 0 10px;
  margin: 0 10px;
  height: 46px;
  gap: 10px;
  &:hover {
    background: var(--color-hover);
    cursor: pointer;
  }

  & div {
    display: flex;
    align-items: center;
    font: var(--font-normal);
    color: var(--color-icon-main);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
