import React, { ComponentType, CSSProperties, PropsWithChildren, useRef } from 'react';
import { useModalStore } from '../../store/modalStore';

type PopupMenuParams = PropsWithChildren<{
  disabled?: boolean;
  popupContent: ComponentType;
}>;

export function PopupMenu(props: PopupMenuParams) {
  const id = useRef(Math.random());
  const anchorRef = useRef();
  const {openPopup, closePopup, popup} = useModalStore();
  const style: CSSProperties = props.disabled
    ? {display: 'flex', opacity: 0.5, pointerEvents: "none"}
    : {display: 'flex'};

  function toggle(e) {
    if (popup?.id === id.current) {
      closePopup()
    } else {
      e.stopPropagation()
      e.preventDefault()
      props.popupContent && openPopup(id.current, <props.popupContent />, anchorRef.current);
    }
  }

  return (
    <div ref={anchorRef} style={style} onClick={toggle}>
      {props.children}
    </div>
  );
}

