import React from 'react';
import { KnownPieces, makePieceKey } from '@chessclub/web-game-server/index';
import { FigureButton } from './FigureButton';
import { useUiStore } from '../../store/uiStore';

export function PiecesRecent({ onMouseDown }: { onMouseDown?; }) {
  const { recentFigures } = useUiStore();

  return <>
    {recentFigures.map(piece => {
      const img = KnownPieces.getResourcesPath(makePieceKey(piece));

      return <FigureButton key={piece.id} fig={piece} onMouseDown={onMouseDown}>
        <img src={img} width={40}/>
      </FigureButton>;
    })}
  </>;
}