import React from 'react';
import { useLessonStore } from '../../../../../../../store/lesson/LessonStore';
import { ChessclubUser } from '../../../../../../../interfaces/user';
import { Stack } from '../../../../../../../components/LowLevelComponents/Stack';
import { KnownIcons, SvgImage } from '../../../../../../../components';
import styled from 'styled-components';
import review_outline from '../../../../../../../svg/icons/review-outline.svg';

const StyledIconText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 111;
  background: radial-gradient(#fff8 5px, #fff0 12px);
  
  font: ${p => p.theme.fonts.small};
  color: ${p => p.theme.currentTheme.iconMain};
`;

export function TaskCountIndicator(props: {user: ChessclubUser}) {

  const { isStudentLayout, teacherId, tasksStatusChanged } = useLessonStore();
  const isStudentAtTeacherView = !isStudentLayout && props.user.id !== teacherId;
  // const questionId = props.user.activeQuestionId;

  if (!isStudentAtTeacherView || !tasksStatusChanged )
    return

  return <Stack >
    {(props.user.hasTaskInWork &&
      !props.user.hasTaskToReview) && <SvgImage
      size={24}
      r={4}
      icon={KnownIcons.tablet_outline}
      active
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    />}
    {props.user.hasTaskToReview && <SvgImage
      size={24}
      r={4}
      icon={KnownIcons.review_outline}
      active
      onClick={(e) => {
        e.stopPropagation();
        e.preventDefault();
      }}
    />}
  </Stack>;
}