import React, { useEffect } from 'react';
import styled from 'styled-components';
import { IUser, UserId } from '@chessclub/grpc_wrapper';
import Input from '../../../components/LowLevelComponents/Input';
import { JustText, SvgImage, KnownIcons } from '../../../components';
import { HidingScrollbar } from '../../../components/LowLevelComponents/HidingScrollbar';
import { useUserListStore, UserListMode } from './UserListStore';
import { UserInList } from './UserInList';
import { useLocalization } from '../../../l10n/UseLocalization';
import { Stack } from '../../../components/LowLevelComponents/Stack';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export function UsersList(props: { ownerId: UserId; users: IUser[] }) {

  const { mode, init } = useUserListStore();

  useEffect(() => {
    init(props.ownerId, props.users);
  }, [props.ownerId, props.users]);

  return <Wrapper>
    {mode === UserListMode.DISPLAY ? <DisplayMode /> : <SearchMode />}
  </Wrapper>;
}

function DisplayMode() {
  const { addMode, persisted, added, doDelete } = useUserListStore();
  const users = [...(persisted || []), ...added];
  const { rooms } = useLocalization();

  return <>
    <div style={{ display: 'grid', gridTemplateColumns: '1fr 35px', alignItems: 'center' }}>
      <JustText>{rooms.trainees}</JustText>
      <SvgImage withHover icon={KnownIcons.plus} onClick={addMode} />
    </div>
    <UserListOnly users={users} del={doDelete} />
  </>;
}

function SearchMode() {
  const { displayMode, doAdd, setRequestText, requestText, found, contacts } = useUserListStore();
  const users = found.length ? found : contacts;
  const { rooms } = useLocalization();

  return <>
    <Input
     label={rooms.search_by_site + (requestText.length < 3 ? rooms.more_characters : '')}
      value={requestText}
      onChange={(e) => setRequestText(e.target.value)}
      endAdornment={<SvgImage withHover icon={KnownIcons.cross} onClick={displayMode} />}
    />
    <UserListOnly users={users} add={doAdd} />
  </>;
}

function UserListOnly({ users, add, del }: { users: IUser[]; add?; del?; }) {
  const { rooms } = useLocalization();

  return <HidingScrollbar style={{ maxHeight: "67vh"}}>
    <div style={{ display: 'flex', flexDirection: 'column'}}>
      {users.length ?
        users.map((u, i) => <UserInList key={i} user={u} />) :
        <JustText>{rooms.nothing_found}</JustText>}
    </div>
  </HidingScrollbar>;
}
