import React from 'react';
import { Theme, useThemeStore } from '../../store/themeStore';
import { allFiguresWhite } from './figures-icons/FiguresWhite';
import { allFiguresBlack } from './figures-icons/FiguresBlack';
import { SvgImage } from '../Icon/SvgImage';
import { FigureButton } from './FigureButton';
import { useTheme } from 'styled-components';
import { selectFiguresIcons, getFiguresAllIcons } from './figures-icons/selectFiguresIcons';
import { useMediaQuery } from '../../components/MaterialUI/MaterialUI';
import { media_max_width_1500, media_max_width_1200 } from '../../mediaQuery';
import { StyledButton } from './common';
import { KnownIcons } from '../Icon/KnownIcons';
import { PiecesColor } from '../../store/lesson-ui/LessonUiStoreApi';
import { useChessboardStore } from '../../store/chessboard/ChessboardStore';
import { useEditingTaskStore } from '../../store/editing-task/EditingTaskStore';
import { useModalStore } from '../../store/modalStore';


export function PiecesPanel() {
  const { theme } = useThemeStore();
  const isDark = theme === Theme.dark;

  const { togglePiecesColor, piecesColor } = useChessboardStore();

  const isWhite = piecesColor === PiecesColor.WHITE;
  const arr = isWhite ? allFiguresWhite : allFiguresBlack;

  const { editingTask } = useEditingTaskStore();
  const isSmallScreen = useMediaQuery(!!editingTask ? media_max_width_1500 : media_max_width_1200);
  const icons = isSmallScreen ? selectFiguresIcons(isWhite, isDark) : getFiguresAllIcons(isDark);
  const { closePopup } = useModalStore()

  return <div style={{ display: 'flex', background: useTheme().currentTheme.hover, borderRadius: 8 }}  onPointerDown={closePopup}>

    {isSmallScreen && <StyledButton onClick={togglePiecesColor}>
      <SvgImage icon={KnownIcons.ing_yang} rotate={isWhite ? 180 : 0} style={{transition: '0.3s'}}/>
    </StyledButton>}

    {isSmallScreen && arr.map((piece, i) => {
      return <FigureButton key={i} fig={piece.piece}>
        <SvgImage icon={icons[piece.icon]} />
      </FigureButton>;
    })}

    {!isSmallScreen && allFiguresWhite.map((piece, i) => {
      return <FigureButton key={i} fig={piece.piece}>
        <SvgImage icon={icons[0][piece.icon]} />
      </FigureButton>;
    }) }
    {!isSmallScreen && allFiguresBlack.map((piece, i) => {
      return <FigureButton key={i} fig={piece.piece}>
        <SvgImage icon={icons[1][piece.icon]} />
      </FigureButton>;
    }) }

  </div>;
}