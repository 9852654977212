import React from 'react';
import { useLessonStore } from '../../../../store/lesson/LessonStore';
import { SvgImage } from '../../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../../components/Icon/KnownIcons';
import { useTasksStore } from '../../../../store/tasks/TasksStore';

export function CanMoveButton() {

  const { currentUser, demonstrationState } = useLessonStore();
  const { currentTaskBoardState } = useTasksStore();
  const canMove = demonstrationState && currentTaskBoardState?.canMove.includes(currentUser?.id);

  const onClick = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  return <SvgImage
    size={32}
    icon={KnownIcons.pawn_with_arrows}
    active={canMove}
    onClick={onClick}
  />;
}