import React from 'react';
import { KnownIcons } from '../../../../../../../components/Icon/KnownIcons';
import { RedCameraCardButton } from './RedCameraCardButton';

export function CamButton({showDisabledMedia, cameraEnabled, localCameraEnabled, toggleCameraEnabled}: {
  showDisabledMedia: boolean,
  cameraEnabled: boolean;
  localCameraEnabled: boolean;
  toggleCameraEnabled: () => void
}) {

  return <RedCameraCardButton
    style={{gridColumn: 3}}
    className='hidden'
    onClick={() => toggleCameraEnabled && toggleCameraEnabled()}
    showDisabledMedia={showDisabledMedia}
    inOn={!cameraEnabled}
    inLocalOn={!localCameraEnabled}
    icon={KnownIcons.camera_filled}
  />

}
