import React from 'react';
import { Box } from '../../../../components/MaterialUI/MaterialUI';
import styled from 'styled-components';
import {LocalCameraCard} from '../LessonCamerasTab/Cameras/CameraCard/variants/LocalCameraCard';
import { useRoomUserId } from '../../../../hooks/useRoomUserId';
import {useUiStore} from "../../../../store/uiStore";
import {useLessonStore} from "../../../../store/lesson/LessonStore";
import { LessonViewMode } from '../../../../store/lesson-ui/LessonUiStoreApi';
import { RemoteCameraCard } from '../LessonCamerasTab/Cameras/CameraCard/variants/RemoteCameraCard';

const StyledContainer = styled.div<{ size: number }>`
  display: grid;
  grid-template-columns: ${({ size }) => `repeat(${size}, minmax(200px, 1fr))`};
  gap: 20px;
  padding-top: var(--top-gap) !important;
`;

export function AllCamerasView() {
  const { users } = useLessonStore();
  const { sizes } = useUiStore();
  const myUserId = useRoomUserId();
  return (
    <Box display='flex' flexDirection={'column'} >
      <StyledContainer size={sizes[LessonViewMode.CAMERAS]}>
        <LocalCameraCard />
        {users.filter(user => user.id !== myUserId && user.socketId)
          .map(user => <RemoteCameraCard user={user} key={user.socketId} />)}
      </StyledContainer>
    </Box>
  );
}