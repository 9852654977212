import React from 'react';
import themes from '../theme';
import { Switch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { Router } from './Router';
import { Modal } from '../components/Modal/Modal';
import { StyledApp } from './StyledApp';
import { useAuthStore } from '../store/auth/AuthStore';
import { useThemeStore } from '../store/themeStore';
import { Preloader } from '../components';
import { PopupMenuContainer } from '../components/PopupMenu/PopupMenuContainer';
import { Bg } from './Bg';
import { ConferenceSupport } from './ConferenceSupport';
import { useThemeColors } from '../hooks/useThemeColors';

export function AppDesktop() {
  const theme = {
    ...themes,
    currentTheme: themes.colors[useThemeStore().theme],
  };
  return <ThemeProvider theme={theme}>
    <App />
  </ThemeProvider>;
}

function App() {

  const { accent } = useThemeColors();
  const { user } = useAuthStore();

  const appContent = !user ?
    <Preloader /> :
    <>
      <Switch>
        <Router user={user} />
      </Switch>
      <Modal />
      <PopupMenuContainer />
    </>;

  // console.log('render App, user', user?.role);

  return <StyledApp clr={accent}>
    <ConferenceSupport />
    <Bg />
    {appContent}
  </StyledApp>;
}


