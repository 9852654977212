import React from 'react';
import { QuestionCard } from '../components/Question/QuestionCard';
import { Castling } from '../../../../components/Castling/Castling';
import { LessonTabs, MovesDisplayMode } from '../../../../store/lesson-ui/LessonUiStoreApi';
import { MovesChain } from '../../../../components/MovesPanel/MovesChain';
import { MovesTable } from '../../../../components/MovesPanel/MovesTable';

import { useLocalization } from '../../../../l10n/UseLocalization';
import { useLessonUiStore } from '../../../../store/lesson-ui/LessonUiStoreImpl';
import { useTaskToDisplay } from '../../../../hooks/useTaskToDisplay';
import { useTasksStore } from '../../../../store/tasks/TasksStore';
import { TextNode } from '../../../../components/LowLevelComponents/TextNode';
import { ChessboardSvg } from '../../../../components/Chessboard/ChessboardSvg';
import { MovesTabCurrentQuestion } from './MovesTabCurrentQuestion';
import { HidingScrollbar } from '../../../../components/LowLevelComponents/HidingScrollbar';
import { MovesText } from '../../../../components/MovesPanel/MovesText';
import { Space } from '../../../../components/LowLevelComponents/Space';

export function LessonMovesPanel() {
  const lang = useLocalization();
  const { movesMode, setMouseOverStep } = useLessonUiStore();
  const { taskToDisplay } = useTaskToDisplay();
  const { currentTaskGameState } = useTasksStore();
  const moves = currentTaskGameState?.movesState;

  return <>

    <MovesTabCurrentQuestion/>

    {/*<Castling question={taskToDisplay} />*/}

    <HidingScrollbar>
      <div onMouseLeave={() => setMouseOverStep(null)}>
        <Space size={10}/>
        {
          taskToDisplay && moves?.length ? <MovesView movesMode={movesMode} moves={moves}/> :
            <TextNode style={{ padding: `10px 5px` }}>{lang.lesson.empty}</TextNode>
        }
        <Space size={10}/>
      </div>
    </HidingScrollbar>
  </>;
}

function MovesView({movesMode,moves}:{movesMode,moves}) {
  if (movesMode === MovesDisplayMode.CHAIN)
    return <MovesChain moves={moves} />
  if (movesMode === MovesDisplayMode.TABLE)
    return <MovesTable moves={moves} />
  if (movesMode === MovesDisplayMode.TEXT)
    return <MovesText moves={moves} />
}