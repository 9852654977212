import React, { useMemo, useRef } from 'react';
import { useLessonStore } from '../../../store/lesson/LessonStore';
import { useAuthStore } from '../../../store/auth/AuthStore';
import { SvgImage } from '../../Icon/SvgImage';
import { KnownIcons } from '../../Icon/KnownIcons';
import { PopupMenu } from '../../PopupMenu/PopupMenu';
import { PopupMenuItem } from '../../PopupMenu/PopupMenuItem';
import styled from 'styled-components';
import { useUiStore } from '../../../store/uiStore';
import { HidingScrollbar } from '../../LowLevelComponents/HidingScrollbar';
import { localStorageKeys } from '../../../helpers/localStorageObject';

const StyledIconWrapper = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  grid-column: 1;
  grid-row: 1;
  box-shadow: 0 0 0 2px ${p => p.theme.currentTheme.iconLight};
`;

const StyledAttentionIcon = styled.div<{interfaceClr: string}>`
  display: grid;
  align-items: center;
  cursor: pointer;
  width: 12px;
  height: 12px;
  grid-column:1;
  grid-row:1;
  margin: 22px 0px 0px 22px;
  border-radius: 50%;

  background: ${({ theme, interfaceClr}) => theme.currentTheme[interfaceClr]};
  box-shadow: 0 0 0 1px ${p => p.theme.currentTheme.iconLight};
`;

function OverflowItemsPopupContent() {
  const { user } = useAuthStore();
  const { interfaceClr } = useUiStore();
  const { users, setStudentHandUp, monitoringStudentId, toggleStudentMonitoringMode,
  } = useLessonStore();

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const menuItems = useMemo(() => {
    return users
      .filter(u => u.id !== user.id);
  }, [users]);

  return  <HidingScrollbar
    elementRef={scrollRef}
    localStorageParamName={localStorageKeys.scrollbarStudentMenu_key}
    style={{ maxHeight: "80vh"}}>
      {menuItems.map(({ handUp, attention, displayName,
                        id, imageUrl }) => (
        <PopupMenuItem
          icon={<StyledIconWrapper
            key={id}
            src={imageUrl}
            alt='person' />}
          modifier={attention && <StyledAttentionIcon
            interfaceClr={interfaceClr} />}
          status={handUp && <SvgImage
            icon={KnownIcons.hand}
            active
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              setStudentHandUp({ userId: id, handUp: !handUp });
            }}
            withHover />}
          height={48}
          active={monitoringStudentId === id}
          key={id}
          text={displayName}
          onClick={() => toggleStudentMonitoringMode(id)}
        />
      ))}
  </HidingScrollbar>;
}

export function StudentsMenu() {

  const {
    users, isStudentLayout,  showStudentPopupMenu, monitoringStudentId,
  } = useLessonStore();

  if (isStudentLayout)
    return null;

  return <div  className="withHover" style={{
    display: 'flex',
    justifyContent: 'end',
    alignItems: "center",
    maxWidth: 32,
    height: 46,
    borderRadius: 8,
    marginRight: 10,
    transition: "300ms",
    marginLeft: monitoringStudentId ? users?.length > 2 ? 42 : 2 : 2,
  }}>

    {(showStudentPopupMenu && users?.length > 1) && (
      <PopupMenu popupContent={OverflowItemsPopupContent}>
        {<SvgImage style={{marginBottom:7, marginTop:7}} icon={KnownIcons.arrow_right} rotate={90} />}
      </PopupMenu>
    )}

  </div>;
}
