import React, { useEffect, useState } from 'react';
import { MediaService } from '@chessclub/video-conf';
import {useLessonStore} from "../../../../../../../store/lesson/LessonStore";
import {useConferenceStore} from "../../../../../../../store/conference/conferenceStore";
import { CameraCardBase } from '../components/CameraCardBase';
import { CameraCardAvatar } from '../components/CameraCardAvatar';
import { useAuthStore } from '../../../../../../../store/auth/AuthStore';

export function LocalCameraCard() {

  const { currentUser } = useLessonStore();
  const { user } = useAuthStore();

  const {
    room,
    stream,
    toggleCameraEnabledBySocketId,
    toggleMicroEnabledBySocketId,
    setLastAudioState,
    setLastVideoState,
    getLastVideoState,
    getLastAudioState
  } = useConferenceStore();

  const [cam, setCam] = useState<boolean>()
  const [mic, setMic] = useState<boolean>()

  useEffect(() => {
    setCam(getLastVideoState(room.id))
    setMic(getLastAudioState(room.id))
  }, [room]);

  // todo / move to store
  // const [audioState, setAudioState] = useState(stream && MediaService.getTrackState('audio'));
  //
  // useEffect(() => {
  //     const handler = (state) => {
  //         setAudioState(state)
  //     }
  //     room.emitter?.on('my-audio-session-updated', handler)
  //
  //     return () => {
  //         room.emitter?.off('my-audio-session-updated', handler);
  //     };
  // }, [room])


//console.log('render my cameraEnabled', currentUser?.cameraEnabled)
  async function toggleCameraEnabled() {
    if (!stream) {
      setLastVideoState(room.id, !cam);
      setCam(!cam)
      return null;
    }

    await room!.toggleMyVideo();

    let enabled = MediaService.getTrackState('video');

    setLastVideoState(room.id, enabled);
    setCam(enabled)
  }

  async function toggleMicroEnabled() {
    if (!stream) {
      setLastAudioState(room.id, !mic);
      setMic(!mic)
      return null;
    }

    await room!.toggleMyAudio();

    let enabled = MediaService.getTrackState('audio');

    setLastAudioState(room.id, enabled);
    setMic(enabled)
  }

  return currentUser && <CameraCardBase
    toggleCameraEnabled={toggleCameraEnabled}
    toggleMicroEnabled={toggleMicroEnabled}
    canMove={false}
    showDisabledMedia={false}
    cameraEnabled={cam}
    localCameraEnabled={true}
    microEnabled={mic}
    localMicroEnabled={true}
    muted={true}
    stream={stream}
    user={currentUser}
    mirror={true}
    currentUserCard={true}
    // analyzer={}
  >
    <CameraCardAvatar user={user} />
  </CameraCardBase>;
}
