import React, { useCallback, memo, useMemo, useEffect, useRef } from 'react';
import { PiecesGroup } from '../PiecesGroup';
import { HidingScrollbar } from '../../LowLevelComponents/HidingScrollbar';
import { useModalStore } from '../../../store/modalStore';
import { PopupMenuTitle } from '../../PopupMenu/PopupMenuTitle';
import { useLocalization } from '../../../l10n/UseLocalization';
import { Lang_resources } from '@chessclub/resources/l10n/Lang_resources';
import styled from 'styled-components';
import { KnownIcons } from '../../Icon/KnownIcons';
import { SvgImage } from '../../Icon/SvgImage';
import { PiecesRecent } from '../PiecesRecent';
import { useUiStore } from '../../../store/uiStore';
import { localStorageKeys, localStorageObject } from '../../../helpers/localStorageObject';
import { PiecesGroupNames } from '../figures-icons/ExtraPiesesGroupNames';
import { FigureType } from '../figures-icons/FigureType';
import { EnumSettingsTab } from '../../../pages/UserSettingsPage/types';
import { ExtraPiecesNavigationIcons } from '../figures-icons/ExtraPiecesNavigationIcons';

const StyledTitleNavigationIcons = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 8px;
  color: ${p => p.theme.currentTheme.iconMain};
  vertical-align: middle;
  pointer-events: unset;
  padding-top: 5px;
`;

const StyledPiecesContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  max-width: 355px;
  border-radius: 8px;
`;


const storedCurrentPiecesGroup = localStorageObject<string>(localStorageKeys.currentPiecesGroup_key, "");

const PopupMenuTitleNavigation = memo(() => {

  const scrollTo = useCallback((className: string) => {
    const el = document.querySelector('#' + className) as HTMLDivElement;
    if (el) {
      el?.parentElement.scrollTo({
        top: el.offsetTop - 10,
        behavior: "smooth",
      });

      storedCurrentPiecesGroup.save(className);
    }
  }, []);

  const navigationItems = useMemo(() =>
  PiecesGroupNames.map(name => (
      <SvgImage
        style={{ padding: "0.25rem"}}
        key={name}
        icon={ExtraPiecesNavigationIcons[name]}
        size={32}
        withHover
        onClick={() => scrollTo(name)}
      />
    )), [scrollTo]);

  return <StyledTitleNavigationIcons>
    {navigationItems}
  </StyledTitleNavigationIcons>;
});


const Pieces = memo(({groupName}: { groupName: keyof Lang_resources["resources"] }) => {
  const { closePopup } = useModalStore();
  const { resources } = useLocalization();

  const handlePointerDown = useCallback((e: React.PointerEvent) => {
    e.preventDefault();
    closePopup();
  }, [closePopup]);

  return (
    <>
      <PopupMenuTitle id={groupName} text={resources[groupName]} />
      <StyledPiecesContainer onPointerDown={handlePointerDown}>
        <PiecesGroup groupName={groupName} />
      </StyledPiecesContainer>
    </>
  );
});

const RecentPieces = memo(() => {
  const { closePopup } = useModalStore();
  const { recentFigures } = useUiStore();

  const handlePointerDown = useCallback((e: React.PointerEvent) => {
    e.preventDefault();
    closePopup();
  }, [closePopup]);

  if (!recentFigures.length) {
    return null;
  }

  return (
    <>
      <PopupMenuTitle id="recent" text="Уже использовали" />
      <StyledPiecesContainer onPointerDown={handlePointerDown}>
        <PiecesRecent />
      </StyledPiecesContainer>
    </>
  );
});

export const ChessboardExtraPiecesDropdown = memo(() => {

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const piecesList = useMemo(() =>
    PiecesGroupNames.map(name => (
      <Pieces key={name} groupName={name} />
    )), []);

  return (
    <>
      <PopupMenuTitleNavigation />
      <RecentPieces />
      <HidingScrollbar
        elementRef={scrollRef}
        localStorageParamName={localStorageKeys.scrollbarExtraPieces_key}
        style={{maxWidth: 355, maxHeight: "40vh", borderRadius: 8}}>
        {piecesList}
      </HidingScrollbar>
    </>
  );
});

