import { create } from 'zustand';
import { localStorageObject } from '../helpers/localStorageObject';
import { XY } from '@chessclub/web-game-server/src/shared';

export enum Theme {
  dark = 'dark',
  light = 'light'
}

interface ThemeStoreInitialState {
  theme: Theme;
  clickData?: XY&{time: number};
}

interface ThemeStoreActions {
  setTheme(theme: Theme, clickPoint?: XY): void;
}

type ThemeStore = ThemeStoreInitialState & ThemeStoreActions

const themeStore = localStorageObject<Theme>('theme', Theme.light);

function setPageTheme(theme: Theme) {
  document.querySelector("meta[name='theme-color']")
    .setAttribute("content",theme === Theme.light ? "#ffffff" : "#2C2C2C")
}

const initialState: ThemeStoreInitialState = {
  theme: themeStore.restore(),
  clickData: null,
};

export const useThemeStore = create<ThemeStore>((
  set,
  get
) => {

  setPageTheme(initialState.theme)

  return {
    ...initialState,

    setTheme: (theme: Theme, clickPoint?: XY) => {
      themeStore.save(theme)
      setPageTheme(theme)
      set({ theme, clickData: {...clickPoint, time: Date.now()} });
    },

  } as ThemeStore;
});
