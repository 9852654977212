import React from 'react';
import { Grid, useMediaQuery } from '../components/MaterialUI/MaterialUI';

// Adaptive

// Layout
/*
    ---------------------------------------
    LEFT                 CENTER
    ---------------------------------------
*/

export function UserSettingsLayout({ left, center }: any) {
  const is1051PxScreen = useMediaQuery('@media (max-width: 1295px)');

  return (
    <Grid container>
      {<Grid item>{left}</Grid>}
      <Grid style={{ width: is1051PxScreen ? '100%' : '540px' }} item>
        {center}
      </Grid>
    </Grid>
  );
}
