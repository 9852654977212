import React, { PropsWithChildren, useEffect } from 'react';
import { Box } from '../MaterialUI/MaterialUI';
import { PageHeader } from '../PageHeader/PageHeader';
import { PageFooter } from '../PageFooter/PageFooter';
import styled from 'styled-components';
import { useUiStore } from '../../store/uiStore';
import { LogoSize } from '../Icon/Logo';
import { Space } from '../LowLevelComponents/Space';

type PageTemplateParams = {
  showFooter?: boolean;
  showNavigation?: boolean;
  placeholder?:string;
  showLogo?: boolean;
  logo?: LogoSize;
  alterNavigation?;
  header?: boolean;
}

const StyledBox = styled(Box)`
  display: grid;
  grid-template-rows: 0fr auto 0fr;

  margin: 0 auto;
  padding: 0!important;
  width: 90%;
  height: 100%;
  transition: 0.1s;
  max-width: 1800px;
  min-width: 630px;

  @media (max-width: 1024px) {
    width: 98%;
  }
`

export function PageTemplate(props: PropsWithChildren<PageTemplateParams>) {
  const {setPlaceholder} = useUiStore();

  useEffect(() => {
    setPlaceholder(props.placeholder);
  }, [props.placeholder]);

  return <StyledBox className={"page"}>
    {props.header !== false && <PageHeader
      {...props}
      showLogo={ props.showLogo === undefined ? true : props.showLogo}
      logo={props.logo || LogoSize.small}
    />}
    {props.header !== false && <Space size={70}/>}
    {props.children}
    {props.showFooter !== false && <PageFooter />}
  </StyledBox>

}
