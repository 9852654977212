import React from 'react';

import { Container } from '../MaterialUI/MaterialUI';
import { PageFooterStyledComponents } from './PageFooterStyledComponents';
import { Links } from './Links';
import { Social_Youtube } from './icons/Social_Youtube';
import { Social_Telegram } from './icons/Social_Telegram';
import { Social_Zen } from './icons/Social_Zen';
import { Social_Vk } from './icons/Social_Vk';
import { LangSwitch } from './LangSwitch';
import { ThemeSwitch } from './ThemeSwitch';
import { buildDate } from '../../helpers/buildDate';

const {
  StyledFooter,
  StyledLinks,
  StyledRow,
} = PageFooterStyledComponents;

interface IFooter {
  style?: any;
  footerRef?: React.MutableRefObject<HTMLElement>;
}

export function PageFooter(props: IFooter) {
  const { style, footerRef } = props;

  const containerProps = {
    fixed: true,
    style: { padding: '0px', maxWidth: '100%'} as any,
  };

  return <StyledFooter ref={footerRef} style={{ marginTop: '2rem', ...style }}>
    <Container {...containerProps}>
      <StyledRow>

        <StyledLinks style={{gap:10, display:"flex", justifyContent: "left", marginRight:"auto",  minWidth:270}}>
          <div>
            <div style={{paddingTop:5}}>ChessClub © {new Date().getFullYear()}</div>
            <div style={{fontSize: 8, textAlign: 'right', marginTop:-5}}>Build: {buildDate}</div>
          </div>

          <StyledLinks style={{paddingLeft: 5, justifyContent: "left"}}>
            <Social_Youtube />
            <Social_Telegram />
            <Social_Zen />
            <Social_Vk />
          </StyledLinks>
        </StyledLinks>

        <StyledLinks style={{gap:10, display:"flex",  justifyContent: "center", margin:"auto", minWidth:502}}>
          <Links />
        </StyledLinks>

        <StyledLinks style={{gap:10, display:"flex", justifyContent:"right", paddingLeft:10, marginLeft:"auto", minWidth:96}}>
          <LangSwitch />
          <ThemeSwitch />
        </StyledLinks>

      </StyledRow>
    </Container>
  </StyledFooter>;
}


