import React from 'react';
import styled from 'styled-components';
import { Table, TableBody, TableCell, TableRow } from '../MaterialUI/MaterialUI';
import { PgnRecordWidget } from './PgnRecordWidget';
import { FullMoveDescription } from '@chessclub/web-game-server/src/shared/core/calcMoves';

const TableWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  flex-grow: 1;

  user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
`;

const StyledTable = styled(Table)`
  table-layout: fixed;

  & td {
    font: ${({ theme }) => theme.fonts.list};
    color: ${({ theme }) => theme.currentTheme.iconMain};
    font-size: 16px;
    border: none;
    padding: ${({ theme }) => theme.spacing.small}px;
    vertical-align: middle;
  }

  & td:first-child {
    background-color: ${({ theme }) => theme.currentTheme.hover};
  }
`;


interface ISelectableCell {
  selected?: boolean;
}

const StyledSelectableCell = styled(TableCell)<ISelectableCell>`
  cursor: pointer;
  background-color: ${({ theme }) => theme.currentTheme.background};

  &:hover {
    background-color: ${({ theme }) => theme.currentTheme.hover};
  }

  ${({ theme, selected }) => (selected ? `background-color: ${theme.currentTheme.select} !important;` : null)}
`;


const StyledCellContent = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;


export function MovesTable({ moves }: { moves?: FullMoveDescription[]}) {

  let pairs : FullMoveDescription[][]= [];
  if (moves) {
    for (let i = 0; i < moves.length; i += 2) {
      pairs.push(moves.slice(i, i + 2));
    }
  }

  return (
        <TableWrapper >
          <StyledTable>
            <TableBody>
              {pairs.map((el, row) => (
                <TableRow key={row}>
                  <TableCell style={{ width: '25px' }}>{row + 1}</TableCell>
                  {el.map((v, column) => (
                    <StyledSelectableCell key={column}>
                      <StyledCellContent>
                        <PgnRecordWidget {...v} i={row*2+column}/>
                      </StyledCellContent>
                    </StyledSelectableCell>
                  ))}
                </TableRow>
              ))}
            </TableBody>
          </StyledTable>
        </TableWrapper>
  );
}


