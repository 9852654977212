import {ChessToolbar} from '../../../components/ChessTools/ChessToolbar';
import { Grid } from '../../../components/MaterialUI/MaterialUI';
import React from 'react';
import { useLessonStore } from '../../../store/lesson/LessonStore';
import { useTaskToDisplay } from '../../../hooks/useTaskToDisplay';

const style = {
  display:'flex',
  justifyContent:'center',
  gridArea: 'T',
  paddingTop: '10px'
};

export function ChessToolsWrapper() {
  const { studentViewSettings, isStudentLayout, demonstrationState} = useLessonStore();

  const { taskToDisplay } = useTaskToDisplay();

  return <Grid style={style}>
    {calcShouldShowToolbar() && taskToDisplay ? <ChessToolbar /> : <div style={{height:40}} />}
  </Grid>;

  function calcShouldShowToolbar() {
    if (!isStudentLayout) {
      return true
    } else if (demonstrationState) {
      return demonstrationState.shouldShowTools;
    } else {
      return studentViewSettings?.toolbar;
    }
  }
}

