import React from 'react';
import { SvgImage } from '../../../components/Icon/SvgImage';
import { KnownIcons } from '../../../components/Icon/KnownIcons';
import { TaskType } from '@chessclub/grpc_wrapper';
import { useEditingTaskStore } from '../../../store/editing-task/EditingTaskStore';
import { JustText } from '../../../components/JustText/JustText';
import { useLocalization } from '../../../l10n/UseLocalization';

const icons  = {
  [TaskType.ENGINE]: KnownIcons.game_with_engine,
  [TaskType.TASK]: KnownIcons.chessboard,
  [TaskType.GAME]: KnownIcons.game_controller,
  [TaskType.QUESTION]: KnownIcons.question_in_rect,
}

export function TaskEditTaskTypeSelector() {
  const {editingTask, updateEditingTaskData} = useEditingTaskStore();
  const {lesson} = useLocalization();

  return <div style={{ display: 'flex' }}>
    {Object.entries(icons).map(type => {

      let onClick = () => {
        let task = { type: type[0] as TaskType} as any;
        if (task.type === TaskType.GAME) {
          task.moves = []
          task.moveIndex = -1;
        }
        updateEditingTaskData(task, true);
      };
      return type && <SvgImage
        icon={type[1]}
        key={type[0]}
        withHover
        active={editingTask.type === type[0]}
        onClick={onClick}
      />;
    })}
    <JustText style={{paddingLeft: 10, alignItems: 'center'}}>
      {lesson[editingTask.type]}
    </JustText>
  </div>;
}